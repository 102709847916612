import { Injectable } from '@angular/core';
import { DataService } from './data.service';

import { Subject, Observable, of } from 'rxjs';
import { Global } from '../_constants/global.variables';

@Injectable()
@Injectable()
export class SystemService {
	public themeChange$: any;

	constructor(private dataService: DataService) {
		this.themeChange$ = of(Global.CompanyLogoImage);
	}
}
