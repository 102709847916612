import { Directive, ViewContainerRef } from "@angular/core";

@Directive({
	selector: "[dialog-object]"
})
export class DialogDirective {
	constructor(public viewContainerRef: ViewContainerRef) {
		console.log("dialog-object: viewContainerRef = %O", viewContainerRef);
	}
}
