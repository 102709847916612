import { ElementRef, Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class RouteToExternalUrlService {

	constructor(private router: Router) { }

	routeToExternalUrl(url: string) {
		this.router.navigate(['/go-to-v1', { externalUrl: url }], {
			skipLocationChange: true,
		});
	}

}
