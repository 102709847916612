import { Injectable } from "@angular/core";

import {
	_ as __rest,
	S as Supercluster,
	e as equal,
	i as interpolateRgb,
	L as Loader,
} from "../_helpers/vendor.js";
import { Global } from '../_constants/global.variables';

declare const google: any;


@Injectable({
	providedIn: "root",
})
export class MapsService {
	url: string = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDOS-YR9HiIrDyM84NsViuykXr6105xsnw&libraries=drawing&callback=Function.prototype";
	script: HTMLScriptElement;
	constructor() {}


	public renderer = {
		palette: interpolateRgb("red", "blue"),
		render({ count, position }, stats) {
			const color = this.palette(count / stats.clusters.markers.max);
			const svg = window.btoa(`
			<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
			  <circle cx="120" cy="120" opacity=".3" r="70" /></svg>`);
			return new google.maps.Marker({
				position,
				icon: {
					url: `data:image/svg+xml;base64,${svg}`,
					scaledSize: new google.maps.Size(70, 70),
				},
				label: {
					text: String(count),
					color: "rgba(255,255,255,0.9)",
					fontSize: "14px",
					fontWeight: "bold",
				},
				zIndex: Number(google.maps.Marker.MIN_ZINDEX) + count,
			});
		},
	};
	public darkConfiguration = {
		zoom: 4,
		center: { lat: -33, lng: 151 },
		disableDefaultUI: false,
		styles: [
				{
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#1d2c4d"
					}
				  ]
				},
				{
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#8ec3b9"
					}
				  ]
				},
				{
				  "elementType": "labels.text.stroke",
				  "stylers": [
					{
					  "color": "#1a3646"
					}
				  ]
				},
				{
				  "featureType": "administrative.country",
				  "elementType": "geometry.stroke",
				  "stylers": [
					{
					  "color": "#4b6878"
					}
				  ]
				},
				{
				  "featureType": "administrative.land_parcel",
				  "elementType": "labels",
				  "stylers": [
					{
					  "visibility": "off"
					}
				  ]
				},
				{
				  "featureType": "administrative.land_parcel",
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#64779e"
					}
				  ]
				},
				{
				  "featureType": "administrative.province",
				  "elementType": "geometry.stroke",
				  "stylers": [
					{
					  "color": "#4b6878"
					}
				  ]
				},
				{
				  "featureType": "landscape.man_made",
				  "elementType": "geometry.stroke",
				  "stylers": [
					{
					  "color": "#334e87"
					}
				  ]
				},
				{
				  "featureType": "landscape.natural",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#023e58"
					}
				  ]
				},
				{
				  "featureType": "poi",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#283d6a"
					}
				  ]
				},
				{
				  "featureType": "poi",
				  "elementType": "labels.text",
				  "stylers": [
					{
					  "visibility": "off"
					}
				  ]
				},
				{
				  "featureType": "poi",
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#6f9ba5"
					}
				  ]
				},
				{
				  "featureType": "poi",
				  "elementType": "labels.text.stroke",
				  "stylers": [
					{
					  "color": "#1d2c4d"
					}
				  ]
				},
				{
				  "featureType": "poi.business",
				  "stylers": [
					{
					  "visibility": "off"
					}
				  ]
				},
				{
				  "featureType": "poi.park",
				  "elementType": "geometry.fill",
				  "stylers": [
					{
					  "color": "#023e58"
					}
				  ]
				},
				{
				  "featureType": "poi.park",
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#3C7680"
					}
				  ]
				},
				{
				  "featureType": "road",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#304a7d"
					}
				  ]
				},
				{
				  "featureType": "road",
				  "elementType": "labels.icon",
				  "stylers": [
					{
					  "visibility": "off"
					}
				  ]
				},
				{
				  "featureType": "road",
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#98a5be"
					}
				  ]
				},
				{
				  "featureType": "road",
				  "elementType": "labels.text.stroke",
				  "stylers": [
					{
					  "color": "#1d2c4d"
					}
				  ]
				},
				{
				  "featureType": "road.highway",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#2c6675"
					}
				  ]
				},
				{
				  "featureType": "road.highway",
				  "elementType": "geometry.stroke",
				  "stylers": [
					{
					  "color": "#255763"
					}
				  ]
				},
				{
				  "featureType": "road.highway",
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#b0d5ce"
					}
				  ]
				},
				{
				  "featureType": "road.highway",
				  "elementType": "labels.text.stroke",
				  "stylers": [
					{
					  "color": "#023e58"
					}
				  ]
				},
				{
				  "featureType": "road.local",
				  "elementType": "labels",
				  "stylers": [
					{
					  "visibility": "off"
					}
				  ]
				},
				{
				  "featureType": "transit",
				  "stylers": [
					{
					  "visibility": "off"
					}
				  ]
				},
				{
				  "featureType": "transit",
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#98a5be"
					}
				  ]
				},
				{
				  "featureType": "transit",
				  "elementType": "labels.text.stroke",
				  "stylers": [
					{
					  "color": "#1d2c4d"
					}
				  ]
				},
				{
				  "featureType": "transit.line",
				  "elementType": "geometry.fill",
				  "stylers": [
					{
					  "color": "#283d6a"
					}
				  ]
				},
				{
				  "featureType": "transit.station",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#3a4762"
					}
				  ]
				},
				{
				  "featureType": "water",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#0e1626"
					}
				  ]
				},
				{
				  "featureType": "water",
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#4e6d70"
					}
				  ]
				}
			  ]
	  }

	  public lightConfiguration = {
		zoom: 4,
		center: { lat: -33, lng: 151 },
		disableDefaultUI: false,
		styles:
		[
		  {
			"elementType": "geometry",
			"stylers": [
			  {
				"color": "#f5f5f5"
			  }
			]
		  },
		  {
			"elementType": "labels.icon",
			"stylers": [
			  {
				"visibility": "off"
			  }
			]
		  },
		  {
			"elementType": "labels.text.fill",
			"stylers": [
			  {
				"color": "#616161"
			  }
			]
		  },
		  {
			"elementType": "labels.text.stroke",
			"stylers": [
			  {
				"color": "#f5f5f5"
			  }
			]
		  },
		  {
			"featureType": "administrative.land_parcel",
			"elementType": "labels.text.fill",
			"stylers": [
			  {
				"color": "#bdbdbd"
			  }
			]
		  },
		  {
			"featureType": "poi",
			"elementType": "geometry",
			"stylers": [
			  {
				"color": "#eeeeee"
			  }
			]
		  },
		  {
			"featureType": "poi",
			"elementType": "labels.text.fill",
			"stylers": [
			  {
				"color": "#757575"
			  }
			]
		  },
		  {
			"featureType": "poi.park",
			"elementType": "geometry",
			"stylers": [
			  {
				"color": "#e5e5e5"
			  }
			]
		  },
		  {
			"featureType": "poi.park",
			"elementType": "labels.text.fill",
			"stylers": [
			  {
				"color": "#9e9e9e"
			  }
			]
		  },
		  {
			"featureType": "road",
			"elementType": "geometry",
			"stylers": [
			  {
				"color": "#ffffff"
			  }
			]
		  },
		  {
			"featureType": "road.arterial",
			"elementType": "labels.text.fill",
			"stylers": [
			  {
				"color": "#757575"
			  }
			]
		  },
		  {
			"featureType": "road.highway",
			"elementType": "geometry",
			"stylers": [
			  {
				"color": "#dadada"
			  }
			]
		  },
		  {
			"featureType": "road.highway",
			"elementType": "labels.text.fill",
			"stylers": [
			  {
				"color": "#616161"
			  }
			]
		  },
		  {
			"featureType": "road.local",
			"elementType": "labels.text.fill",
			"stylers": [
			  {
				"color": "#9e9e9e"
			  }
			]
		  },
		  {
			"featureType": "transit.line",
			"elementType": "geometry",
			"stylers": [
			  {
				"color": "#e5e5e5"
			  }
			]
		  },
		  {
			"featureType": "transit.station",
			"elementType": "geometry",
			"stylers": [
			  {
				"color": "#eeeeee"
			  }
			]
		  },
		  {
			"featureType": "water",
			"elementType": "geometry",
			"stylers": [
			  {
				"color": "#c9c9c9"
			  }
			]
		  },
		  {
			"featureType": "water",
			"elementType": "labels.text.fill",
			"stylers": [
			  {
				"color": "#9e9e9e"
			  }
			]
		  }
		]

	  }


	public mapConfiguration = {
		zoom: 15,
		styles: [
			{
				elementType: "geometry",
				stylers: [{ color: "#242f3e" }],
			},
			{
				elementType: "labels.text.stroke",
				stylers: [{ color: "#242f3e" }],
			},
			{
				elementType: "labels.text.fill",
				stylers: [{ color: "#746855" }],
			},
			{
				featureType: "administrative.locality",
				elementType: "labels.text.fill",
				stylers: [{ color: "#d59563" }],
			},
			{
				featureType: "poi",
				elementType: "labels.text.fill",
				stylers: [{ color: "#d59563" }],
			},
			{
				featureType: "poi.park",
				elementType: "geometry",
				stylers: [{ color: "#263c3f" }],
			},
			{
				featureType: "poi.park",
				elementType: "labels.text.fill",
				stylers: [{ color: "#6b9a76" }],
			},
			{
				featureType: "road",
				elementType: "geometry",
				stylers: [{ color: "#38414e" }],
			},
			{
				featureType: "road",
				elementType: "geometry.stroke",
				stylers: [{ color: "#212a37" }],
			},
			{
				featureType: "road",
				elementType: "labels.text.fill",
				stylers: [{ color: "#9ca5b3" }],
			},
			{
				featureType: "road.highway",
				elementType: "geometry",
				stylers: [{ color: "#746855" }],
			},
			{
				featureType: "road.highway",
				elementType: "geometry.stroke",
				stylers: [{ color: "#1f2835" }],
			},
			{
				featureType: "road.highway",
				elementType: "labels.text.fill",
				stylers: [{ color: "#f3d19c" }],
			},
			{
				featureType: "transit",
				elementType: "geometry",
				stylers: [{ color: "#2f3948" }],
			},
			{
				featureType: "transit.station",
				elementType: "labels.text.fill",
				stylers: [{ color: "#d59563" }],
			},
			{
				featureType: "water",
				elementType: "geometry",
				stylers: [{ color: "#17263c" }],
			},
			{
				featureType: "water",
				elementType: "labels.text.fill",
				stylers: [{ color: "#515c6d" }],
			},
			{
				featureType: "water",
				elementType: "labels.text.stroke",
				stylers: [{ color: "#17263c" }],
			},
		],
		// minZoom: 14,
		mapTypeId: "satellite",

		mapId: "DEMO_MAP_ID",
	}

	loadMapConfiguration(configuration?){
		let themedConfiguration: any;
		if(Global.Theme === "dark"){
			themedConfiguration = this.darkConfiguration;
		} else {
			themedConfiguration = this.lightConfiguration;
		}
		if(configuration?.zoom !== undefined){
			themedConfiguration.zoom = configuration.zoom;
		}
		if(configuration?.center !== undefined){
			themedConfiguration.center = configuration.center;
		}
		return themedConfiguration;
	}

	loadScript() {

		return new Promise((resolve, reject) => {
			this.script = document.createElement("script");
			this.script.async = true;
			this.script.defer = true;
			this.script.onload = resolve;
			this.script.onerror = reject;
			this.script.id = "";
			this.script.src = this.url;
			document.head.appendChild(this.script);
		});
	}

	loadGoogleMaps() {
		return new Promise((resolve, reject) => {
			if (this.script !== undefined) {
				resolve(this.script);
			} else {
				this.loadScript().then(() => {
					resolve(this.script);

				});
			}
		});
	}

	returnPlaybackMarker(alarmState?: string){
		let url = "";
		if (alarmState === "Critical" || alarmState === "Red") {
			url =
				Global.imagesUrl +
				"assets/images/GSEGraphics/Cir-Critical.svg";
		}
		if (alarmState === "Alarm" || alarmState === "Amber") {
			url =
				Global.imagesUrl +
				"assets/images/GSEGraphics/Cir-Alarm.svg";
		}
		if (alarmState === "Warning") {
			url =
				Global.imagesUrl +
				"assets/images/GSEGraphics/Cir-Warning.svg";
		}
		if (
			alarmState === "N/A" ||
			alarmState === "Informational" ||
			alarmState === undefined
		) {
			if(Global.Theme === "light"){
				url =
				Global.imagesUrl +
				"assets/images/GSEGraphics/Cir-Norm.svg";
			} else {
				url =
				Global.imagesUrl +
				"assets/images/GSEGraphics/Cir-Norm-Light.svg";
			}

		}

		return url;
	}
}
