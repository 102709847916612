import { Injectable, NgZone } from "@angular/core";
import * as signalR from "@microsoft/signalr";
import * as moment from "moment";
import { IHttpConnectionOptions } from "@microsoft/signalr";
import { ToastrService } from "ngx-toastr";
import { audit, BehaviorSubject, filter, Observable, Subject, Subscription } from "rxjs";

import { ISignalRNotification } from "../_models/signalr-notification.model";
import { SignalRTagIdObject } from "../_models/signalRTagIdObject.interface";
import { TagStorageObjectForSignalRService } from "../_models/tagStorageObjectForSignalRService.interface";
import { IUser } from "../_models/user.model";
import { UtilityService } from "./utility.service";
import swal from "sweetalert2";
import _ from "lodash";
import { Global } from "../_constants/global.variables";
import { IWidgetSignalRGroupObject } from "../_models/signalr-widget-group.model";
import { SignalRGroupsWidgetObject } from "../_models/signalr-widget-object.model";
import { IWidget } from "../_models/widget.model";
import { IncomingDataService } from "./incoming-data.service";

@Injectable({
	providedIn: "root"
})
export class SignalRCoreService {
	public hubConnection: signalR.HubConnection;

	public connected: boolean = false;
	public connectionState: string;
	public connectedClients: any;
	public OdataLockedEntities: Array<any>;
	public signalRHub: any;

	public currentUser: any;
	public Me: any;
	public connectionId: any;
	private message: ISignalRNotification;

	private _messages: BehaviorSubject<ISignalRNotification>;
	public broadcastMessages$: any;
	public processedMessages$: any;
	public processedTagIds$: any;

	public service: any;
	public videoFrames: any;

	public messageCount: number = 0;
	private startTime: any = 0;
	private firstConnectedClient: any = null;
	private localLogging: boolean = false;
	public signalRNotifications$: any;
	public tagStorageObjectForSignalR: TagStorageObjectForSignalRService = {
		distinctTagList: [],
		widgetArray: []
	};

	public groupsWidgetObjectForSignalR: SignalRGroupsWidgetObject = {
		distinctGroups: "",
		widgetSignalRGroupObjects: []
	};

	public currentActivityLog: Array<any> = [];
	public countOfSignalRObservations$: BehaviorSubject<number> = new BehaviorSubject<number>(Global.SignalR.countOfObservations);
	public hubConnectionState$: BehaviorSubject<string> = new BehaviorSubject<string>("disconnected");
	private serviceName: string = "signalr-core: ";
	private countOfSignalRDisconnectMessages: number = 0;

	public data: any[];
	public broadcastedData: any[];

	public timeSent: number;
	public timeReceived: number;
	public totalTime: number;
	public signalRTestInProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public lastToasterMessageSent: number;
	public joinedGroupsByTagNamePrefix: boolean = false;

	constructor(private toastr: ToastrService, private utilityService: UtilityService, private zone: NgZone, private incomingDataService: IncomingDataService) {
		this.service = this;
		this.message = new ISignalRNotification();
		this.broadcastMessages$ = new Subject<ISignalRNotification>();
		this.processedMessages$ = new Subject<ISignalRNotification>();
		this.processedTagIds$ = new Subject<number>();

		this.signalRTestInProgress$.subscribe((testInProgress: boolean) => {
			Global.SignalR.testInProgress = testInProgress;
		});

		setInterval(() => {
			if (typeof(this.LogActivity) == "function") {
				this.LogActivity(); //-- this is to just send out the SignalR messages for the user audit periodically if any records exist. --Kirk T. Sherer, January 10, 2024. 
			}
		}, 1000);
	}

	broadcastMessages(): Subject<ISignalRNotification> {
		return this.broadcastMessages$;
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log(this.serviceName + "ngOnDestroy invoked...");
		this.signalClientsForLogout();
	}

	showDisconnectedToasterMessage(message: string) {

		//-- only send out the disconnected message every thirty (30) seconds, and only send it if the user has debug mode turned on per Stephen. --Kirk T. Sherer, October 7, 2022. 
		if (this.lastToasterMessageSent != null && new Date().getTime() - this.lastToasterMessageSent >= 30000) {
			if (Global.User.DebugMode) { //-- currently this would only be for system administrators since they are the only ones that have access to DebugMode. 
				this.utilityService.showToastMessageShared({
					type: 'warning',
					message: message,
					title: 'SignalR',
				});
			}
			this.lastToasterMessageSent = new Date().getTime();
		}
	}

	startHub() {
		var service = this;
		if (!service.hubConnection) {
			service.buildHubConnection();
		}
		else {
			service.startConnection().then((data: any) => {
				console.log(service.serviceName + "SignalR hub connection started.");
			});
		}
	}

	buildHubConnection() {
		var service = this;
		var accessToken = Global?.User?.currentUser?.ODataAccessToken ?? null;
		
		const options: IHttpConnectionOptions = {
			accessTokenFactory: () => {
				return accessToken;
			},
			skipNegotiation: false, //-- can't skip negotiation unless you're using WebSockets. Error if you try without WebSockets: 'Negotiation can only be skipped when using the WebSocket transport directly'
			transport: signalR.HttpTransportType.WebSockets, //-- removed signalR.HttpTransportType.WebSockets since it's unreliable. Mark Thompson said the ServerSentEvents is much more reliable. --Kirk T. Sherer, October 10, 2022.
			withCredentials: false
		};
		service.hubConnection = new signalR.HubConnectionBuilder()
			.configureLogging(signalR.LogLevel.Information)
			.withUrl(Global.SignalR.CoreUrl, options)
			.withAutomaticReconnect([0, 0, 0, 0])  // .withAutomaticReconnect([0, 2000, 10000, 30000]) yields the default behavior. The 0, 0, 0, 0 means when we disconnect, immediately try to reconnect each of the four times (i.e. don't wait any milliseconds to reconnect).
			.build();							   // if it can't reconnect after four times, then it runs the onclose function and the user's browser is completely disconnected from SignalR. --Kirk T. Sherer, December 4, 2023.

		service.hubConnection.on("SignalRNotification", (code: any, dataObject: any, callerConnectionId: any, groupName: any) => {
			service.SignalRNotification(code, dataObject, callerConnectionId, groupName);
		});

		Global.User.DebugMode && console.log(service.serviceName + "attempting to start SignalR hub...");
		Global.User.DebugMode && console.log("Global.SignalR.CoreUrl = " + Global.SignalR.CoreUrl);

		service.startConnection().then((response: any) => {
			service.hubConnectionState$.next(response.state);
			//set up onreconnect, etc.
			Global.User.DebugMode && console.log(service.serviceName + "response = %O", response);
			service.broadcast("System.signalR Connected");
			Global.SignalR.Status = "Connected";
			if (Global.User.DebugMode === 1) {
				service.utilityService.showToastMessageShared({
					type: "success",
					message: "SignalR Connected"
				});
			}
		});

	}

	startConnection(): Promise<any> {
		var service = this;
		Global.SignalR.countOfObservations = 0;
		if (Global.User.DebugMode === 1) {
			service.utilityService.showToastMessageShared({
				type: "info",
				message: "SignalR Connecting"
			});
		}
		return new Promise((resolve, reject) => {
			if (service.hubConnection.state != signalR.HubConnectionState.Connected && service.hubConnection.state != signalR.HubConnectionState.Reconnecting && service.hubConnection.state != signalR.HubConnectionState.Connecting) {

				service.hubConnection
					.start()
					.then((data: any) => {

						Global.SignalR.countOfObservations = 0;
						console.log(service.serviceName + "Connection started.");
						service.hubConnectionState$.next(service.hubConnection.state);
						if (service.hubConnection && service.hubConnection.state == "Connected" ) {
							service.hubConnection.invoke("getconnectionid").then((data) => {
								service.connectionId = data;
								Global.SignalR.ClientId = service.connectionId;
								console.log(service.serviceName + "connectionId: " + service.connectionId);
								service.setSignalRVariables(service.hubConnection);
								resolve(service.hubConnection);
							})
							.catch((err: Error) => console.error(service.serviceName + "Error in getconnectionid: %O", err));
						}

						//--Reconnecting--
						service.hubConnection.onreconnecting(() => {
							console.assert(service.hubConnection.state === signalR.HubConnectionState.Reconnecting);
							console.log(service.serviceName + "reconnecting to SignalR hub.");
							//show reconnecting to the user
							service.hubConnectionState$.next(service.hubConnection.state);
							service.broadcast("System.signalR Reconnecting");
							Global.SignalR.Status = "Reconnecting";
							if (Global.User.DebugMode === 1) {
								service.utilityService.showToastMessageShared({
									type: "info",
									message: "SignalR Reconnecting"
								});
							}
						});

						//--Reconnected--
						service.hubConnection.onreconnected(connectionId => {
							console.assert(service.hubConnection.state === signalR.HubConnectionState.Connected);
							console.log(service.serviceName + "reconnected to SignalR hub.");
							service.connectionId = connectionId;
							Global.SignalR.ClientId = service.connectionId;
							console.log(service.serviceName + "connectionId: " + service.connectionId);
							service.hubConnectionState$.next(service.hubConnection.state);
							service.broadcast("System.signalR Connected");
							Global.SignalR.Status = "Connected";
							service.setSignalRVariables(service.hubConnection);
							if (Global?.User?.currentUser?.ODataAccessToken != null) {
								Global.SignalR.joinedSignalRGroups = false; //-- since reconnection may have given us back a different connectionId, we must rejoin the groups. This Global variable will need to be set to false in order for the joinGroups function to do that.
								service.joinGroups(); //-- hub should be connected here. Go ahead and retry joining groups.
							}
							if (Global.User.DebugMode === 1) {
								service.utilityService.showToastMessageShared({
									type: "success",
									message: "SignalR Connected"
								});
							}
							
						});

						//--OnClose--
						service.hubConnection.onclose(() => {
							console.log(this.serviceName + "closed SignalR hub connection.");
							service.hubConnectionState$.next(service.hubConnection.state);
							this.broadcast("System.signalR Disconnected");
							Global.SignalR.Status = "Disconnected";
							if (Global.User.DebugMode === 1) {
								this.utilityService.showToastMessageShared({
									type: "warning",
									message: "SignalR Disconnected"
								});
							}
							if (Global.User.isBeingAuthenticated || Global.User.isLoggedIn ) {
								//-- if the connection was closed, but we're re-authenticating the user or we're already authenticated, then go ahead and start the hub again. 
								setTimeout(() => {
									this.startHub(); //-- restart the hub if we've made it this far.
								}, 1000);
							}
							else {
								Global.SignalR.ListOfTagNamePrefixes = null; //-- we're not being authenticated, so we should no longer have a list of tag name prefixes for SignalR groups to join. 
								Global.SignalR.ListOfAdditionalSignalRGroups = null; //-- no longer need the additional SignalR group list either.
							}
						});

					})
					.catch((err: Error) => {
						if (Global.User.isLoggedIn) {
							//-- only display an error if we're still logged in and have an ODataAccessToken. If not, the user has already logged out and no need to send errors or try to reconnect. --Kirk T. Sherer, March 11, 2022.
							console.log(this.serviceName + "Error while starting connection: %O", err);
							this.countOfSignalRDisconnectMessages++;
							if (this.countOfSignalRDisconnectMessages <= 30) {
								Global.User.DebugMode && console.error(this.serviceName + "SignalR .Net Core Error = %O", err);
							} else {
								this.countOfSignalRDisconnectMessages = 0; //--reset the counter.
							}
							this.connected = false;
							reject(err);
						}
						setTimeout(() => {
							this.startHub();
						}, 1000);
					});
			}
			else {
				console.log(this.serviceName + "Connection state: " + service.hubConnection.state + ". hubConnection = %O", service.hubConnection);
				setInterval(() => {
					if (service.hubConnection.state == signalR.HubConnectionState.Connected && Global?.User?.currentUser?.ODataAccessToken != null) {
						if (Global?.SignalR?.joinedSignalRGroups == false) {
							service.joinGroups(); //-- hub should be connected here. Go ahead and retry joining groups.
						}
					}
				}, 500, 3);
			}
		});
	}

	public joinGroups() {
		var service = this;
		if (service.hubConnection?.state == signalR.HubConnectionState.Connected && Global?.User?.currentUser?.ODataAccessToken != null) {
			var systemSignalRGroups = "All,System Updates,User Issues" + (Global.User.isAdmin ? ",Admin" : "");
			service.joinGroup(systemSignalRGroups); //--DO NOT join these groups in uppercase, since the data isn't sent to an uppercase group. --Kirk T. Sherer, September 13, 2024. 
			service.joinSignalRGroupsByTagNamePrefix();
			if (Global.SignalR.ListOfAdditionalSignalRGroups != null) {
				//-- join the additional SignalR groups since we're probably going through a SignalR reconnection because this list exists. --Kirk T. Sherer, July 15, 2024. 
				service.joinGroup(Global.SignalR.ListOfAdditionalSignalRGroups); //--DO NOT join these groups in uppercase, since the data isn't sent to an uppercase group. --Kirk T. Sherer, September 13, 2024. 
			}
			var allSignalRGroupsArray = (systemSignalRGroups + (Global.SignalR.ListOfTagNamePrefixes ? "," + Global.SignalR.ListOfTagNamePrefixes : "") + (Global.SignalR.ListOfAdditionalSignalRGroups ? "," + Global.SignalR.ListOfAdditionalSignalRGroups : "")).split(",");
			console.log(this.serviceName + "Array of SignalR Groups: %O", allSignalRGroupsArray);
			Global.SignalR.joinedSignalRGroups = true;
		}
	}

	public joinAdditionalGroup(groupName: string) {
		var service = this;
		if (service.hubConnection?.state == signalR.HubConnectionState.Connected && Global?.User?.currentUser?.ODataAccessToken != null) {
			service.joinGroup(groupName);
			if (Global.SignalR.ListOfAdditionalSignalRGroups == null) {
				Global.SignalR.ListOfAdditionalSignalRGroups = groupName;
			}
			else 
			{
				var listOfAdditionalSignalRGroupsArray = Global?.SignalR?.ListOfAdditionalSignalRGroups?.split(",");
				var existingGroupName = listOfAdditionalSignalRGroupsArray && listOfAdditionalSignalRGroupsArray.firstOrDefault((name:string) => { return name == groupName });
				if (existingGroupName == null) {
					Global.SignalR.ListOfAdditionalSignalRGroups += "," + groupName;
				} //-- else, there's no reason to add to this list since it's already in the list for this group name. This list is necessary for reconnection to
				  //-- the SignalR hub, but keeping them separate from the TagNamePrefix and overall system-level groups that are joined upon login. --Kirk T. Sherer, July 15, 2024.
			}
		}
	}

	public leaveAdditionalGroup(groupName: string) {
		var service = this;
		if (service.hubConnection?.state == signalR.HubConnectionState.Connected && Global?.User?.currentUser?.ODataAccessToken != null) {
			service.leaveGroup(groupName);
			var listOfAdditionalSignalRGroupsArray = Global?.SignalR?.ListOfAdditionalSignalRGroups?.split(",");
			var remainingAdditionalGroupsAsArray = listOfAdditionalSignalRGroupsArray && listOfAdditionalSignalRGroupsArray.where((name: string) => { return name != groupName }).toArray();
			if (remainingAdditionalGroupsAsArray && remainingAdditionalGroupsAsArray.length > 0) {
				Global.SignalR.ListOfAdditionalSignalRGroups = remainingAdditionalGroupsAsArray.join(",");
			}
			else {
				Global.SignalR.ListOfAdditionalSignalRGroups = null;
			}
		}
	}

	stopHub() {
		if (this.hubConnection) {
			this.hubConnection.stop();
		}
		Global.SignalR = {
			CoreUrl: Global.SignalR.CoreUrl,
			ClientId: null,
			DateLoggedIn: null,
			DateLoggedInFormatted: null,
			Status: "Disconnected",
			joinedSignalRGroups: false,
			signalRHub: null
		};
	}

	broadcast(code: string, object?: any, groupName?: string) {
		if (code == "Files Uploaded" || code == "Files Changed") {
			Global.User.DebugMode && console.log(this.serviceName + "broadcast called: code = " + code + ", object = %O", object);
		}

		// if (groupName == 'System Updates' && code == 'SQL.NinjaStatistic.Insert') {
		// 	Global.User.DebugMode && console.log(this.serviceName + 'System Updates: code = ' + code + ', object = %O', object);
		// }

		if (object != undefined) {
			if (object.indexOf != undefined && object?.indexOf(",") > -1) {
				//Remove the leading Conn=xxx,
				object = object.substring(object.indexOf(",") + 1);
			}

			// if (object.indexOf != undefined && object?.indexOf("\r\n") > -1) {
			// 	Global.User.DebugMode && console.log(this.serviceName + "Multiple messages for group: " + groupName);
			// }
		}

		this.message = new ISignalRNotification();
		this.message.clientId = Global.SignalR.ClientId;
		this.message.code = code;
		this.message.object = object != null ? object : null;
		this.message.groupName = groupName != null ? groupName : null;
		this.broadcastMessages$.next(this.message);
	}

	joinGroup(groupName: string) {
		var service = this;
		var groupName = groupName.trim();
		var service = this;
		if (service.hubConnection?.state == signalR.HubConnectionState.Connected) {
			//console.log("joinGroup '" + groupName + "'");
			service.hubConnection.invoke("joinGroup", Global.User.currentUser.ODataAccessToken, groupName).then((data: any) => {
				//Global.User.DebugMode && console.log(service.serviceName + "Joined signalR group: " + groupName + ".");
			})
			.catch((err: Error) => console.error(service.serviceName + "Error in joinGroup for '" + groupName + "': %O", err));
		}
	};

	leaveGroup(groupName: string) {
		var service = this;
		if (service.hubConnection?.state == signalR.HubConnectionState.Connected) {
			service.hubConnection.invoke("leaveGroup", Global.User.currentUser.ODataAccessToken, groupName).then((data: any) => {
				//Global.User.DebugMode && console.log(service.serviceName + "Leaving signalR group: " + groupName + ".");
			})
			.catch((err: Error) => console.error(this.serviceName + "Error in leaveGroup for '" + groupName + "': %O", err));
		}
	}

	joinSignalRGroupsByTagNamePrefix() {
		var service = this;
		if (service.hubConnection?.state == signalR.HubConnectionState.Connected) {
			var successful = false;
			//-- join all SignalR groups for the user's list of accessible assets (for all system admins this will be all assets.)
			if (!service.hubConnection) {
				setTimeout(() => {
					if (!service.joinedGroupsByTagNamePrefix) {
						service.joinSignalRGroupsByTagNamePrefix(); //-- keep retrying until the connection is connected, and then join the groups. 500 MS for 10 times if necessary. 
					}
				}, 500, 3);
			}
			else {
				var listOfTagNamePrefixes = Global.SignalR.ListOfTagNamePrefixes;

				if (listOfTagNamePrefixes != null) {

					var tagNamePrefixArray = listOfTagNamePrefixes.split(",").distinct().toArray();
					var skipNumber = 0;
					var takeNumber = 100;
					var collectedNumber = 100;
					var totalNumberOfSignalRGroups = 0; 
					var currentListOfTagNamePrefixes = "";
					while (takeNumber == collectedNumber) {
						var currentListOfTagNamePrefixesArray = tagNamePrefixArray.skip(skipNumber)
																		.take(takeNumber)
																		.toArray();
		
						currentListOfTagNamePrefixes = currentListOfTagNamePrefixesArray.join(",");
						this.joinGroup(currentListOfTagNamePrefixes.toUpperCase()); //--These groups MUST be joined in Uppercase, since they are all sent to uppercase groups in SignalR. --Kirk T. Sherer, September 13, 2024. 
																		
						collectedNumber = currentListOfTagNamePrefixesArray.length;
						totalNumberOfSignalRGroups += collectedNumber;
		
						//Global.User.DebugMode && console.log(this.serviceName + "currentListOfTagNamePrefixes: " + currentListOfTagNamePrefixes);												
						skipNumber += 100;				 
					}
					
					console.log(this.serviceName + "Total Number of SignalR TagNamePrefix groups joined: " + totalNumberOfSignalRGroups + ".");		
					Global.SignalR.ListOfTagNamePrefixes = tagNamePrefixArray.join(); //--rejoining after the tagNamePrefixesArray was set to the distinct list of tag name prefixes.
					successful = true;		
					service.joinedGroupsByTagNamePrefix = true;								
				}
				else {
					if (!successful) {
						setTimeout(() => {
							if (!service.joinedGroupsByTagNamePrefix) {
								service.joinSignalRGroupsByTagNamePrefix(); //-- keep retrying until the connection is connected, and then join the groups. 500 MS for 10 times if necessary. 
							}
						}, 500, 3);
					}
				}
			}
		}
	}

	getUserByClientId(clientId: string) {
		var user = this.connectedClients
			.where((client: any) => {
				return client.ClientId === clientId;
			})
			.first().User;
		//Global.User.DebugMode && console.log("getUserByClientId user = %O", user);
		return user;
	}

	saveUserByClientId(clientId: string, user: any) {
		if (this.connectedClients == undefined) {
			this.connectedClients = [];
		}
		this.connectedClients = [
			{
				ClientId: clientId,
				User: user
			}
		]
			.concat(this.connectedClients)
			.distinct(function (a, b) {
				return a.ClientId == b.ClientId;
			})
			.toArray();
	}

	removeUserByClientId(clientId: string) {
		if (this.connectedClients != undefined) {
			this.connectedClients = this.connectedClients.where((c: any) => { return c.ClientId != clientId; }).toArray();
		}
	}

	BroadcastVideoFrameFromArray(videoObject: any) {
		var service = this.service;
		//Global.User.DebugMode && console.log("BroadcastVideoFrameFromArray invoked...");
		if (videoObject.frames.length > 0) {
			//Global.User.DebugMode && console.log("BroadcastVideoFrameFromArray: SignalR service broadcasting frame code " + videoObject.code);
			service.broadcast(videoObject.code, videoObject.frames.shift());
		}

		//If there are more than a certain frames in the queue, dump most of them
		if (videoObject.frames.length > 15) {
			//Global.User.DebugMode && console.log("BroadcastVideoFrameFromArray: more than 15 frames in the queue, dump most of them...");
			videoObject.frames = videoObject.frames.skip(videoObject.frames.length - 3);
		}

		//Global.User.DebugMode && console.log("BroadcastVideoFrameFromArray: calculating frame interval...");
		videoObject.frameInterval = 200 - ((videoObject.frames.length - 1) ^ 1.2) * 10;

		setTimeout((t) => {
			//Send the lowest numbered frame here.
			service.BroadcastVideoFrameFromArray(videoObject);
			//Global.User.DebugMode && console.log("BroadcastVideoFrameFromArray: Video Frame queue length = " + videoObject.frames.length + "  Interval = " + videoObject.frameInterval);
		}, videoObject.frameInterval);
	}

	notifyOtherClientsInGroup = function (groupName: string, code: string, clientObject: any) {
		var service = this.service;
		if (groupName != null && code != null && clientObject != null && service.hubConnection.state == "Connected") {
			service.hubConnection && service.hubConnection.invoke("NotifyOtherClients", Global.User.currentUser.ODataAccessToken, groupName, code, clientObject).then((data: any) => {
				if (groupName != "TestPattern") {
					Global.User.DebugMode && console.log(service.serviceName + "NotifyOtherClients group: " + groupName + ", code: '" + code + "', clientObject = %O", clientObject);
				}
			})
			.catch((err: Error) => {
				//console.error(this.serviceName + "Error in notifyOtherClients: %O", err)
			});
		}
	};

	notifySpecificClient = function (clientId: string, code: string, clientObject: any) {
		var service = this.service;
		if (clientId != null && code != null && clientObject != null && service.hubConnection.state == "Connected") {
			service.hubConnection.invoke("NotifySpecificClient", Global.User.currentUser.ODataAccessToken, clientId, code, clientObject).then((data: any) => {
				Global.User.DebugMode && console.log(service.serviceName + "NotifySpecificClient: clientId: " + clientId + ", code: '" + code + "', clientObject: %O", clientObject);
			})
			.catch((err: Error) => {
				//console.error(this.serviceName + "Error in notifySpecificClient: %O", err)
			});
		}
	};

	LogActivity(message?: string, username?: string) {
		var service = this;
		if (message != null) {
			var logRecord = {
				Date: new Date().getTime(),
				Message: message
			};
			service.currentActivityLog.push(logRecord);
		}

		if (service.hubConnection && service.hubConnection.state == "Connected" && (username != null || Global?.User?.currentUser?.Username != null)) {
			var activityEntriesToProcess = service.currentActivityLog;
			if (activityEntriesToProcess.length > 0) {
				console.log("activityEntriesToProcess = %O", activityEntriesToProcess);
				activityEntriesToProcess.forEach((item:any) => {
					var message = item.Date + "~" + item.Message;
					service.notifyOtherClientsInGroup("UserActivity", username != null ? username : Global.User.currentUser.Username, message);
					service.currentActivityLog = service.currentActivityLog.where((log:any) => { return log.Date != item.Date && log.Message != item.Message }).toArray();
				});
			}
		}
	} 

	waitUntilConnectedToSignalR() {
		var connectedToSignalR$ = new Observable((subscriber) => {
			subscriber.next(Global.SignalR.joinedSignalRGroups);
			if (Global.SignalR.joinedSignalRGroups == true) {
				subscriber.complete();
			}
		});

		return connectedToSignalR$;
	}

	setSignalRVariables = function (signalRHub: any) {
		Global.User.DebugMode && console.log("setting SignalR variables...");
		if (!this.connected) {
			this.connected = true;
			Global.User.DebugMode && console.log("SignalR start is done.  connected = " + this.connected);
			Global.User.DebugMode && console.log("Client Connect - Local ClientId:" + Global.SignalR.ClientId);

			Global.User.DebugMode && console.log("getting current user...");
			var currentUser = <IUser>JSON.parse(localStorage.getItem("currentUser"));
			Global.User.DebugMode && console.log("checking to see if currentUser exists...");
			if (currentUser) {
				var clientDataObject = {
					User: {
						Username: currentUser.Username,
						UserId: currentUser.Id,
						Email: currentUser.Email,
						FamilyName: currentUser.FamilyName,
						GivenName: currentUser.GivenName,
						MiddleName: currentUser.MiddleName
					},
					ClientId: Global.SignalR.ClientId,
					DateLoggedIn: +this.utilityService.DateTimeInMilliseconds(currentUser.DateLoggedIn),
					DateLoggedInFormatted: moment(currentUser.DateLoggedIn).format("YYYY-MM-DD HH:mm:ss")
				};
			}
			var dataObject = clientDataObject;
			Global.User.DebugMode && console.log("dataObject = %O", dataObject);
			if (dataObject) {
				this.Me = dataObject;
				this.signalRHub = signalRHub;
				this.saveUserByClientId(dataObject.ClientId, dataObject);
				Global.SignalR = {
					CoreUrl: Global.SignalR.CoreUrl,
					ClientId: this.connectionId,
					DateLoggedIn: +this.utilityService.DateTimeInMilliseconds(currentUser.DateLoggedIn),
					DateLoggedInFormatted: moment(currentUser.DateLoggedIn).format("YYYY-MM-DD HH:mm:ss"),
					Status: "Connected",
					joinedSignalRGroups: true,
					signalRHub: this.hubConnection
				};
			}
			this.connectionState = "connected";
			this.signalRPerformanceTest();
			Global.User.DebugMode && console.log("hub should be started...");
		}
	};

	getClientDataObject() {
		//Global.User.DebugMode && console.log("getting current user...");
		var u = <IUser>JSON.parse(localStorage.getItem("currentUser"));
		//Global.User.DebugMode && console.log("checking to see if 'u' exists...");
		if (u) {
			var clientDataObject = {
				User: {
					Username: u.Username,
					UserId: u.Id,
					Email: u.Email,
					FamilyName: u.FamilyName,
					GivenName: u.GivenName,
					MiddleName: u.MiddleName
				},
				// ClientId: $.connection.hub.id,
				DateLoggedIn: this.utilityService.DateTimeInMilliseconds(u.DateLoggedIn),
				DateLoggedInFormatted: moment(u.DateLoggedIn).format("YYYY-MM-DD HH:mm:ss")
			};
			Global.User.DebugMode && console.log(this.serviceName + "returning clientDataObject...%O", clientDataObject);
			return clientDataObject;
		}
		return null;
	}

	signalRPerformanceTest() {
		var startTime = performance.now();
		Global.User.DebugMode && console.log(this.serviceName + "signalR Client = %O", this.Me);
		if (this.Me) {
			this.notifySpecificClient(this.Me.ClientId, "signalRPerformanceTest", true);
		}
	}

	localLogIn(code: string, callerConnectionId: string, dataObject: any, groupName?: string) {
		if (this.localLogging) {
			Global.User.DebugMode && console.log(this.serviceName + "Hub->Me " + code + ", Client: " + callerConnectionId + ", Data: %O", dataObject);
			Global.User.DebugMode && console.log(this.serviceName + "Hub->Me " + code + " %O", dataObject);
		}
	}

	localLogOut(code: string, dataObject: any) {
		if (this.localLogging) {
			Global.User.DebugMode && console.log(this.serviceName + "Me->Hub " + code + ", Data:%O", dataObject);
		}
	}

	SignalRNotification(code: string, dataObject: any, callerConnectionId: any, groupName: any) {
		this.messageCount++;
		
		if (groupName == "UserActivity") {
			console.log("SignalRNotification ---> code: " + code + ", connectionId: " + callerConnectionId + ", groupName: " + groupName + ", dataObject = %O", dataObject);
		}

		if (code === undefined) {
			console.log(code);
			console.log(dataObject);
			console.log(callerConnectionId);
			console.log(groupName);
		}

		var elapsedMS = Date.now() - this.startTime;
		if (!groupName) {
			groupName = "";
		}

		this.localLogIn(code, callerConnectionId, dataObject, groupName);

		var fromUser;
		var fromUserName;
		var frameCount = 0;

		switch (true) {

			case code.substring(0, 11) == "VideoFrame ":
				//If the video control structure is not present for this video camera stream, then add it here.

				if (!this.videoFrames) {
					//-- have to initialize the array first...
					this.videoFrames = [];
				}

				if (!this.videoFrames[code]) {
					//-- then you can set the element in the array to the code that was sent....

					this.videoFrames[code] = {
						frames: [],
						frameInterval: 200,
						bytesReceived: 0,
						bytesPerSecond: 0,
						code: code,
						frameCounter: 0
					};

					//Schedule the first frame delivery, the routine will schedule the rest of them in a chain
					setTimeout(function () {
						//Send the lowest numbered frame here. Have to check to see if this.videoFrames exists first before you can broadcast it. --Kirk T. Sherer, March 17, 2020.

						if (this.videoFrames) {
							var videoObject = this.videoFrames[code];
							//Global.User.DebugMode && console.log("broadcasting video frame from array...");
							this.BroadcastVideoFrameFromArray(videoObject);
						}
					}, 100);
				}

				this.videoFrames[code].frames.push(dataObject);
				this.videoFrames[code].frameCounter++;
				this.videoFrames[code].bytesReceived += dataObject.FrameData.length;
				if (this.videoFrames[code].frameCounter % 5 == 0) {
					this.videoFrames[code].bytesPerSecond = this.videoFrames[code].bytesReceived;
					this.videoFrames[code].bytesReceived = 0;
					//Global.User.DebugMode && console.log(this.videoFrames[code].code + " Bytes/Sec = " + this.videoFrames[code].bytesPerSecond);
				}
				//debugger;
				this.broadcast(code, dataObject);

				break;

			case code == "System.InformationalMessage":
				fromUser = this.getUserByClientId(callerConnectionId);
				if (fromUser) {
					fromUserName = fromUser.User.GivenName + " " + fromUser.User.FamilyName;
					this.utilityService.showToastMessageShared({
						type: "info",
						message: dataObject,
						title: "Message from " + fromUserName
					});
					// this.toastr.info(
					// 	dataObject,
					// 	'Message from ' + fromUserName
					// );
					break;
				}

			//---B
			case code == "System.AlertMessage":
				// fromUser = this.getUserByClientId(callerConnectionId);
				// Global.User.DebugMode && console.log(this.serviceName + "Alert Message from user = %O", fromUser);
				// if (fromUser) {
				// 	fromUserName = fromUser.User.GivenName + " " + fromUser.User.FamilyName;
				// 	// swal.fire({
				// 	// 	title: 'New Message',
				// 	// 	buttonsStyling: false,
				// 	// 	confirmButtonText: 'Ok',
				// 	// 	confirmButtonClass: 'btn btn-success mr-1',
				// 	// 	html:
				// 	// 		"<div class='panel panel-default'>" +
				// 	// 		"<div class='panel-heading'>" +
				// 	// 		'Message from ' +
				// 	// 		fromUserName +
				// 	// 		'</div>' +
				// 	// 		"<div class='panel-body'>" +
				// 	// 		dataObject +
				// 	// 		'</div>' +
				// 	// 		'</div>',
				// 	// }).then((result) => {
				// 	// 	this.signalSpecificClient(
				// 	// 		callerConnectionId,
				// 	// 		'System.InformationalMessage',
				// 	// 		'......message acknowledged.'
				// 	// 	);
				// 	// });
				// }
				break;

			//---B
			case code == "System.SignalR.ClientConnected":
				if (dataObject != this.Me?.ClientId) {
					//Global.User.DebugMode && console.log(this.serviceName + "Other User connected to SignalR hub. ClientId: %O", dataObject);
				} else {
					Global.User.DebugMode && console.log(this.serviceName + "System.ClientConnected Data:%O", dataObject);
					this.saveUserByClientId(callerConnectionId, dataObject);
				}
				// //Tell the new user about us in response.
				// this.notifySpecificClient(dataObject.ClientId, "System.OnLineReportResponse", this.getClientDataObject());
				// this.consoleLogAllConnectedClients();
				// //Tell the rest of the system about it, in case some application wants to listen in on client connection events.
				// this.broadcast(code, dataObject);
				break;

			//---B
			case code == "System.ClientLogout":
				Global.User.DebugMode && console.log(this.serviceName + "System.ClientLogout Data:%O", dataObject);
				//Global.User.DebugMode && console.log("Our ClientId = " + $.connection.hub.id);
				// if (dataObject) {
				// 	this.removeUserByClientId(dataObject.ClientId);
				// 	this.removeAllEntityLocksForClientId(dataObject.ClientId);
				// 	//Tell the rest of the system about it, in case some application wants to listen in on client disconnection events.
				// 	this.broadcast(code, dataObject);
				// }
				break;

			//---B
			case code == "System.SignalR.ClientDisconnected":
				//The dataObject IS the clientID in this case.
				if (dataObject != this.Me?.ClientId) {
					//Global.User.DebugMode && console.log(this.serviceName + "Other User disconnected from SignalR hub. ClientId: %O", dataObject);
				} else {
					Global.User.DebugMode && console.log(this.serviceName + "Current User disconnected from SignalR hub. ClientId: %O", dataObject);
				}

				this.removeUserByClientId(dataObject);
				this.removeAllEntityLocksForClientId(dataObject);
				//Tell the rest of the system about it, in case some application wants to listen in on client disconnection events.
				// this.broadcast(code, dataObject);
				// this.consoleLogAllConnectedClients();
				break;

			//---B
			case code == "System.OnLineReportResponse":
				// if (dataObject) {
				// 	this.saveUserByClientId(dataObject.ClientId, dataObject);

				// 	if (!this.firstConnectedClient) {
				// 		this.firstConnectedClient = dataObject;
				// 		//Ask the first connected client about locked entites so we can start with a synced list like everybody else has.
				// 		this.notifySpecificClient(dataObject.ClientId, "System.ReportLockedEntities", null);
				// 	}
				// 	this.consoleLogAllConnectedClients();
				// }
				break;

			//---B
			case code == "System.ReportLockedEntities":
				// this.notifySpecificClient(callerConnectionId, "System.LockedEntitiesReport", this.OdataLockedEntities);
				// this.broadcast(code, dataObject);
				break;

			//---B
			case code == "System.LockedEntitiesReport":
				// dataObject.forEach(function (lockEntry) {
				// 	this.AddEntityLockEntryToLocalList(lockEntry);
				// });
				// Global.User.DebugMode && console.log(this.serviceName + "Current Locked Entities = %O", this.OdataLockedEntities);
				// this.broadcast(code, dataObject);
				break;

			//---B
			case code == "System.EntityLocked":
				// this.addEntityLockEntryToLocalList(dataObject);
				// Global.User.DebugMode && console.log(this.serviceName + "Entity Has Been Locked. OdataLockedEntitiesList now = %O", this.OdataLockedEntities);
				// this.broadcast("System.EntityLocked", dataObject);
				break;

			//---B
			case code == "System.EntityUnlocked":
				//Remove it from our locked list
				// this.removeEntityLockEntryFromLocalList(dataObject);
				// Global.User.DebugMode && console.log(this.serviceName + "Entity Has Been Unlocked. OdataLockedEntitiesList now = %O", this.OdataLockedEntities);

				// //Tell the entire local system about it.
				// this.broadcast("System.EntityUnlocked", dataObject);

				break;

			//---B
			default:
				if (this.messageCount % 100000 == 0) {
					Global.User.DebugMode && console.log(this.serviceName + "SignalR Messages = " + this.messageCount);
					Global.User.DebugMode && console.log(this.serviceName + "Last Hub->Me - signalR code: " + code + ", dataObject: %O", dataObject);
				} else {
					// if (code.indexOf("Recipe") != -1) {
					// 	Global.User.DebugMode && console.log(this.serviceName + "SignalR Message from SQL - code: " + code + ", dataObject = %O", dataObject);
					// }
				}

				//Global.User.DebugMode && console.log("signalR.service.ts: signalRNotification received. code = " + code + "  Data Object = " + dataObject);
				if (!Global.SignalR.countOfObservations) {
					Global.SignalR.countOfObservations = 0;
				}
				Global.SignalR.countOfObservations++;
				this.updateSignalRCounter();

				// if (code == 'SQL.Asset.Update') {
				// 	Global.User.DebugMode && console.log("signalR.service.ts: signalRNotification received. code = " + code + "  Data Object = " + dataObject);
				// }

				if (code == "Files Uploaded" || code == "Files Changed") {
					Global.User.DebugMode && console.log(this.serviceName + "signalr.service.ts: signalRNotification called: code = " + code + ", object = %O", dataObject);
				}

				//-----------------------------------------------------------------------------------------------------------------------------
				//-- NOTE:  DO NOT REMOVE THIS CODE -- OBSERVATIONS AND SIGNALR MESSAGES MUST BE PROCESSED IN THE INCOMING DATA ARRAYS BELOW --
				//--
				//-- The reason for this setup is to get away from this SignalR Service having to broadcast everything and setting up listeners
				//-- everywhere.  This setup allows for the data coming in to be placed into an incoming data array, and then the Data Service
				//-- will be processing the data with the appropriate functions that we were using whenever we were listening for updates via 
				//-- the SignalR broadcast function. --Kirk T. Sherer, August 1, 2024.
				//--
				//-- The SQL Server SignalR data (i.e. not actual tag observations) is processed by Cache Utility Service, not the Data Service. 
				//-- So the SQL Active Subjects are constructed in the Cache Utility Service and the updates to the respective SQL Server tables 
				//-- represented in the Data Cache are also processed in Cache Utility Service and not the Data Service. --Kirk T. Sherer, October 14, 2024. 
				//-----------------------------------------------------------------------------------------------------------------------------
				if (code != "o") {
					if (code.substring(0,4) == "SQL.") {
						//console.log("<-- SQL Server SignalR Update --> code = " + code + ", object = %O", dataObject + ", groupName = ", groupName);
						this.incomingDataService.incomingSQLDataArray.push({
							code: code, 
							object: dataObject, 
							groupName: groupName
						});
					}
					else {
						this.broadcast(code, dataObject, groupName); //-- only time we're broadcasting anything is when it's not a huge amount of data that needs to be processed more efficiently. 
					}
					
				}
				else {
					//Global.User.DebugMode && console.log("<-- Incoming SignalR observation --> code = " + code + ", object = %O", dataObject + ", groupName = ", groupName);
					this.incomingDataService.incomingDataArray.push({
						code: code, 
						object: dataObject, 
						groupName: groupName
					});
				}
				//-----------------------------------------------------------------------------------------------------------------------------
				
				
		}
	}

	updateSignalRCounter() {
		this.countOfSignalRObservations$.next(Global.SignalR.countOfObservations);
		// if (Global.SignalR.countOfObservations % 25 == 0) {
		// 	//-- print out a message every 25th count... --Kirk T. Sherer, December 2, 2020.
		// 	Global.User.DebugMode && console.log("signalR.service.ts: Global.SignalR.countOfObservations = " + Global.SignalR.countOfObservations);
		// }
	}

	sendEmail = function (emailData: any) {
		Global.User.DebugMode && console.log(this.serviceName + "Sending Email.....");
		return this.hubConnection.invoke("sendEmail", Global.User.currentUser.ODataAccessToken, emailData).then((data: any) => {
			return true;
		})
		.catch((err: Error) => console.error(this.serviceName + "Error in sendEmail. %O", err));
	};

	consoleLogAllConnectedClients() {
		//Global.User.DebugMode && console.log(this.serviceName + "Current clients connected to SignalR hub: %O", this.connectedClients);
		//Global.User.DebugMode && console.log("hub = %O", $.connection.hub);
	}

	signalClientsForLogout = function () {
		this.signalAllClients("System.ClientLogout", this.getClientDataObject());
	};

	addEntityLockEntryToLocalList(lockEntry: any) {
		var found = false;
		for (var lc = 0; lc < this.OdataLockedEntities.length; lc++) {
			if (this.OdataLockedEntities[lc].Id == lockEntry.Id && this.OdataLockedEntities[lc].odataSource == lockEntry.odataSource && this.OdataLockedEntities[lc].collection == lockEntry.collection) {
				found = true;
				break;
			}
		}
		if (!found) {
			Global.User.DebugMode && console.log(this.serviceName + "Lock entry was unique - adding to the list");
			this.OdataLockedEntities.push(lockEntry);
			Global.User.DebugMode && console.log(this.serviceName + "Current locks = %O", this.OdataLockedEntities);
		}
	}

	removeEntityLockEntryFromLocalList(lockEntry: any) {
		if (this.OdataLockedEntities) {
			for (var lc = 0; lc < this.OdataLockedEntities.length; lc++) {
				if (this.OdataLockedEntities[lc].entityTag == lockEntry.entityTag) {
					this.OdataLockedEntities.splice(lc, 1);
					break;
				}
			}
		}
	}

	removeAllEntityLocksForClientId(clientId: string) {
		if (this.OdataLockedEntities) {
			for (var lc = 0; lc < this.OdataLockedEntities.length; lc++) {
				if (this.OdataLockedEntities[lc].clientId == clientId) {
					this.OdataLockedEntities.splice(lc, 1);
				}
			}
		}
	}

	getLockingUserForEntity(odataSource: any, collection: any, entityId: any) {
		Global.User.DebugMode && console.log("Checking for locks.....");
		//See if this entity is already locked by someone else. (Not us)
		if (this.OdataLockedEntities.length > 0) {
			Global.User.DebugMode && console.log("Global Locks are present....%O", this.OdataLockedEntities);
			for (var x = 0; x < this.OdataLockedEntities.length; x++) {
				if (this.OdataLockedEntities[x].Id == parseInt(entityId)) {
					if (this.OdataLockedEntities[x].odataSource == odataSource) {
						if (this.OdataLockedEntities[x].collection == collection) {
							var lock = this.OdataLockedEntities[x];
							Global.User.DebugMode && console.log("entity lock is present - Applicable lock = %O", lock);

							//Find the person out of the logged in people who matches this client id.
							// if (lock.lockingClientId != this.Me.ClientId) {

							//     var lockUser = datacache.GetFromNamedCache("SignalRUsersByClientId", lock.lockingClientId);
							//     if (lockUser) {
							//         Global.User.DebugMode && console.log("Lock User = %O", lockUser.User);
							//         return lockUser.User;

							//     } else {
							//         Global.User.DebugMode && console.log("signalR did not find the locking client ID in the cache for users!!!!");
							//         Global.User.DebugMode && console.log("Cache for Users by Client Id = %O", datacache.GetAllValuesFromCache("SignalRUsersByClientId"));
							//     }
							// }
						}
					}
				}
			}
			return null;
		}
	}

	//Called by the local system to unlock an entity on all connected clients.
	unlockEntity = function (odataSourceName: any, collectionName: any, Id: any) {
		var entityLockData = this.getEntityLockingDataObject(odataSourceName, collectionName, Id);
		this.removeEntityLockEntryFromLocalList(entityLockData);
		Global.User.DebugMode && console.log("Current locks = %O", this.OdataLockedEntities);
		this.broadcast("System.EntityUnlocked", entityLockData);
	};

	signalAllClients(code: string, item: any) {
		//Signal down the chain locally.
		Global.User.DebugMode && console.log(this.serviceName + "Broadcasting " + code);
		this.broadcast(code, item);
		return this.signalOnlyOtherClients(code, item);
	}

	//---B
	signalAllClientsInGroup = function (groupName: any, code: string, item: any) {
		//Signal down the chain locally.
		Global.User.DebugMode && console.log(this.serviceName + "Broadcasting " + code);
		this.broadcast(code, item);
		return this.signalOnlyOtherClientsInGroup(groupName, code, item);
	};

	//---B
	signalAllClientsForCurrentUser = function (code: string, item: any) {
		//Signal down the chain locally.
		Global.User.DebugMode && console.log(this.serviceName + "Broadcasting " + code);
		this.broadcast(code, item);
		return this.notifyOtherClientsInGroup(Global.User.currentUser.Username, code, item);
	};

	//---B
	signalAllOtherClientsForCurrentUser = function (code: string, item: any) {
		//Signal down the chain locally.
		Global.User.DebugMode && console.log(this.serviceName + "Broadcasting " + code);
		return this.notifyOtherClientsInGroup(Global.User.currentUser.Username, code, item);
	};

	//---B
	signalOnlyOtherClients = function (code: string, item: any) {
		Global.User.DebugMode && console.log(this.serviceName + "Sending SignalR to all clients. Code = " + code + " object = %O", item);
		return this.notifyOtherClients(code, item);
	};

	//---B
	signalOnlyOtherClientsInGroup = function (groupName: any, code: string, item: any) {
		Global.User.DebugMode && console.log(this.serviceName + "Sending SignalR to all clients. Code = " + code + " object = %O", item);
		return this.notifyOtherClientsInGroup(groupName, code, item);
	};

	generateIdForPopupThatIsUnique(): string {
		let uniqueId: string;

		do {
			var pass = "";
			var str = "ABCDEFGHIJKLMNOPQRSTUVWXYZ" + "abcdefghijklmnopqrstuvwxyz0123456789@#$";

			for (let i = 1; i <= 8; i++) {
				var char = Math.floor(Math.random() * str.length + 1);

				pass += str.charAt(char);
			}

			console.log(pass);
			uniqueId = pass;
		} while (
			this.tagStorageObjectForSignalR.widgetArray.findIndex((widget: any) => {
				return widget.WidgetId === uniqueId;
			}) !== -1
		);
		return uniqueId;
	}
	//----------------------- Dylan's Test Functions for SignalR .Net Core ---------------------------------

	public startHubCall = () => {
		const options: IHttpConnectionOptions = {
			accessTokenFactory: () => {
				return "'VrtYWo9uySQqGtHyZEfvubJ8oQ1VHnLwBBHNKkExodfXTzDxLTYV+5LakrITp4e5CbFH9z9aNl9OQ=='";
			},
			skipNegotiation: false, //-- can't skip negotiation unless you're using WebSockets. Error if you try without WebSockets: 'Negotiation can only be skipped when using the WebSocket transport directly'
			transport: signalR.HttpTransportType.ServerSentEvents, //-- removed signalR.HttpTransportType.WebSockets since it's unreliable. Mark Thompson said the ServerSentEvents is much more reliable. --Kirk T. Sherer, October 10, 2022.
			//transport: signalR.HttpTransportType.WebSockets,
			withCredentials: false
		};
		this.hubConnection = new signalR.HubConnectionBuilder().withUrl("https://signalr.iopspro.com/DataServices/SignalRCore/chart", options).build();
	};

	public addTransferChartDataListener = () => {
		this.hubConnection.on("transferchartdata", (data) => {
			this.data = data;
			console.log(data);
		});
	};

	public broadcastChartData = () => {
		this.hubConnection.invoke("broadcastchartdata", this.data).then((data: any) => {
			Global.User.DebugMode && console.log(this.serviceName + "broadcastchartdata group: %O", data);
		})
		.catch((err) => console.error(err));
	};

	public addBroadcastChartDataListener = () => {
		this.hubConnection.on("broadcastchartdata", (data) => {
			this.broadcastedData = data;
		});
	};
}
