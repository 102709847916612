import { NgModule, Optional, SkipSelf } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthenticationService } from "./authentication.service";
import { UtilityService } from "./utility.service";
import { DataService } from "./data.service";
import { SystemService } from "./system.service";

import { throwIfAlreadyLoaded } from "./module-import-guard";
import { SecurityService } from "./security.service";
import { CacheUtilityService } from "./cache-utility.service";
import { IndexedDBService } from "./indexedDB.service";
import { DashboardService } from "./dashboard.service";
import { DisplaySetupService } from "./display-setup.service";
import { FileUploadService } from './file-upload.service';

import { RouteToExternalUrlService } from './route-to-external-url.service';
import { SignalRCoreService } from './signalr-core.service';
import { DialogService } from './dialog.service';
import { GseService } from './gse.service';
import { KendoSettingsService } from './kendo-settings.service';
import { QuestionControlService } from './question-control.service';
import { RecursiveDataNavigatorService } from './recursive-data-navigator.service';
import { VideoService } from './video.service';
import { WeatherService } from './weather.service';
import { IncomingDataService } from './incoming-data.service';

@NgModule({
	imports: [CommonModule],
	declarations: [],
	providers: [
		AuthenticationService,
		CacheUtilityService,
		DashboardService,
		DataService,
		DialogService,
		DisplaySetupService,
		FileUploadService,
		GseService,
		IndexedDBService,
		KendoSettingsService,
		QuestionControlService,
		RecursiveDataNavigatorService,
		RouteToExternalUrlService,
		SecurityService,
		SignalRCoreService,
		SystemService,
		UtilityService,
		VideoService,
		WeatherService,
		IncomingDataService
	],
})
export class ServicesModule {
	constructor(@Optional() @SkipSelf() parentModule: ServicesModule) {
		throwIfAlreadyLoaded(parentModule, 'ServicesModule');
	}
}
