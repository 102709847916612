import { Component, Inject, OnInit } from '@angular/core';
import {
	MatSnackBarRef,
	MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';

@Component({
	selector: 'lib-snackbar-broadcast-message',
	templateUrl: './snackbar-broadcast-message.component.html',
	styleUrls: ['./snackbar-broadcast-message.component.scss'],
})
export class SnackbarBroadcastMessageComponent implements OnInit {
	constructor(
		public snackBarRef: MatSnackBarRef<SnackbarBroadcastMessageComponent>,
		@Inject(MAT_SNACK_BAR_DATA) public data: any
	) {}

	ngOnInit(): void {}
}
