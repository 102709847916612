import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, HostListener, EventEmitter, Output, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { HttpEventType, HttpErrorResponse, HttpEvent } from "@angular/common/http";
import { of } from "rxjs";
import { catchError, filter, map } from "rxjs/operators";
import { FileUploadService } from "../../services/file-upload.service";
import { Global } from "../../_constants/global.variables";
import { UtilityService } from "../../services/utility.service";
import swal from "sweetalert2";
import { AlertController, ToastController } from "@ionic/angular";
import { MatDialogRef } from "@angular/material/dialog";
import { IGlobal } from "../../_models/global.model";
import { File, IWriteOptions, FileEntry } from "@ionic-native/file/ngx";
import { Plugins, CameraResultType, Capacitor, FilesystemDirectory, CameraPhoto, CameraSource } from "@capacitor/core";
import { Platform } from "@ionic/angular";
import { FormGroup } from "@angular/forms";
import { DataService } from "../../services/data.service";
import { Router } from "@angular/router";

import * as WebVPPlugin from "capacitor-video-player";
import { VideoService } from "../../services/video.service";
import { SignalRCoreService } from "../../services/signalr-core.service";
import { DomSanitizer } from "@angular/platform-browser";
const {
	Camera,
	Filesystem,
	Storage,
	CameraDirection,
	CapacitorVideoPlayer,
	CameraOptions,
} = Plugins;

@Component({
	selector: "lib-file-upload-list-mobile",
	templateUrl: "./file-upload-list-mobile.component.html",
	styleUrls: ["./file-upload-list-mobile.component.scss"],
})
export class FileUploadListMobileComponent implements OnInit {
	@Input() public fileImageLibraryControlObject: any;

	@ViewChild("fileUpload", { static: false }) fileUpload: ElementRef;
	files = [];
	@Output("dropZone") fileDrop = new EventEmitter<Array<File>>();

	@ViewChild("video") captureElement: ElementRef;
	mediaRecorder: any;
	videoPlayer: any;
	videos = [];

	// public photos: Photo[] = [];
	// private PHOTO_STORAGE: string = "photos";
	private platform: Platform;

	public listTitle: string = "File Uploads";
	public listSubtitle: string = "Upload file(s) here.";
	public iconmenu: string =
		"doc,docx,xls,xlsm,xlsx,msg,pdf,ppt,pptx,js,css,mp4,mp3,wav,zip,txt,json,config,cs,aspx,html,tif,tiff,vsd,chm,asp,sql,hl7,xml,linq,rdl,wma,msi,exe,reg,csv,webm,avi";

	public uploadReordinal: number = 100000;
	public newFiles: any;
	public canBeClosed: boolean = true;
	public showCancelButton: boolean = true;
	public dropzoneActive: boolean = false;
	public currentUpload: any;

	private videoKeyURL: string = Global.Video.serverArchiveUrl;
	private accessToken: string = Global.User.currentUser.ODataAccessToken;

	private fileIndex: number = 0;
	private fileImageLibraryData: Array<File>;
	private currentImageKeyList: string;
	private swalWithBootstrapButtons: any;
	public entityId: string;
	public entityType: string;
	public showSettings: boolean = false;
	public readOnly: boolean = false;
	private componentName: string = "file-upload";
	public global: IGlobal = Global;

	public fileListInDialogWindow: boolean = false;

	private fileObject: any;
	public isRecordingVideo: boolean = false;
	public takingVideo: boolean = false;
	public useRearCamera: boolean = true;
	private filesUploaded$: any;

	constructor(
		platform: Platform,
		public dialogRef: MatDialogRef<FileUploadListMobileComponent>,
		private fileUploadService: FileUploadService,
		private elementRef: ElementRef,
		private utilityService: UtilityService,
		private toastr: ToastController,
		private file: File,
		private alertify: AlertController,
		private dataService: DataService,
		private router: Router,
		public videoService: VideoService,
		private changeDetector: ChangeDetectorRef,
		private signalR: SignalRCoreService,
		private sanitizer: DomSanitizer
	) {
		this.platform = platform;
	}

	ngOnInit() {
		var service = this;
		setTimeout(function () {
			service.addEventListenerToDropArea(service);
		}, 200);

		if (this.fileImageLibraryControlObject) {
			this.fileObject = this.fileImageLibraryControlObject;
		} else {
			this.fileObject = Global.Dialog.currentDialogObject.widgetObject;
			this.fileListInDialogWindow = true;
		}
		Global.User.DebugMode &&
			console.log(
				"this.fileListInDialogWindow = " + this.fileListInDialogWindow
			);
		Global.User.DebugMode &&
			console.log("this.fileObject = %O", this.fileObject);
		this.listTitle = this.fileObject.listTitle;
		this.entityId = this.fileObject.entityId;
		this.entityType = this.fileObject.entityType;
		this.readOnly = !Global.User.isAdmin;

		this.loadFileList();
	}

	ngAfterViewInit() {
		var service = this;
		service.addEventListenerToDropArea(service);

		service.filesUploaded$ = service.signalR.broadcastMessages$
			.pipe(
				filter(
					(msg: any) =>
						msg.code == "Files Uploaded" ||
						msg.code == "Files Changed"
				)
			)
			.subscribe((data: any) => {
				var sqlEntity = data.object == null ? data.code : data.object;
				Global.User.DebugMode &&
					console.log(
						service.componentName +
							": SignalR Message received. Code: " +
							data.code +
							", data.object received via SignalR: %O",
						data.object
					);
				Global.User.DebugMode &&
					console.log(
						service.componentName + ": this.fileObject = %O",
						service.fileObject
					);

				if (
					service.fileObject.entityType == data.object.entityType &&
					service.fileObject.entityId == data.object.entityId &&
					service.fileObject.imageKeys !== data.object.imageKeys
				) {
					Global.User.DebugMode &&
						console.log(
							service.componentName +
								": imageKey list for this entity is not the same as what was received via SignalR.  Loading the file list again..."
						);

					service.fileObject.imageKeys = data.object.imageKeys; //-- set to the list of image keys we just received from this SignalR message.
					service.loadFileList(); //-- go get the file list since the image key list changed from another source. --Kirk T. Sherer, April 7, 2021.
				}
			});
		this.videoService.loadVideos().then((videos: any) => {
			this.videos = videos;
		});

		// Initialise the video player plugin
		if (Capacitor.isNative) {
			this.videoPlayer = CapacitorVideoPlayer;
		} else {
			this.videoPlayer = WebVPPlugin.CapacitorVideoPlayer;
		}
	}

	ngOnDestroy() {
		Global.User.DebugMode &&
			console.log(
				"closing dialog and sending out SignalR broadcast with referenced fileObject... %O",
				this.fileObject
			);
		this.signalR.broadcast("Updated File Count", this.fileObject);
	}

	//-- Camera functions for mobile device

	async takePicture() {
		console.log("attempting to add a picture...");

		const capturedPhoto = await Camera.getPhoto({
			resultType: CameraResultType.Uri, // file-based data; provides best performance
			source: CameraSource.Camera, // automatically take a new photo with the camera
			quality: 100, // highest quality (0 to 100)
			direction: CameraDirection.Front,
		});

		// Convert photo to base64 format, required by Filesystem API to save
		this.readAsBase64(capturedPhoto).then((base64Data: string) => {
			this.uploadFile(base64Data, this, base64Data);
		});
	}

	takeVideo() {
		this.takingVideo = true;
	}

	backToList() {
		this.takingVideo = false;
		this.isRecordingVideo = false;
	}

	changeCamera() {
		this.useRearCamera = !this.useRearCamera;
		var cameraDescription = this.useRearCamera ? "Rear" : "Front";

		this.utilityService.showToastMessageShared({
			type: "success",
			message: "Now using " + cameraDescription + " camera...",
		});
	}

	async recordVideo() {
		//-- recording video...
		this.isRecordingVideo = true;
		// Create a stream of video capturing
		console.log("this.captureElement = %O", this.captureElement);

		const stream = await navigator.mediaDevices.getUserMedia({
			video: {
				facingMode: this.useRearCamera ? "environment" : "user", //'user' --> 'user' = front camera, 'environment' = rear camera.
			},
			audio: true,
		});

		console.log("stream = %O", stream);
		console.log("this.captureElement = %O", this.captureElement);

		// Show the stream inside our video object
		this.captureElement.nativeElement.srcObject = stream;

		var options = { mimeType: "video/webm" };
		this.mediaRecorder = new MediaRecorder(stream, options);
		let chunks = [];

		// Store the video on stop
		this.mediaRecorder.onstop = async (event) => {
			console.log("data available after MediaRecorder.stop() called.");
			const videoBuffer = new Blob(chunks, { type: "video/webm" });
			console.log("videoBuffer = %O", videoBuffer);
			const base64Data = (await this.videoService.convertBlobToBase64(
				videoBuffer
			)) as string;
			console.log("base64Data = " + base64Data);
			this.uploadFile(base64Data, this, base64Data); //-- this should upload it to the file image library.

			await this.videoService.storeVideo(videoBuffer);

			// Reload our list
			this.videos = this.videoService.videos;
			this.changeDetector.detectChanges();
		};

		// Store chunks of recorded video
		this.mediaRecorder.ondataavailable = (event: any) => {
			if (event.data && event.data.size > 0) {
				chunks.push(event.data);
			}
		};

		// Start recording wth chunks of data
		this.mediaRecorder.start(100);
	}

	stopVideoRecording() {
		this.isRecordingVideo = false;
		this.takingVideo = false;

		this.mediaRecorder.stop();
		console.log("video recorder stopped.  data available for upload.");
	}

	async playVideo(videoFile: any) {
		// Get the video as base64 data
		const realUrl = await this.videoService.getVideoUrl(videoFile);

		// Show the player fullscreen
		await this.videoPlayer.initPlayer({
			mode: "fullscreen",
			url: realUrl,
			playerId: "fullscreen",
			componentTag: "app-home",
		});
	}

	// Read camera photo into base64 format based on the platform the app is running on
	private async readAsBase64(cameraPhoto: CameraPhoto) {
		// "hybrid" will detect Cordova or Capacitor
		if (this.platform.is("hybrid")) {
			// Read the file into base64 format
			const file = await Filesystem.readFile({
				path: cameraPhoto.path,
			});

			return file.data;
		} else {
			// Fetch the photo, read as a blob, then convert to base64 format
			const response = await fetch(cameraPhoto.webPath!);
			const blob = await response.blob();

			return (await this.convertBlobToBase64(blob)) as string;
		}
	}

	convertBlobToBase64 = (blob: Blob) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onerror = reject;
			reader.onload = () => {
				resolve(reader.result);
			};
			reader.readAsDataURL(blob);
		});

	private async constructFileRecordForList(f: any) {
		const formData = new FormData();
		var file: any = {};
		file.data = {};
		file.data.lastModified =
			this.utilityService.JavascriptDateTimeInMilliseconds(f.CreateDate);
		file.data.lastModifiedDate = new Date(f.CreateDate);
		file.data.name = f.Filename;
		file.data.description = f.Description;
		file.data.size = f.OriginalSize;
		file.data.type = "";
		file.data.webkitRelativePath = "";

		formData.append("file", file.data);
		file.inProgress = false;
		file.name = f.Filename;
		file.lastModifiedDate = new Date(f.CreateDate);
		file.size = f.OriginalSize;
		file.imageKey = f.ImageKey;
		var fileExtension = f.Filename.split(".")[1];
		var defaultVideoFileName = f.ImageKey + "." + fileExtension;
		file.isVideoServerImage =
			f.IsVideoServerImage == null ? false : f.IsVideoServerImage;
		file.videoServerFilename = f.VideoServerFilename
			? f.VideoServerFilename
			: f.IsVideoServerImage
			? defaultVideoFileName
			: null;
		file.thumbnailImage = f.ThumbnailBase64
			? this.sanitizer.bypassSecurityTrustResourceUrl(
					"data:image/png;base64," + f.ThumbnailBase64
			  )
			: null;
		file = this.setFileImage(file);
		Global.User.DebugMode &&
			console.log(this.componentName + ": file = %O", file);
		return file;
	}

	readFile(file: any) {
		const reader = new FileReader();
		reader.onloadend = () => {
			const imgBlob = new Blob([reader.result], {
				type: file.type,
			});
			const formData = new FormData();
			formData.append("name", "Hello");
			formData.append("file", imgBlob, file.name);
			this.uploadFile(formData, this);
		};
		reader.readAsArrayBuffer(file);
	}

	showSettingsMenu() {
		this.showSettings = !this.showSettings;

		Global.User.DebugMode &&
			console.log("this.showSettings = " + this.showSettings);
	}

	addEventListenerToDropArea(service: any) {
		var svc = this;

		const dropZone = document.body;
		Global.User.DebugMode && console.log("dropZone = %O", dropZone);
		dropZone.addEventListener(
			"drop",
			function (event) {
				//debugger
				event.preventDefault();
				event.stopPropagation();

				var items = event.dataTransfer.items;
				for (var i = 0; i < items.length; i++) {
					// webkitGetAsEntry is where the magic happens
					var item = items[i];
					Global.User.DebugMode && console.log("item = %O", item);
					if (item) {
						service.traverseFileTree(item);
					}
				}

				service.uploadFiles();
			},
			false
		);
	}

	onDropEvent(items: any) {
		Global.User.DebugMode &&
			console.log("onDropEvent invoked... %O", items);
		event.preventDefault();
		event.stopPropagation();

		var service = this;

		const fileUpload = this.fileUpload.nativeElement;
		fileUpload.onchange = () => {
			for (let index = 0; index < fileUpload.files.length; index++) {
				const file = fileUpload.files[index];
				this.files.push({ data: file, inProgress: false, progress: 0 });
			}
		};

		this.fileUpload.nativeElement.value = "";

		for (var i = 0; i < items.length; i++) {
			// webkitGetAsEntry is where the magic happens
			var item = items[i];
			Global.User.DebugMode && console.log("item = %O", item);

			var fileObject: any = {};
			fileObject.inProgress = false;
			fileObject.uploadProgress = 0;
			fileObject.downloadProgress = 0;
			fileObject.data = item;

			if (fileObject.data.type != "") {
				//service.traverseFileTree(fileObject);
				var fileAlreadyExists: boolean = false;
				this.files.forEach((file: any) => {
					if (fileObject.data.name == file.data.name) {
						fileAlreadyExists = true;
					}
				});
				if (!fileAlreadyExists) {
					this.files.push(fileObject);
					this.uploadFile(fileObject, this);
				}
			}
		}
	}

	closeDialog() {
		Global.User.DebugMode && console.log("trying to close dialog...");
		this.signalR.broadcast("Updated File Count", this.fileObject);
	}

	dropzoneState(event: any) {
		Global.User.DebugMode &&
			console.log("files hovered over drop zone...%O", event);
		this.dropzoneActive = event;
	}

	private uploadFiles(service: any) {
		service.fileUpload.nativeElement.value = "";
		Global.User.DebugMode && console.log("this.files = %O", service.files);

		service.files.forEach((file) => {
			if (!file.imageKey) {
				Global.User.DebugMode &&
					console.log("uploadFiles: file = %O", file);
				this.uploadFile(file, service);
			}
		});
	}

	onClick() {
		const fileUpload = this.fileUpload.nativeElement;
		fileUpload.onchange = () => {
			for (let index = 0; index < fileUpload.files.length; index++) {
				const file = fileUpload.files[index];
				this.files.push({ data: file, inProgress: false, progress: 0 });
			}
			this.uploadFiles(this);
		};

		fileUpload.click();
	}

	uploadFile(file: any, service: any, base64String?: string) {
		const formData = new FormData();
		console.log(
			service.componentName + ": uploadFile invoked --> file = %O",
			file
		);
		var listOfCurrentFiles = this.files;

		if (base64String) {
			// Split the base64 string in data and contentType
			var block = base64String.split(";");
			// Get the content type of the image
			var contentType = block[0].split(":")[1]; // Example: "image/png"
			//-- get the file extension from the content type.
			var fileExtension = contentType.split("/")[1];
			var lastModifiedDate = new Date();
			//--set a filename to the last modified date.getTime() with the file extension.
			var fileName = lastModifiedDate.getTime() + "." + fileExtension;

			file = {};
			file.inProgress = true;
			file.name = fileName;
			file.lastModifiedDate = lastModifiedDate;
			file.size = base64String.length / 1000;
			file = this.setFileImage(file);
			Global.User.DebugMode &&
				console.log(this.componentName + ": file = %O", file);
			formData.append("file", base64String);
		} else {
			formData.append("file", file.data);
			file.inProgress = true;
			var filename = file.data.name;
			if (filename.indexOf(".") == -1) {
				filename = filename + ".png"; //-- if there is no file extension, put one on the file before uploading it. --Kirk T. Sherer, February 5, 2021.
			}
			file.name = filename;
			file.lastModifiedDate = new Date(file.data.lastModifiedDate);
			file.size = file.data.size / 1000;
			file = service.setFileImage(file);
		}

		file.inProgress = true;
		file.uploading = true;

		Global.User.DebugMode && console.log("file = %O", file);

		service.fileUploadService.upload(formData).subscribe(
			(event: HttpEvent<any>) => {
				var Sent = event.type === HttpEventType.Sent;
				var DownloadProgress =
					event.type === HttpEventType.DownloadProgress;
				var UploadProgress =
					event.type === HttpEventType.UploadProgress;
				var Response = event.type === HttpEventType.Response;
				var ResponseHeader =
					event.type === HttpEventType.ResponseHeader;
				var User = event.type === HttpEventType.User;

				//Global.User.DebugMode && console.log(service.componentName + ": HttpEventType.UploadProgress = " + UploadProgress + ", HttpEventType.Response = " + Response + ", HttpEventType.Sent = " + Sent + ", HttpEventType.DownloadProgress = " + DownloadProgress + ", HttpEventType.ResponseHeader = " + ResponseHeader + ", HttpEventType.User = " + User);
				switch (event.type) {
					case HttpEventType.UploadProgress:
						//-- if we get this status, we're sending the file to the server and this is the upload progress to the server. --Kirk T. Sherer, January 21, 2021
						file.uploadProgress = Math.round(
							(event.loaded * 100) / event.total
						);
						Global.User.DebugMode &&
							console.log(
								service.componentName +
									": uploadProgress event -> %O",
								event
							);
						break;
					case HttpEventType.Response:
						//-- if we get this status, the server has the file and now we can add its image key to the list of image keys for the collection. --Kirk T. Sherer, January 21, 2021
						Global.User.DebugMode &&
							console.log(
								service.componentName +
									": uploadFile: event.type = " +
									event.type +
									", event -> %O",
								event
							);
						if (typeof event === "object") {
							if (event.body) {
								file.inProgress = false;
								Global.User.DebugMode &&
									console.log(
										service.componentName +
											": event.body = %O",
										event.body
									);
								var imageKeyExists =
									service.fileObject.imageKeys.firstOrDefault(
										(f: any) => {
											f.imageKey == event.body.ImageKey;
										}
									);
								if (!imageKeyExists) {
									//if (!service.currentImageKeyList || (service.currentImageKeyList && service.currentImageKeyList.indexOf(event.body.ImageKey) == -1)) {
									if (
										service.currentImageKeyList == undefined
									) {
										service.currentImageKeyList = "";
									}
									service.currentImageKeyList +=
										(service.currentImageKeyList != ""
											? ","
											: "") + event.body.ImageKey;
									file.name = event.body.Filename;
									var fileExtension =
										event.body.Filename.split(".")
											.pop()
											.toLowerCase();
									if (
										service.iconmenu.indexOf(
											fileExtension.toLowerCase()
										) > -1
									) {
										//This is an icon file display. Assign the right one.
										file.iconFile =
											Global.imagesUrl +
											"assets/img/FileUpload/icon-" +
											fileExtension +
											".png";
									} else {
										file.iconFile = null;
									}

									file.lastModifiedDate =
										event.body.lastModifiedDate;
									file.size = event.body.OriginalSize;
									file.imageKey = event.body.ImageKey;
									file.isVideoServerImage =
										event.body.IsVideoServerImage;
									file.videoServerFilename = event.body
										.VideoServerFilename
										? event.body.VideoServerFilename
										: event.body.IsVideoServerImage
										? event.body.Filename
										: null;
									file.thumbnailImage = event.body
										.ThumbnailBase64
										? this.sanitizer.bypassSecurityTrustResourceUrl(
												"data:image/png;base64," +
													event.body.ThumbnailBase64
										  )
										: null;

									if (service.fileObject) {
										service.fileObject.addUploadFunction(
											file.imageKey
										);
									}
									service.setFileImage(file);

									//-- NOTE: this next portion must remain for mobile only. Above file properties are really for desktop, but retained them here. --Kirk T. Sherer, April 9, 2021.
									this.constructFileRecordForList(
										event.body
									).then((file: any) => {
										console.log(
											"push file on list.  File object = %O",
											file
										);
										service.files.push(file);
										console.log(
											"list after pushing new file on stack = %O",
											service.files
										);
										service.changeDetectorReference();
									});
								} else {
									console.log(
										"ImageKey '" +
											event.body.ImageKey +
											"' exists in currentImageKeyList. service.currentImageKeyList = " +
											service.currentImageKeyList
									);
									console.log(
										"list after processing from webAPI = %O",
										service.files
									);
								}
								// if (!service.currentImageKeyList || (service.currentImageKeyList && service.currentImageKeyList.indexOf(event.body.ImageKey) == -1)) {
								// 	if (service.currentImageKeyList == undefined) {
								// 		service.currentImageKeyList = "";
								// 	}
								// 	service.currentImageKeyList += (service.currentImageKeyList != "" ? "," : "") + event.body.ImageKey;
								// 	file.imageKey = event.body.ImageKey;
								// 	file.isVideoServerImage = event.body.IsVideoServerImage;
								// 	file.videoServerFilename = event.body.VideoServerFilename;
								// 	if (service.fileObject) {
								// 		service.fileObject.addUploadFunction(file.imageKey);
								// 	}
								// 	this.constructFileRecordForList(event.body).then((file:any) => {
								// 		console.log("push file on list.  File object = %O", file);
								// 		service.files.push(file);
								// 		console.log("list after pushing new file on stack = %O", service.files);
								// 		service.changeDetectorReference();
								// 	});
								// }
							}
						}
						break;
					case HttpEventType.DownloadProgress:
						//-- if we get this status, this is the progress from the server for the file being uploaded. --Kirk T. Sherer, January 21, 2021
						file.downloadProgress = Math.round(
							(event.loaded * 100) / event.total
						);
						Global.User.DebugMode &&
							console.log(
								service.componentName +
									": downloadProgress event -> %O",
								event
							);
						break;
					case HttpEventType.ResponseHeader:
						Global.User.DebugMode &&
							console.log(
								service.componentName +
									": uploadFile: event.type is not Response or UploadProgress.  It is 'HttpEventType.ResponseHeader', event -> %O",
								event
							);
						break;
					case HttpEventType.Sent:
						Global.User.DebugMode &&
							console.log(
								service.componentName +
									": uploadFile: event.type is not Response or UploadProgress.  It is 'HttpEventType.Sent', event -> %O",
								event
							);
						break;
				}
			},
			(err: HttpErrorResponse) =>
				console.error(
					service.componentName +
						": " +
						`Error with ${file.name} file upload: ${err.message}`
				),
			() => {
				console.log(
					service.componentName +
						": " +
						file.name +
						" has been uploaded."
				);
			}
		);
	}

	changeDetectorReference() {
		this.changeDetector.detectChanges();
	}

	private setFileImage(file: any) {
		Global.User.DebugMode &&
			console.log(this.componentName + ": setFileImage: file = %O", file);
		var includeThumbnail = false;
		var filename = file.data ? file.data.name : file.name;

		if (filename == "Pasted Image") {
			includeThumbnail = true;
		} else if (filename.indexOf(".") == -1) {
			//-- user didn't name the file with a file extension.  Add the appropriate file extension so we can get the correct thumbnail picture, if applicable. --Kirk T. Sherer, February 5, 2021.
			if (file.IsVideoServerImage) {
				filename = file.videoServerFilename;
			} else {
				filename = filename + ".png";
			}
		}

		var fileExtension = filename.split(".").pop().toLowerCase();
		// Global.User.DebugMode && console.log("fileExtension = " + fileExtension);

		if (
			fileExtension == "jpg" ||
			fileExtension == "png" ||
			fileExtension == "jpeg" ||
			fileExtension == "gif" ||
			fileExtension == "bmp"
		) {
			includeThumbnail = true;
		}
		// Global.User.DebugMode && console.log(this.componentName + ": this.iconmenu = " + this.iconmenu);
		// Global.User.DebugMode && console.log(this.componentName + ": this.iconmenu.indexOf(fileExtension.toLowerCase()) = " + this.iconmenu.indexOf(fileExtension.toLowerCase()));

		//Assign an icon for the file
		if (this.iconmenu.indexOf(fileExtension.toLowerCase()) > -1) {
			//This is an icon file display. Assign the right one.
			file.iconFile =
				Global.imagesUrl +
				"assets/img/FileUpload/icon-" +
				fileExtension +
				".png";
		} else {
			file.iconFile = null;
		}

		// Global.User.DebugMode && console.log(this.componentName + ": file.iconFile = " + file.iconFile);

		file.thumbnailUrl = null; //-- shouldn't show a thumbnailUrl unless includeThumbnail has been set earlier. --Kirk T. Sherer, April 9, 2021.

		if (file.imageKey != null) {
			if (file.isVideoServerImage) {
				var url =
					this.videoKeyURL +
					file.videoServerFilename +
					"?accessToken=" +
					encodeURIComponent(this.accessToken);
				file.viewUrl = url;
			} else {
				var url = this.dataService.imageKeyURL + file.imageKey;
				if (includeThumbnail) {
					file.thumbnailUrl = url;
				}
				file.viewUrl = url;
			}
		}

		// Global.User.DebugMode && console.log(this.componentName + ": file.thumbnailUrl = " + file.thumbnailUrl);

		return file;
	}

	// private setFileImage(file: any, base64String?: string) {
	// 	Global.User.DebugMode && console.log("setFileImage: file = %O", file);
	// 	var includeThumbnail = false;
	// 	var filename = file.data?.name;
	// 	if (!filename) {
	// 		filename = file.name; //--this came from a base64 String that was converted to an image. --Kirk T. Sherer, January 29, 2021.
	// 		var creationDate = new Date();
	// 		if (file.data) {
	// 			file.data.lastModified = this.utilityService.JavascriptDateTimeInMilliseconds(creationDate);
	// 			file.data.lastModifiedDate = creationDate;
	// 			file.data.name = filename;
	// 			file.data.size = file.size == null ? 0 : file.size,
	// 			file.data.type = "",
	// 			file.data.webkitRelativePath = "",
	// 			file.data.base64String = base64String
	// 		}
	// 		else {
	// 			file.data = {
	// 				lastModified: this.utilityService.JavascriptDateTimeInMilliseconds(creationDate),
	// 				lastModifiedDate: creationDate,
	// 				name: filename,
	// 				size: file.size == null ? 0 : file.size,
	// 				type: "",
	// 				webkitRelativePath: "",
	// 				base64String: base64String
	// 			};
	// 		}
	// 	}

	// 	if (filename == "Pasted Image") {
	// 		includeThumbnail = true;
	// 	} else if (filename.indexOf('.') == -1) {
	// 		//-- user didn't name the file with a file extension.  Add the appropriate file extension so we can get the correct thumbnail picture, if applicable. --Kirk T. Sherer, February 5, 2021.
	// 		if (file.IsVideoServerImage) {
	// 			filename = file.videoServerFilename;
	// 		}
	// 		else {
	// 			filename = filename + '.png';
	// 		}
	// 	}

	// 	var fileExtension = filename.split('.').pop().toLowerCase();
	// 	// Global.User.DebugMode && console.log("fileExtension = " + fileExtension);
	// 	if (fileExtension == "jpg" || fileExtension == "png" || fileExtension == "jpeg" || fileExtension == "gif" || fileExtension == "bmp" ) {
	// 		includeThumbnail = true;
	// 	}
	// 	// Global.User.DebugMode && console.log("this.iconmenu = " + this.iconmenu);
	// 	// Global.User.DebugMode && console.log("this.iconmenu.indexOf(fileExtension.toLowerCase()) = " + this.iconmenu.indexOf(fileExtension.toLowerCase()));

	// 	//Assign an icon for the file
	// 	if (this.iconmenu.indexOf(fileExtension.toLowerCase()) > -1) {
	// 		//This is an icon file display. Assign the right one.
	// 		if (fileExtension == 'webm') {
	// 			file.iconFile = Global.imagesUrl + "assets/img/FileUpload/icon-mp4.png";
	// 		} else {
	// 			file.iconFile = Global.imagesUrl + "assets/img/FileUpload/icon-" + fileExtension + ".png";
	// 		}
	// 	}
	// 	else {
	// 		file.iconFile = null;
	// 	}

	// 	// Global.User.DebugMode && console.log("file.iconFile = " + file.iconFile);

	// 	if (file.imageKey != null) {

	// 		if (file.isVideoServerImage) {
	// 			var url = this.videoKeyURL + file.videoServerFilename + "?accessToken=" + encodeURIComponent(this.accessToken);
	// 			file.thumbnailUrl = null;
	// 			file.viewUrl = url;
	// 		}
	// 		else {
	// 			file.thumbnailUrl = this.imageKeyURL + file.imageKey;
	// 			file.viewUrl = file.thumbnailUrl;
	// 		}
	// 	}
	// 	// Global.User.DebugMode && console.log("file.thumbnailUrl = " + file.thumbnailUrl);

	// 	return file;
	// }

	selectDropdownMenu() {}

	async deleteFile(file: any, event: Event) {
		event.stopPropagation();
		var service = this;
		Global.User.DebugMode &&
			console.log(
				service.componentName + ": service.files = %O",
				service.files
			);
		var listOfCurrentFiles = service.files;

		Global.User.DebugMode &&
			console.log(
				service.componentName +
					": Marking " +
					file.name +
					" file for deletion..."
			);
		Global.User.DebugMode &&
			console.log(service.componentName + ": file = %O", file);

		const alert = await service.alertify.create({
			header: "Are you sure?",
			message:
				"You are trying to delete the '<strong>" +
				file.name +
				"</strong>' file from the <strong>" +
				service.listTitle +
				"</strong>. Cancel if you would rather keep it.",
			buttons: [
				{
					text: "OK",
					handler: async () => {
						if (service.fileObject) {
							service.fileObject.removeUploadFunction(
								file.imageKey
							);
							listOfCurrentFiles.forEach(function (item, index) {
								if (item.imageKey == file.imageKey) {
									listOfCurrentFiles.splice(index, 1);
									service.files = listOfCurrentFiles;
								}
							});
							service.rebuildCurrentImageKeyList();
							service.utilityService.showToastMessageShared({
								type: "success",
								message:
									"'" +
									file.name +
									"' file has been DELETED.",
							});
						}
					},
				},
				{
					text: "Cancel",
					role: "cancel",
					cssClass: "secondary",
					handler: async () => {
						service.utilityService.showToastMessageShared({
							type: "info",
							message:
								"'" + file.name + "' has NOT been deleted.",
						});
					},
				},
			],
		});

		await alert.present();
	}

	public editFile(file: any, event: Event) {
		event.stopPropagation();
		event.preventDefault();

		Global.User.DebugMode &&
			console.log(this.componentName + ": Editing file = %O", file);
		event.stopPropagation();
		file.edit = true;
	}

	public viewFile(file: any) {
		if (!file.edit) {
			Global.User.DebugMode &&
				console.log("trying to view the file... file = %O", file);
			var url = file.viewUrl;

			var intHSize = window.screen.availWidth * 0.7;
			var intVSize = window.screen.availHeight * 0.7;
			var intTop = (window.screen.availHeight - intVSize) / 2;
			var intLeft = (window.screen.availWidth - intHSize) / 2;
			var sngWindow_Size_Percentage = 0.85;

			var strFeatures = "";
			intHSize =
				window.screen.availWidth * sngWindow_Size_Percentage * 0.6;
			intVSize = window.screen.availHeight * sngWindow_Size_Percentage;
			intTop = (window.screen.availHeight - intVSize) / 2;
			intLeft = (window.screen.availWidth - intHSize) / 2;
			strFeatures =
				"top=" +
				intTop +
				",left=" +
				intLeft +
				",height=" +
				intVSize +
				",width=" +
				intHSize +
				", scrollbars=yes, resizable=yes, location=no";

			var SRWindow = window.open(url, "SRWindow", strFeatures);
		}
	}

	updateFileName(file: any, event: Event) {
		Global.User.DebugMode &&
			console.log(
				this.componentName + ": Edit file change result = %O",
				event
			);
		var changedObject: any = event.target;
		Global.User.DebugMode &&
			console.log(
				this.componentName + ": changedObject = %O",
				changedObject
			);
		Global.User.DebugMode &&
			console.log(
				this.componentName +
					": changedObject.value = " +
					changedObject.value
			);
		var newFileName = changedObject.value;
		var originalFileName = file.data.name;
		var originalExtension = originalFileName.split(".")[1];
		Global.User.DebugMode &&
			console.log(
				this.componentName +
					": newFileName = " +
					newFileName +
					", originalFileName = " +
					originalFileName +
					", originalExtension = " +
					originalExtension
			);

		if (newFileName.indexOf(".") == -1) {
			newFileName = newFileName + "." + originalExtension; //-- if user removed the extension, put it back. --Kirk T. Sherer, February 5, 2021.
		}

		if (newFileName != originalFileName) {
			file.data.name = newFileName;
			var sqlStatement =
				"API.FileImage_UpdateFileName @ImageKey='" +
				file.imageKey +
				"', @FileName='" +
				newFileName +
				"'";
			this.dataService
				.SQLActionAsPromise(sqlStatement)
				.then((data: any) => {
					Global.User.DebugMode &&
						console.log(
							this.componentName +
								": " +
								sqlStatement +
								" data = %O",
							data
						);
					if (data && data.length > 0) {
						this.utilityService.showToastMessageShared({
							type: "success",
							message:
								"The '" +
								originalFileName +
								"' file has been renamed to '" +
								newFileName +
								"'.",
						});
					}
				});
		} else {
			this.utilityService.showToastMessageShared({
				type: "info",
				message: "File '" + originalFileName + "' remains unchanged.",
			});
		}
		file.edit = false;
	}

	updateFileDescription(file: any, event: Event) {
		Global.User.DebugMode &&
			console.log(
				this.componentName +
					": updateFileDescription invoked... event = %O",
				event
			);
		var changedObject: any = event.target;
		Global.User.DebugMode &&
			console.log(
				this.componentName + ": changedObject = %O",
				changedObject
			);
		Global.User.DebugMode &&
			console.log(
				this.componentName +
					": changedObject.value = " +
					changedObject.value
			);
		var newFileDescription = changedObject.value;
		var originalFileDescription = file.data.description;

		Global.User.DebugMode &&
			console.log(
				this.componentName +
					": newFileDescription = " +
					newFileDescription +
					", originalFileDescription = " +
					originalFileDescription
			);

		if (newFileDescription != originalFileDescription) {
			file.data.description = newFileDescription;
			var sqlStatement =
				"API.FileImage_UpdateFileDescription @ImageKey='" +
				file.imageKey +
				"', @Description='" +
				newFileDescription +
				"'";
			this.dataService
				.SQLActionAsPromise(sqlStatement)
				.then((data: any) => {
					Global.User.DebugMode &&
						console.log(
							this.componentName +
								": " +
								sqlStatement +
								" data = %O",
							data
						);
					if (data && data.length > 0) {
						this.utilityService.showToastMessageShared({
							type: "success",
							message:
								"The '" +
								file.data.name +
								"' file description has been updated.",
						});
					}
				});
		} else {
			this.utilityService.showToastMessageShared({
				type: "info",
				message:
					"The '" +
					file.data.name +
					"' file description remains unchanged.",
			});
		}
		file.edit = false;
	}

	closeWindow(): void {
		this.dialogRef.close();
	}

	removeAllFiles(event: Event) {
		event.stopPropagation();
		var service = this;
		Global.User.DebugMode && console.log("this.files = %O", this.files);
		var listOfCurrentFiles = this.files;

		this.swalWithBootstrapButtons = swal.mixin({
			customClass: {
				confirmButton: "btn btn-danger",
				cancelButton: "btn btn-success",
			},
			buttonsStyling: false,
		});
		Global.User.DebugMode && console.log("Trying to remove all files...");

		this.swalWithBootstrapButtons
			.fire({
				title: "Are you sure?",
				html:
					"You are trying to remove <strong>ALL</strong> files from the <strong>" +
					this.listTitle +
					"</strong>. Cancel if you would rather keep them.",
				showCancelButton: true,
				confirmButtonText: "Remove All Files",
				cancelButtonText: "Cancel",
				reverseButtons: false,
			})
			.then((result: any) => {
				if (result.value) {
					// logic for deleting files goes here.
					listOfCurrentFiles.forEach(function (item, index) {
						if (service.fileObject) {
							service.fileObject.removeUploadFunction(
								item.imageKey
							);
						}
					});
					this.rebuildCurrentImageKeyList();

					service.files = [];
					this.utilityService.showToastMessageShared({
						type: "success",
						message: "ALL files have been REMOVED.",
					});
				} else {
					this.utilityService.showToastMessageShared({
						type: "info",
						message: "No files have been removed.",
					});
				}
			});

		this.showSettings = false; //-- this closes the menu for deleting all files.
	}

	// loadFileList() {
	// 	if (this.fileObject && this.fileObject.imageKeys && this.fileObject.imageKeys.length > 0) {
	// 		Global.User.DebugMode && console.log("imageKeys = %O", this.fileObject.imageKeys);
	// 		var imageKeyArray = this.fileObject.imageKeys;
	// 		this.currentImageKeyList = "";
	// 		var numberOfImageKeys = 0;
	// 		imageKeyArray.forEach((record: any) => {
	// 			this.currentImageKeyList += record.ImageKey;
	// 			numberOfImageKeys++;
	// 			if (numberOfImageKeys < imageKeyArray.length) {
	// 				this.currentImageKeyList += ",";
	// 			}
	// 		});

	// 		Global.User.DebugMode && console.log("this.currentImageKeyList = " + this.currentImageKeyList);
	// 		var reordinal = 0;

	// 		this.fileImageLibraryData = [];
	// 		this.fileUploadService.list(this.currentImageKeyList).pipe(
	// 			map(event => {
	// 				Global.User.DebugMode && console.log("ByImageKeyList: event -> %O", event);
	// 				return event;
	// 			}),
	// 			catchError((error: HttpErrorResponse) => {
	// 				return of(`${error} list failed.`);
	// 			})).subscribe((event: any) => {
	// 				if (typeof (event) === 'object' && event.body != undefined) {
	// 					Global.User.DebugMode && console.log("event.body = %O", event.body);
	// 					if (event.body.value != undefined) {
	// 						var unsortedList = event.body.value;
	// 						var listOfFiles = unsortedList.orderBy((data: any) => { return data.CreateDate }).toArray();

	// 						listOfFiles.forEach((f: any) => {
	// 							Global.User.DebugMode && console.log("---> uploaded file = %O", f);
	// 							this.constructFileRecordForList(f).then((file:any) => {
	// 								this.files.push(file);
	// 								Global.User.DebugMode && console.log("this.files = %O", this.files);
	// 							});
	// 						});
	// 					}
	// 				}
	// 			})
	// 	}

	// }

	rebuildCurrentImageKeyList() {
		var imageKeyArray = this.fileObject.imageKeys;
		this.currentImageKeyList = "";
		var numberOfImageKeys = 0;
		imageKeyArray.forEach((record: any) => {
			this.currentImageKeyList += record.ImageKey;
			numberOfImageKeys++;
			if (numberOfImageKeys < imageKeyArray.length) {
				this.currentImageKeyList += ",";
			}
		});

		Global.User.DebugMode &&
			console.log(
				this.componentName +
					": this.currentImageKeyList = " +
					this.currentImageKeyList
			);
	}

	loadFileList() {
		if (
			this.fileObject &&
			this.fileObject.imageKeys &&
			this.fileObject.imageKeys.length > 0
		) {
			Global.User.DebugMode &&
				console.log(
					this.componentName + ": this.fileObject.imageKeys = %O",
					this.fileObject.imageKeys
				);
			var imageKeyArray = this.fileObject.imageKeys;
			this.rebuildCurrentImageKeyList();
			var reordinal = 0;

			this.fileImageLibraryData = [];
			this.fileUploadService
				.list(this.currentImageKeyList)
				.pipe(
					map((event) => {
						Global.User.DebugMode &&
							console.log(
								this.componentName +
									": ByImageKeyList: event -> %O",
								event
							);
						return event;
					}),
					catchError((error: HttpErrorResponse) => {
						return of(`${error} list failed.`);
					})
				)
				.subscribe((event: any) => {
					if (typeof event === "object" && event.body != undefined) {
						Global.User.DebugMode &&
							console.log(
								this.componentName + ": event.body = %O",
								event.body
							);
						if (event.body.value != undefined) {
							var unsortedList = event.body.value;
							var listOfFiles = unsortedList
								.orderBy((data: any) => {
									return data.CreateDate;
								})
								.toArray();

							var countOfFiles: number = 1;
							this.files = [];
							listOfFiles.forEach((f: any) => {
								Global.User.DebugMode &&
									console.log("---> uploaded file = %O", f);
								this.constructFileRecordForList(f).then(
									(file: any) => {
										this.files.push(file);
										Global.User.DebugMode &&
											console.log(
												"this.files = %O",
												this.files
											);
									}
								);
								countOfFiles++;
							});
							Global.User.DebugMode &&
								console.log(
									this.componentName + ": this.files = %O",
									this.files
								);
						}
					}
				});
		} else {
			this.files = [];
		}
		this.changeDetectorReference();
	}

	pasteTargetOnClickEvent(event: Event) {
		Global.User.DebugMode &&
			console.log("pasteTargetOnClickEvent: event = %O", event);
		//-- save all modified descriptions in the file upload list here. --Kirk T. Sherer, April 27, 2020.
	}

	traverseFileTree(fileObject: any, path?: string) {
		//-- use this function whenever you can figure out how to use the createReader and readEntries functions on a directory object.--Kirk T. Sherer, May 21, 2020.
		var service = this;
		path = path || "";
		Global.User.DebugMode &&
			console.log("traverseFileTree fileObject = %O", fileObject);

		if (fileObject.data && fileObject.data?.type != "") {
			////Global.User.DebugMode && console.log(this.componentName + ": Adding to queue in new routine");
			service.uploadFile(fileObject, service);
		}
		// else {
		// 	// Get folder contents
		// 	var dirReader = fileObject.data.createReader();
		// 	dirReader.readEntries(function (entries) {
		// 		for (var i = 0; i < entries.length; i++) {
		// 			service.traverseFileTree(entries[i], path + fileObject.data.name + "/");
		// 		}
		// 	});
		// }
	}
}
