import { Injectable, NgZone, ApplicationRef } from "@angular/core";
import { Global } from "projects/shared-lib/src/lib/_constants/global.variables";
import { UtilityService } from "projects/shared-lib/src/lib/services/utility.service";
import { SwUpdate, VersionEvent } from "@angular/service-worker";
import { concat, first, interval, Subscription } from "rxjs";
import { AlertController, ToastController } from "@ionic/angular";

@Injectable({
	providedIn: "root"
})
export class ServiceWorkerService {
	public subscriptions: Subscription[] = [];
	public isIos: boolean;
	public serviceName: string = "service-worker: ";

	constructor(private utilityService: UtilityService, private swUpdate: SwUpdate, private applicationReference: ApplicationRef, private alertify: AlertController, private toastr: ToastController) {
		const userAgent = window.navigator.userAgent.toLowerCase();
		//console.log(this.serviceName + "/iphone|ipad|ipod/.test(userAgent) = %O", /iphone|ipad|ipod/.test(userAgent));
		var isAppleDevice = /iphone|ipad|ipod/.test(userAgent);
		//console.log(this.serviceName + "isAppleDevice: " + isAppleDevice)
		this.isIos = isAppleDevice;
		console.log(this.serviceName + "isIos device: " + this.isIos);
		if (this.isIos && this.swUpdate.isEnabled) {
			//-- set up additional software update check since iOS doesn't reload the entire app when shutting down the process on the iOS device. --Kirk T. Sherer, November 6, 2020.
			this.initializeVersionUpdater();
		}
	}

	async onUpdateAvailable(event: VersionEvent) {
		var updateMessage = "";

		if (event.type == "VERSION_READY") {
			console.log("A new version of iOPS is ready to be installed: ", updateMessage);
			updateMessage = event.latestVersion.appData["updateMessage"];
			const alert = await this.alertify.create({
				header: "iOPS Update Available",
				message: "A new vesion of the iOPS Application is available and will now be installed." + `<br /><br /><strong>Details:</strong> ${updateMessage}`,
				buttons: [
					{
						text: "OK",
						handler: async () => {
							this.utilityService.showToastMessageShared({
								type: "info",
								message: "iOPS Application updating..."
							});
							await this.swUpdate.activateUpdate().then(() => {
								this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
								window.location.reload();
							});
						}
					}
				]
			});

			await alert.present();
		} else if (event.type == "VERSION_DETECTED") {
			console.log("A new version of iOPS is available.");
		}
	}

	checkForiOSDevice(): boolean {
		console.log("userAgent = %O", window.navigator.userAgent.toLowerCase());
		const userAgent = window.navigator.userAgent.toLowerCase();
		console.log("/iphone|ipad|ipod/.test(userAgent) = %O", /iphone|ipad|ipod/.test(userAgent));
		var isAppleDevice = /iphone|ipad|ipod/.test(userAgent);
		return isAppleDevice;
	}

	initializeVersionUpdater() {
		console.log("initializeVersionUpdater invoked for this iOS device...");
		const updateInterval$ = interval(1000 * 60 * 1); //-- Check for updates every minute...
		const appIsStable$ = this.applicationReference.isStable.pipe(first((isStable) => isStable === true));
		const appIsStableInterval$ = concat(appIsStable$, updateInterval$);

		this.subscriptions.push(
			this.swUpdate.versionUpdates.subscribe((e) => {
				// console.log("iOPS Software Update Available -> current version: %O", e.current);
				// console.log("iOPS Software Update Available -> available version: %O", e.available);
				this.onUpdateAvailable(e);
			})
		);
		this.subscriptions.push(
			appIsStableInterval$.subscribe(() => {
				this.checkForApplicationUpdate();
			})
		);
	}

	async checkForApplicationUpdate() {
		console.log(this.serviceName + ": this.swUpdate.isEnabled = " + this.swUpdate.isEnabled);
		if (this.swUpdate.isEnabled) {
			await this.swUpdate.checkForUpdate();
		}
	}

	manualCheckForApplicationUpdate() {
		console.log(this.serviceName + "manualCheckForApplicationUpdate invoked. Global.isMobile = " + Global.isMobile);
		console.log(this.serviceName + "this.swUpdate.isEnabled = " + this.swUpdate.isEnabled);
		try {
			if (this.swUpdate.isEnabled) {
				var time0 = performance.now();
				this.utilityService.showToastMessageShared({
					type: "info",
					message: "Checking for software update..."
				});
				this.swUpdate
					.checkForUpdate()
					.then((event:any) => {
						var time1 = performance.now();
						var totalTime = time1 - time0;
						console.log(this.serviceName + "checkForUpdate invoked and returned. Time to check: " + totalTime);
						if (totalTime < 20000) {
							//-- software update takes longer than 20,000 milliseconds, so if it's less than 20,000, just send a toaster message. --Kirk T. Sherer, November 24, 2020.
							this.utilityService.showToastMessageShared({
								type: "success",
								message: "iOPS Application is up-to-date."
							});
						} else {
							console.log(this.serviceName + "Software is being updated, so user's alertify window should have appeared indicating there's an update.");
							this.utilityService.showToastMessageShared({
								type: "success",
								message: "New iOPS Application version detected..."
							});
							this.updateApplication(event);
						}
					})
					.catch((err: Error) => {
						console.error(`${this.serviceName}checkForUpdate error: ${err}`);
						this.utilityService.showToastMessageShared({
							type: "warning",
							message: `Check for Software Update error: ${err}`
						});
					});
			} else {
				this.utilityService.showToastMessageShared({
					type: "warning",
					message: "Software Updates not available for this device."
				});
			}
		}
		catch (e) {
			this.utilityService.showToastMessageShared({
				type: "warning",
				message: "Software Updates not available for this device."
			});
		}
	}

	async updateApplication(event: any) {
		var updateMessage = "";

		if (event.type == "VERSION_READY" || event.type == "VERSION_DETECTED") {
			console.log("A new version of iOPS is ready to be installed: ");
			updateMessage = event.latestVersion.appData["updateMessage"];
			const alert = await this.alertify.create({
				header: "iOPS Update Available",
				message: "A new vesion of the iOPS Application is available and will now be installed." + `<br /><br /><strong>Details:</strong> ${updateMessage}`,
				buttons: [
					{
						text: "OK",
						handler: async () => {
							this.utilityService.showToastMessageShared({
								type: "info",
								message: "iOPS Application updating..."
							});
							await this.swUpdate.activateUpdate().then(() => {
								window.location.reload();
							});
						}
					}
				]
			});

			await alert.present();
		}
	}
}
