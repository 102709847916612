import { QuestionBase } from './question-base';

export class FileUploadQuestion extends QuestionBase<string> {
  controlType = 'file-upload';
  type = 'number';

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
  }
}
