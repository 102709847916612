<ion-card>
  <ion-card-header>
    <ion-card-title>Device Motion</ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <button full class="primary" (click)="startTracking()">Start Tracking</button>
    <button full class="secondary" (click)="stopTracking()">Stop Tracking</button>
    <ion-card *ngIf="data">
      <ion-card-content>
        <h2>X: {{data.x | number:'1.4-4'}}</h2>
        <h2>Y: {{data.y | number:'1.4-4'}}</h2>
        <h2>Z: {{data.z | number:'1.4-4'}}</h2>
      </ion-card-content>
    </ion-card>
  </ion-card-content>
</ion-card>
