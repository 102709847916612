import { QuestionBase } from './question-base';

export class SliderYesNoQuestion extends QuestionBase<string> {
  controlType = 'slider-yes-no';
  type = 'boolean';

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
  }
}
