import { NgModule } from "@angular/core";
import { SecondsToHoursMinutesSeconds } from "./seconds-to-hours-minutes-seconds.pipe";
import { FileSizePipe } from './file-size.pipe';
import { FormatTimePipe } from "./format-time.pipe";
import { DateAgoPipe } from './date-ago.pipe';
import { DecimalPlacesPipe } from './decimal-places.pipe';
import { DecimalPipe } from '@angular/common';
import { SanitizedUrlPipe } from "./sanitized-url.pipe";

@NgModule({
	imports: [],
	declarations: [
		SecondsToHoursMinutesSeconds,
		FileSizePipe,
		FormatTimePipe,
		DateAgoPipe,	
		DecimalPlacesPipe,
		SanitizedUrlPipe
	],
	exports: [
		SecondsToHoursMinutesSeconds,
		FileSizePipe,
		FormatTimePipe,
		DateAgoPipe,
		DecimalPlacesPipe,
		SanitizedUrlPipe
	],
	providers: [DecimalPipe, DecimalPlacesPipe],
})
export class PipeModule {
	static forRoot() {
		return {
			ngModule: PipeModule,
			providers: [],
		};
	}
}
