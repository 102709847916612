import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
	AbstractControl,
	AsyncValidatorFn,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { Observable, of } from "rxjs";
import { catchError, delay, map, tap } from "rxjs/operators";
import { Global } from "../_constants/global.variables";
import { QuestionBase } from "../_models/dynamic-fields/questions/question-base";
import { isObjectOrNothingValidator } from "../_other-template-components/dynamic-form/validators/is-object-or-nothing.validator";
import { isObjectValidator } from "../_other-template-components/dynamic-form/validators/is-object.validator";

@Injectable()
export class QuestionControlService {
	public httpHeaders: HttpHeaders;
	public serviceName: string = "question-control-service: ";

	constructor(private http: HttpClient) {}

	toFormGroup(questions: QuestionBase<string>[]) {
		let group: any = {};

		let sortedQuestions: QuestionBase<string>[];
		sortedQuestions = questions && questions.sort((a, b) => a.order - b.order);
		Global.User.DebugMode && console.log(this.serviceName + "sortedQuestions = %O",	sortedQuestions);
		sortedQuestions.forEach((question: any) => {
			//console.log(this.serviceName + "question = %O", question);
			//Global.User.DebugMode && console.log(this.serviceName + "field name: '" + question.key + "', required: " + question.required + ", value: " + question.value + ", type: " + question.type + ", controlType: " + question.controlType);

			//-- for primary-key-field... if null then leave as null and don't convert to '' (nothing). --Kirk T. Sherer, December 12, 2022.
			if (question.type == "primary-key-field") {
				if (question.value == null || question.value == undefined || question.value == "") {
					group[question.key] = new FormControl(null);
				} else {
					group[question.key] = new FormControl(question.value);
				}
			} else {
				var questionValue =	question.controlType == "number" ? question.value : question.value || "";
				group[question.key] = question.required ? new FormControl(questionValue, Validators.required) : new FormControl(questionValue);
				if (question.type == "email") {
					if (!question.required) {
						//-- we're just validating the pattern of what is entered as an email address, but it's not required.
						group[question.key] = new FormControl(questionValue, Validators.email);
					} else {
						//-- we're validating the pattern of what is entered as an email address, but we're also requiring something be entered.
						group[question.key] = new FormControl(questionValue, [Validators.required, Validators.email ]);
					}
				}
			}

			if (question.validateObject) {
				if (question.allowBlankValue) {
					group[question.key].setValidators(isObjectOrNothingValidator()); //-- if we arrived here we're allowing the selector to be cleared out of any text and it allows the form to be saved. --Kirk T. Sherer, June 30, 2021.
				} else {
					group[question.key].setValidators(isObjectValidator()); //-- if we arrived here we're basically saying if the selector is not an object then don't allow the form to be saved without a value in this selector field. --Kirk T. Sherer, June 30, 2021.
				}
			}

			Global.User.DebugMode &&
				console.log(
					this.serviceName + "group[" + question.key + "] = %O",
					group[question.key]
				);
		});

		Global.User.DebugMode &&
			console.log(this.serviceName + "formGroup = %O", group);

		sortedQuestions.forEach((question: any) => {
			if (question.controlType == "text-differences-comparison") {
				console.log("question = %O", question);
				console.log("group = %O", group);
				console.log(
					"group[question.leftFile] = %O",
					group[question.leftFile]
				);
				console.log(
					"group[question.rightFile] = %O",
					group[question.rightFile]
				);
				question.left = group[question.leftFile].value;
				question.right = group[question.rightFile].value;
				console.log(
					"text-differences-comparison question = %O",
					question
				);
			}
		});

		return new FormGroup(group);
	}
}
