<ion-card>
  <ion-card-header>
    <ion-card-title>Vibration</ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <button full (click)="vibrateDevice()">Vibrate Mobile Device</button>
    <div *ngIf="vibratedDevice">
      <h3>Vibrated mobile device.</h3>
    </div>
  </ion-card-content>
</ion-card>
