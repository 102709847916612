<ion-card>
  <ion-card-header>
    <ion-card-title>Camera</ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <button full primary (click)="takePicture()">Take Picture</button>
    <ion-card *ngIf="imageSource">
      <img [src]="imageSource">
    </ion-card>
  </ion-card-content>
</ion-card>
