import { IGlobal } from "../_models/global.model";
/*
	NOTE:  	PLEASE DO NOT change this Global object to an 'any' object.  It MUST be of type IGlobal since we're referencing it by the strongly-typed IGlobal type throughout the application.
			If there are syntax errors with the Global object being of type IGlobal, that means you must add/change the global.model.ts to match whatever field(s) you are changing
			in the Global object. Thanks. --Kirk T. Sherer, November 17, 2023.
*/
export const Global: IGlobal = {
	Initial: {
		href: null,
		route: null,
		baseUrl: null
	},
	Theme: "light",
	DefaultThemeForApplication: "light",
	DefaultColorForDesktopVersion: "light-gray",
	DefaultThemeForApplication_AirportSiteUsers: "dark",
	DefaultColorForDesktopVersion_AirportSiteUsers: "dark-blue",
	LogosForApplicationTheme: {
		Dark: {
			//-- Oshkosh iOPS Logo --
			// iOPSLogo: "staticImages/assets/img/Oshkosh/Official iOPS OSK Logo-White.png",
			// iOPSTinyLogo: "staticImages/assets/img/Oshkosh/Official iOPS OSK Logo-White.png",
			//-- Original iOPS Logo --
			iOPSLogo: "staticImages/assets/img/iOPSProLogoWhite.png",
			iOPSTinyLogo: "staticImages/assets/img/iOPSProLogoWhite-Tiny.png",
			//--
			CompanyTinyLogo: "staticImages/assets/img/Oshkosh/Oshkosh AeroTech Oval - White.png",
			CompanyLogo: "staticImages/assets/img/Oshkosh/oshkosh_logo_white.png"
		},
		Light: {
			iOPSLogo: "staticImages/assets/img/iOPSProLogo.png",
			iOPSTinyLogo: "staticImages/assets/img/iOPSProLogo-Tiny.png",
			//--
			CompanyTinyLogo: "staticImages/assets/img/Oshkosh/Oshkosh AeroTech Oval.png",
			CompanyLogo: "staticImages/assets/img/Oshkosh/oshkosh_logo.png"
		}
	},
	passwordToken: null,
	Dialog: {
		lastRouteURL: "",
		currentDialogObject: null,
		componentName: ""
	},
	Weather: {
		apiKey: "5d46e2e019e07e3456a7f1257caeef75",
		apiUrl: "https://api.openweathermap.org/data/2.5"
	},
	Application: {
		Environment: {
			Name: null,
			Id: 0,
			Version: null
		},
		Type: "desktop"
	},
	isMobile: false,
	serverLoading: false,
	FullDataCacheExists: false,
	CurrentMenuItem: null,

	//-- uncomment the next three lines if you are starting with a dark theme for the application (i.e. Global.Theme = 'dark')
	//-- Oshkosh iOPS Logo --
	// iOPSLogoImage: "staticImages/assets/img/Oshkosh/Official iOPS OSK Logo-White.png", //-- for main menu image when full menu is displayed.
	// iOPSTinyLogoImage: "staticImages/assets/img/Oshkosh/Official iOPS OSK Logo-White.png", //-- for main menu image when sidebar mini menu is displayed.
	//-- Original iOPS Logo --
	iOPSLogoImage: "staticImages/assets/img/iOPSProLogoWhite.png",
	iOPSTinyLogoImage: "staticImages/assets/img/iOPSProLogoWhite-Tiny.png",
	//--
	CompanyLogoImage: "staticImages/assets/img/Oshkosh/oshkosh_logo_white.png", //-- for startup logo when loading the application; also being used as bottom, left-hand logo for mobile; top, right-hand corner for desktop.
	CompanyTinyLogoImage: "staticImages/assets/imgOshkosh/Oshkosh AeroTech Oval - White.png", //-- for smaller main menu image when the mini-menu is being displayed (only for desktop at the moment).

	webRoot: document.URL.indexOf("localhost/iOPS-SVN/iOPS") > 0 ? "/iOPS-SVN/iOPS/Website/" : document.URL.indexOf("localhost/iOPSPro-Development") > 0 ? "/iOPSPro-Development/Website/" : document.URL.indexOf("localhost/iOPSSVN/iOPS") > 0 ? "/iOPSSVN/iOPS/Website/" : "/",
	imagesUrl: "staticImages/",
	SignalR: {
		//-- You must comment out this next line and uncomment the line after it if you're moving the Test application to use Production data.  Otherwise, the next line must be used to determine which SignalR hub is appropriate for Test vs Prod/Beta.
		//-- NOTE: Next line must be changed for federated model once URL structure has been defined.
		CoreUrl: document.URL.indexOf("test") > 0 || document.URL.indexOf("localhost") > 0 ? "https://signalr.test.iopspro.com/DataServices/SignalRCore/iOPSHub" : "https://signalr.iopspro.com/DataServices/SignalRCore/iOPSHub",
		//CoreUrl: 'https://signalr.test.iopspro.com/DataServices/SignalRCore/iOPSHub', //--Test Only
		//CoreUrl: 'http://localhost:5000/iOPSHub',
		ClientId: "",
		DateLoggedIn: null,
		DateLoggedInFormatted: "",
		Status: "",
		joinedSignalRGroups: false,
		signalRHub: null,
		countOfObservations: 0,
		testInProgress: false,
		ListOfTagNamePrefixes: null,
		ListOfAdditionalSignalRGroups: null
	},
	User: {
		isAdmin: false,
		isOrgAdmin: false,
		isSiteAdmin: false,
		currentUser: null,
		isLoggedIn: false,
		SidebarMini: false,
		MenuColor: "blue",
		DebugMode: false,
		isBeingAuthenticated: false,
		isLoggedInAsDifferentUser: false,
		loggingIntoLegacyApplication: false,
		isRequestingAccount: false,
		PermittedSites: [],
		Privilege: null,
		Role: null,
		prefersDarkTheme: false,
		needToBuildMenu: true,
		issueNotificationCount: 0,
		currentRoute: null,
		Menu: {
			Dashboards: null,
			MobileUserFunctions: null,
			DesktopMenuItems: null,
			Privileges: null
		}
	},
	RDN: {
		recursedSets: [],
		maxId: 0,
		editingForm: false,
		internalDialog: false
	},

	Data: {
		//-- You must comment out this next line and uncomment the line after it if you're moving the Test application to use Production data.  Otherwise, the next line must be used to determine which webAPI is appropriate for Test vs Prod/Beta.
		dataServerUrl: document.URL.indexOf("localhost") > 0 || document.URL.indexOf("test") > 0 ? "https://api.test.iopspro.com/DataServices/ODataV4/iOPS" : "https://iopspro.com/DataServices/ODataV4/iOPS",
		// //dataServerUrl: 'https://iopspro.com/DataServices/ODataV4', //-- prod only
		 dataServerIsLocal: false,
		// dataServerUrl: "http://localhost:48773",
		// dataServerIsLocal: true,
		footerDataSource: document.URL.indexOf("localhost") > 0 || document.URL.indexOf("test") > 0 ? "Test" : "Prod",
		//-- use the following two lines if you're running the webAPI locally at port 48773:
		reportsServerUrl: "https://reporting.iopspro.com/api/reportserver/v2/reports",
		// thingApiUrl:  "http://localhost:5044",
		// thingApiIsLocal: true
		thingApiUrl:  "DataServices/ThingAPI",
		thingApiIsLocal: false
	},

	Video: {
		//-- Desktop video URLs --
		serverArchiveUrl: "https://video001.iopspro.com/Video/userplayback/archive/",
		serverLiveUrl: "https://video001.iopspro.com/play/",
		serverDownloadUrl: "https://video001.iopspro.com/Concat/",
		serverPlaylistUrl: "https://video001.iopspro.com/Playlist/",
		serverUrl: "https://video001.iopspro.com",
		//-- if camera number is 100 or greater, then get rid of the 001 on the URL.

		//-- Mobile video URLs --
		serverMobileArchiveUrl: "https://video001.iopsmobile.com/Video/userplayback/archive/",
		serverMobileLiveUrl: "https://video001.iopsmobile.com/play/",
		serverMobileDownloadUrl: "https://video001.iopsmobile.com/Concat/",
		serverMobilePlaylistUrl: "https://video001.iopsmobile.com/Playlist/",
		serverMobileUrl: "https://video001.iopsmobile.com",
	},

	tacticalDashboardGraphTypes: [
		{
			id: 0,
			name: "area",
			description: "An area chart or area graph displays graphically quantitative data. It is based on the line chart. The area between axis and line are commonly emphasized with colors, textures and hatchings. Commonly one compares two or more quantities with an area chart.",
			properName: "Area"
		},
		{
			id: 1,
			name: "bar",
			description: "A bar chart or bar graph is a chart or graph that presents categorical data with rectangular bars with heights or lengths proportional to the values that they represent. The bars can be plotted vertically or horizontally. A vertical bar chart is sometimes called a column chart.",
			properName: "Bar"
		},
		{
			id: 2,
			name: "column",
			description: "A bar chart or bar graph is a chart or graph that presents categorical data with rectangular bars with heights or lengths proportional to the values that they represent. The bars can be plotted vertically or horizontally. A vertical bar chart is sometimes called a column chart.",
			properName: "Column"
		},
		{
			id: 3,
			name: "donut",
			description: "Donut charts are used to show the proportions of categorical data, with the size of each piece representing the proportion of each category. A donut chart is created using a string field and a count of features, number, or rate/ratio field.",
			properName: "Donut"
		},
		{
			id: 4,
			name: "horizontalWaterfall",
			description: "A general 'waterfall' is an analytical tool that visually presents the sequential breakdown of a starting value (ex: revenue) to a final result (ex: profit) by displaying intermediate values and 'leakage' points.",
			properName: "Horizontal Waterfall"
		},
		{
			id: 5,
			name: "line",
			description: "A line chart or line plot or line graph or curve chart is a type of chart which displays information as a series of data points called 'markers' connected by straight line segments. It is a basic type of chart common in many fields.",
			properName: "Line"
		},
		{
			id: 6,
			name: "verticalArea",
			description: " 'An area chart or area graph displays graphically quantitative data. It is based on the line chart. The area between axis and line are commonly emphasized with colors, textures and hatchings. Commonly one compares two or more quantities with an area chart.'",
			properName: "Vertical Area"
		},
		{
			id: 7,
			name: "verticalLine",
			description: "A line chart or line plot or line graph or curve chart is a type of chart which displays information as a series of data points called 'markers' connected by straight line segments. It is a basic type of chart common in many fields.",
			properName: "Vertical Line"
		},
		{
			id: 8,
			name: "waterfall",
			description: "A general 'waterfall' is an analytical tool that visually presents the sequential breakdown of a starting value (ex: revenue) to a final result (ex: profit) by displaying intermediate values and 'leakage' points.",
			properName: "Waterfall"
		}
	],
	AdminTableEditorStates: [
		{
			label: "Security",
			role: "SystemAdministrator",
			children: [
				{
					label: "Organizations",
					setName: "Organization",
					role: "SystemAdministrator"
				},
				{
					label: "People",
					setName: "Person",
					role: "SystemAdministrator"
				},
				{
					label: "Requested Accounts",
					setName: "RequestedPerson",
					role: "SystemAdministrator"
				},
				{
					label: "Users",
					setName: "iOPSUsers",
					role: "SystemAdministrator"
				},
				{
					label: "Sites",
					setName: "Sites",
					role: "SystemAdministrator"
				},
				{
					label: "Global Airport Reference",
					setName: "GlobalAirportReference",
					role: "SystemAdministrator"
				}
			]
		},
		{
			label: "IoT",
			role: "SystemAdministrator",
			children: [
				{
					label: "Things",
					setName: "Things",
					role: "SystemAdministrator"
				}
			]
		},
		//-- This next section is not to be grouped since it's specifically for Organization Admins or Site Admins.
		{
			label: "People",
			setName: "PersonBySingleOrganization",
			role: "OrganizationAdministrator,SiteAdministrator",
			privilege: "ManageUsers"
		},
		{
			label: "Users",
			setName: "UsersBySingleOrganization",
			role: "OrganizationAdministrator,SiteAdministrator",
			privilege: "ManageUsers"
		},
		{
			label: "Sites",
			setName: "SitesBySingleOrganization",
			role: "OrganizationAdministrator,SiteAdministrator"
		},
		//-- End section for organization admins and site admins--

		{
			label: "Tags",
			role: "SystemAdministrator",
			children: [
				{
					label: "JTP Data Reader IP Addresses",
					setName: "JTPDataReader",
					role: "SystemAdministrator"
				},
				{
					label: "Program Revisions",
					setName: "PLCPrograms",
					role: "SystemAdministrator"
				},
				{
					label: "PLC Bit Position Composite Types",
					setName: "BitPositionCompositeTypes",
					role: "SystemAdministrator"
				},
				{
					label: "Ninja Stages",
					setName: "NinjaStages",
					role: "SystemAdministrator"
				},
				{
					label: "Tags",
					setName: "Tag",
					role: "SystemAdministrator"
				},
				{
					label: "Tags with no AssetId",
					setName: "TagsWithNoAssetIdDefined",
					role: "SystemAdministrator"
				},
				{
					label: "Tag Simple Name to Standard Name Mapping",
					setName: "TagToJBTSTandardObservationIdMapper",
					role: "SystemAdministrator"
				},
				{
					label: "Rules Engine",
					setName: "Rules",
					role: "SystemAdministrator"
				}
				// {
				// 		label: "Tag Simple Name to JBT Standard Observation Name Mapping",
				// 		role: "SystemAdministrator"
				// },
			]
		},
		// {
		// 	label: "Standard Observation Names",
		// 	setName: "JBTStandardObservation",
		// 	role: "SystemAdministrator"
		// },
		// {
		// 	label: "Standard Observation Types",
		// 	setName: "JBTStandardObservationTypes",
		// },
		// {
		// 	label: "Standard Observation Internal Types",
		// 	setName: "JBTStandardObservationInternalTypes",
		// 	role: "SystemAdministrator"
		// },
		{
			label: "Standard Names",
			role: "SystemAdministrator",
			children: [
				{
					label: "Adveez/GSE",
					setName: "StandardNames_Adveez",
					role: "SystemAdministrator"
				},
				{
					label: "All Standard Names",
					setName: "StandardNames_All",
					role: "SystemAdministrator"
				},
				{
					label: "Gate System",
					setName: "StandardNames_GateSystem",
					role: "SystemAdministrator"
				},
				{
					label: "Names w/No Tag Defined",
					setName: "StandardNames_NoTags",
					role: "SystemAdministrator"
				},
				{
					label: "Oshkosh/Pierce",
					setName: "StandardNames_Oshkosh",
					role: "SystemAdministrator"
				},
				{
					label: "Sabel Odata Standard Names",
					setName: "SabelODataStandardObservation",
					role: "SystemAdministrator"
				},
				{
					label: "GSE Tag Simple Names",
					setName: "GSETagSimpleName",
					role: "SystemAdministrator"
				}
			]
		},
		// {
		// 	label: "Tag Standard Names",
		// 	setName: "TagStandardName",
		// 	role: "SystemAdministrator"
		// },
		// {
		// 	label: "Tag Standard Name Types",
		// 	setName: "TagStandardNameTypes",
		// 	role: "SystemAdministrator"
		// },
		// {
		// 	label: "Tag Standard Name Internal Types",
		// 	setName: "TagStandardNameInternalTypes",
		// 	role: "SystemAdministrator"
		// },
		{
			label: "Widget Types",
			role: "SystemAdministrator",
			children: [
				{
					label: "Widget Type List",
					setName: "WidgetTypes",
					role: "SystemAdministrator"
				},
				{
					label: "Tab Groups",
					setName: "WidgetTypeTabGroup",
					role: "SystemAdministrator"
				},
				{
					label: "Canvas Widget Definitions",
					setName: "CanvasWidgetTypes",
					role: "SystemAdministrator"
				},
				{
					label: "Components",
					setName: "Component",
					role: "SystemAdministrator"
				}
			]
		},
		// {
		// 	label: "Recipes",
		// 	role: "SystemAdministrator",
		// 	children: [
		// 		{
		// 			label: "Recipe Frequency Types",
		// 			setName: "RecipeFrequencyType",
		// 			role: "SystemAdministrator"
		// 		},
		// 		{
		// 			label: "Recipe Evaluation Types",
		// 			setName: "RecipeEvaluationType",
		// 			role: "SystemAdministrator"
		// 		},
		// 		{
		// 			label: "Recipe Watch Types",
		// 			setName: "RecipeWatchType",
		// 			role: "SystemAdministrator"
		// 		},
		// 		{
		// 			label: "Recipes",
		// 			setName: "Recipe",
		// 			role: "SystemAdministrator"
		// 		},
		// 	]
		// },
		{
			label: "Application",
			role: "SystemAdministrator",
			children: [
				{
					label: "Application Versions",
					setName: "ApplicationVersion",
					role: "SystemAdministrator"
				},
				{
					label: "Broadcast Messages",
					setName: "ApplicationBroadcastMessage",
					role: "SystemAdministrator"
				},
				{
					label: "Training Courses",
					setName: "TrainingCourse",
					role: "SystemAdministrator"
				},
				{
					label: "SQL Server Changes",
					setName: "SQLServerChange",
					role: "SystemAdministrator"
				},
				{
					label: "SQL Server Stored Procedure Changes",
					setName: "SQLServerStoredProcedureChange",
					role: "SystemAdministrator"
				},
				{
					label: "User Issue Contacts",
					setName: "UserIssueContact",
					role: "SystemAdministrator"
				}
			]
		},
		{
			label: "Assets",
			role: "SystemAdministrator",
			children: [
				{
					label: "Assets",
					setName: "StandaloneAssets",
					role: "SystemAdministrator"
				},
				{
					label: "Adveez Assets",
					setName: "AdveezAsset",
					role: "SystemAdministrator"
				},
				{
					label: "Asset Make",
					setName: "AssetMake",
					role: "SystemAdministrator"
				},
				{
					label: "Asset Model",
					setName: "AssetModel",
					role: "SystemAdministrator"
				},
				{
					label: "Asset Type",
					setName: "AssetType",
					role: "SystemAdministrator"
				},
				{
					label: "Asset Type Group",
					setName: "AssetTypeGroup",
					role: "SystemAdministrator"
				},
				{
					label: "Airlines",
					setName: "AirlineLookup",
					role: "SystemAdministrator"
				},
				{
					label: "Cameras",
					setName: "Camera",
					role: "SystemAdministrator"
				},
				{
					label: "Systems",
					setName: "System",
					role: "SystemAdministrator"
				}
			]
		},
		{
			label: "Lookup Tables",
			role: "SystemAdministrator",
			children: [
				{
					label: "Camera Models",
					setName: "CameraModel",
					role: "SystemAdministrator"
				},
				{
					label: "Cell Phone Carriers",
					setName: "CellPhoneCarrier",
					role: "SystemAdministrator"
				},
				{
					label: "Time Zone List",
					setName: "TimeZoneList",
					role: "SystemAdministrator"
				},
				{
					label: "Standard Name Types",
					setName: "JBTStandardObservationTypes",
					role: "SystemAdministrator"
				},
				{
					label: "Standard Name Internal Types",
					setName: "JBTStandardObservationInternalTypes",
					role: "SystemAdministrator"
				},
				{
					label: "User Roles",
					setName: "UserRole",
					role: "SystemAdministrator"
				},
				{
					label: "Authorizable User Privileges",
					setName: "Privilege",
					role: "SystemAdministrator"
				},
				{
					label: "Suites",
					setName: "Suite",
					role: "SystemAdministrator"
				},
				{
					label: "Modules",
					setName: "Modules",
					role: "SystemAdministrator"
				}
				// {
				// 	label: "HTML Fragments",
				// 	setName: "HtmlFragment",
				// 	role: "SystemAdministrator"
				// },
				// {
				// 	label: "Email Templates",
				// 	setName: "EmailTemplate",
				// 	role: "SystemAdministrator"
				// },
				// {
				// 	label: "Email Template Assignments",
				// 	setName: "EmailTemplateAssignment",
				// 	role: "SystemAdministrator"
				// },
				// {
				// 	label: "Email Template Substitutions",
				// 	setName: "EmailTemplateSubstitution",
				// 	role: "SystemAdministrator"
				// },
				// {
				// 	label: "System Hierarchy",
				// 	setName: "SystemHierarchy",
				// 	role: "SystemAdministrator"
				// },
			]
		}
	],
	routerEvents$: null,
	GSE: {
		assetQueryUrl: "https://adveez.iopspro.com/DataServices/ODataV4/iOPS/api/AdveezAPIProxy?search=",
		assetPostUrl: "https://adveez.iopspro.com/DataServices/ODataV4/iOPS/api/AdveezAPIProxy",
		assetPutUrl: "https://adveez.iopspro.com/DataServices/ODataV4/iOPS/api/AdveezAPIProxy",
		apiKey: "IcEradjibeentafyeabrovvood3Ot0"
	},
	Test: {
		oDataServerUrl: "https://api.test.iopspro.com/DataServices/ODataV4/iOPS",
		signalRCoreServerUrl: "https://signalr.test.iopspro.com/DataServices/SignalRCore/iOPSHub"
	},
	Prod: {
		oDataServerUrl: "https://iopspro.com/DataServices/ODataV4/iOPS",
		signalRCoreServerUrl: "https://signalr.iopspro.com/DataServices/SignalRCore/iOPSHub"
	}
};
