import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackbarBroadcastMessageComponent } from './snackbar-broadcast-message.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
	declarations: [SnackbarBroadcastMessageComponent],
	imports: [CommonModule, MatSnackBarModule, MatIconModule, MatButtonModule],
	exports: [SnackbarBroadcastMessageComponent],
})
export class SnackbarBroadcastMessageModule {}
