import { EventEmitter } from "events";

export class QuestionBase<T> {
	value: T;
	key: string;
	label: string;
	required: boolean;
	order: number;
	controlType: string;
	type: string;
	selected: string;
	title: string;
	visible: boolean;
	saveOnChange: boolean;
	saveStoredProcedureName: string;
	onChangeFunction: any;
	pattern: string;
	min: number;
	max: number;
	listOfValues: any;
	validateObject: boolean;
	allowBlankValue: boolean;
	setName: string;
	visibleFields: any;
	fieldValidation: any;
	valueField: any;
	buttonList: any;
	buttonSize: string;
	multiSelectedAll: boolean;
	resize: string;
	leftFile: string;
	rightFile: string;
	left: string;
	right: string;
	editable: boolean;
	isDynamicProperty: boolean;
	placeholder: string;
	options: { key: string; value: string }[];

	constructor(
		options: {
			value?: T;
			key?: string;
			label?: string;
			required?: boolean;
			order?: number;
			controlType?: string;
			type?: string;
			selected?: string;
			title?: string;
			visible?: boolean;
			saveOnChange?: boolean;
			saveStoredProcedureName?: string;
			onChangeFunction?: any;
			pattern?: string;
			min?: number;
			max?: number;
			listOfValues?: any;
			validateObject?: boolean;
			allowBlankValue?: boolean;
			setName?: string;
			visibleFields?: any;
			fieldValidation?: any;
			valueField?: any;
			buttonList?: any;
			buttonSize?: string;
			multiSelectedAll?: boolean;
			resize?: string;
			editable?: boolean;
			isDynamicProperty?: boolean;
			placeholder?: string;
		} = {}
	) {
		this.value = options.value;
		this.key = options.key || '';
		this.label = options.label || '';
		this.required = !!options.required;
		this.order = options.order === undefined ? 1 : options.order;
		this.controlType = options.controlType || '';
		this.type = options.type || '';
		this.selected = options.selected || '';
		this.title = options.title || '';
		this.visible = options.visible == null ? true : options.visible;
		this.saveOnChange = !!options.saveOnChange;
		this.saveStoredProcedureName = options.saveStoredProcedureName || '';
		this.onChangeFunction = options.onChangeFunction || null;
		this.pattern = options.pattern || null;
		this.min = options.min || null;
		this.max = options.max || null;
		this.listOfValues = options.listOfValues || null;
		this.validateObject = options.validateObject || false;
		this.allowBlankValue = options.allowBlankValue || false;
		this.setName = options.setName || null;
		this.visibleFields = options.visibleFields || null;
		this.fieldValidation = options.fieldValidation || null;
		this.valueField = options.valueField || null;
		this.buttonList = options.buttonList || null;
		this.buttonSize = options.buttonSize || null;
		this.multiSelectedAll = options.multiSelectedAll || false;
		this.resize = options.resize || null;
		this.editable = options.editable || true;
		this.isDynamicProperty = options.isDynamicProperty || false;
		this.placeholder = options.placeholder || null;
	}
}
