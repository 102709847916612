import { Injectable, NgZone } from '@angular/core';
import { Global } from '../_constants/global.variables';
import { DialogComponentItem } from '../_models/dialog-component-item.model';
// import { GseSummaryComponent } from '../gse-summary/gse-summary.component';
// import { PCASummaryComponent } from '../pca-summary/pca-summary.component';
// import { PBBSummaryComponent } from '../pbb-summary/pbb-summary.component';
// import { GPUSummaryComponent } from '../gpu-summary/gpu-summary.component';
// import { KendoUiGenericPopupComponent } from '../../components/kendo-ui-generic-popup/kendo-ui-generic-popup.component';
// import { Global } from '../../../../../app/_constants/global.variables';
import { Router } from '@angular/router';
// import { TagGraphSingleTagComponent } from '../../components/tag-graph-single-tag/tag-graph-single-tag.component';
import { FileUploadListMobileComponent } from '../../lib/components/file-upload-list-mobile/file-upload-list-mobile.component';
// import { GsTopFiveAlarmsSummaryComponent } from '../gs-top-five-alarms-summary/gs-top-five-alarms-summary.component';
// import { GsTopFiveEquipmentAlarmsSummaryComponent } from '../gs-top-five-equipment-alarms-summary/gs-top-five-equipment-alarms-summary.component';
// import { GseTopFiveAlarmsSummaryComponent } from '../gse-top-five-alarms-summary/gse-top-five-alarms-summary.component';
// import { GseTopFiveEquipmentAlarmsSummaryComponent } from '../gse-top-five-equipment-alarms-summary/gse-top-five-equipment-alarms-summary.component';

@Injectable({
	providedIn: 'root',
})
export class DialogService {
	public components: Array<DialogComponentItem>;

	constructor(private router: Router, private zone: NgZone) {
		this.components = [
			new DialogComponentItem(
				FileUploadListMobileComponent,
				'Documentation',
				'Documentation',
				null
			),
			//   new DialogComponentItem(GseSummaryComponent, 'GSE-Summary', 'GSE Summary', null),
			//   new DialogComponentItem(PBBSummaryComponent, 'PBB-Summary', 'PBB Summary', null),
			//   new DialogComponentItem(PCASummaryComponent, 'PCA-Summary', 'PCA Summary', null),
			//   new DialogComponentItem(GPUSummaryComponent, 'GPU-Summary', 'GPU Summary', null),
			//   new DialogComponentItem(KendoUiGenericPopupComponent, 'Kendo-Grid', 'Kendo Grid', null),
			//   new DialogComponentItem(TagGraphSingleTagComponent, 'TagGraphSingleTag', 'Tag Graph', null),

			//   new DialogComponentItem(GsTopFiveAlarmsSummaryComponent, 'GS-Top-Five-Alarms-Summary', 'GS Top Five Alarms Summary', null),
			//   new DialogComponentItem(GsTopFiveEquipmentAlarmsSummaryComponent, 'GS-Top-Five-Equipment-Alarms-Summary', 'GS Top Five Equipment Alarms Summary', null),
			//   new DialogComponentItem(GseTopFiveAlarmsSummaryComponent, 'GSE-Top-Five-Alarms-Summary', 'GSE Top Five Alarms Summary', null),
			//   new DialogComponentItem(GseTopFiveEquipmentAlarmsSummaryComponent, 'GSE-Top-Five-Equipment-Alarms-Summary', 'GSE Top Five Equipment Alarms Summary', null),
		];
	}

	getComponentByName(name: string) {
		if (name) {
			var item = this.components.filter(
				(component: DialogComponentItem) => {
					return component.name == name;
				}
			);
			if (item) {
				return item[0];
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	openDialogWindow(componentDataObject: any) {
		Global.Dialog.lastRouteURL = this.router.url;
		Global.User.DebugMode &&
			console.log(
				'Global.Dialog.lastRouteURL = ' + Global.Dialog.lastRouteURL
			);

		Global.User.DebugMode &&
			console.log('componentDataObject = %O', componentDataObject);

		Global.Dialog.currentDialogObject = componentDataObject;
		Global.Dialog.componentName = componentDataObject.WidgetName;

		//const myDialogComponentItem: DialogComponentItem = new DialogComponentItem(GseSummaryComponent, componentDataObject);
		Global.User.DebugMode &&
			console.log(
				'going to layout/dialog with componentDataObject... Global.Dialog: %O',
				Global.Dialog
			);

		this.zone.run(() =>
			this.router.navigate([
				'/layout/dialog',
				{ dataObject: componentDataObject },
			])
		);
	}
}
