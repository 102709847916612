import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatTime' })
export class FormatTimePipe implements PipeTransform {

    //-- Even though this incoming value is listed as 'hoursMinutes', the value could be minutes and seconds.  I renamed this pipe to be 'formatTime' so that it could be used in either case. --Kirk T. Sherer, February 2, 2021. 
    //-- This pipe will simply add a leading zero to the hours and minutes separately and then take the right-most two characters for each before combining the values back together with a colon. 
    
    transform(hoursAndMinutes: string = ""): string {
        if (hoursAndMinutes.indexOf(":") == 0) {
            return hoursAndMinutes;
        } else {
            var hoursAndMinutesArray = hoursAndMinutes.split(":");
            var hours = "0" + hoursAndMinutesArray[0]; //-- adding leading zero regardless of whether we have a single digit for hours or not.
            var minutes = "0" + hoursAndMinutesArray[1]; //-- adding leading zero regardless of whether we have a single digit for minutes or not.
            var hoursWithLeadingZero = hours.substr(hours.length - 2); //-- taking the two characters starting from the right side of the hours variable. If there are two numbers for hours, it will ignore the leading zero that was added. 
            var minutesWithLeadingZero = minutes.substr(minutes.length - 2); //-- taking the two characters starting from the right side of the minutes variable. If there are two numbers for minutes, it will ignore the leading zero that was added. 
            return `${hoursWithLeadingZero}:${minutesWithLeadingZero}`;
        }
    }
}