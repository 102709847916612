import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { tap, catchError } from "rxjs/operators";

import { IUser } from "../_models/user.model";
import { HttpHeaders } from "@angular/common/http";
import { UtilityService } from "projects/shared-lib/src/lib/services/utility.service";
import { SignalRCoreService } from "projects/shared-lib/src/lib/services/signalr-core.service";
import { Global } from "../_constants/global.variables";
import { DataService } from "projects/shared-lib/src/lib/services/data.service";
import { Router } from "@angular/router";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
	private currentUserSubject: BehaviorSubject<IUser>;
	public currentUser: Observable<IUser>;
	public handleError: any;
	public routerEvents$: any;
	public serviceName: string = "authentication-service: ";

	constructor(private http: HttpClient, private utilityService: UtilityService, private signalRCore: SignalRCoreService, private dataService: DataService, private router: Router) {
		if (!Global.User?.isLoggedIn) {
			this.dataService.setThemeBackToDefaultSettings();
		}

		console.log("webAPI URL: " + Global.Data.dataServerUrl);
		console.log("SignalR.CoreUrl: " + Global.SignalR.CoreUrl);

		this.currentUserSubject = new BehaviorSubject<IUser>(JSON.parse(localStorage.getItem("currentUser")));

		// let localhostindex = document.URL.indexOf('localhost');
		// let testmobilesplit = document.URL.split('.iopsmobile.com')[0];
		// let testsplit = document.URL.split('.iopspro.com')[0];
		// console.log(this.serviceName + 'document.URL = ' + document.URL);
		// console.log(this.serviceName + 'localhostindex', localhostindex);

		// console.log(this.serviceName + 'testmobilesplit', testmobilesplit);
		// console.log(this.serviceName + 'testsplit', testsplit);

		//let testvar = document.URL.indexOf('localhost') > 0 || document.URL.indexOf('test') > 0 ? 'https://test.iopspro.com/DataServices/ODataV4' : 'https://iopspro.com/DataServices/ODataV4';
		//console.log(this.serviceName + 'evaluationvariable', testvar);
		this.currentUserSubject = new BehaviorSubject<IUser>(JSON.parse(localStorage.getItem("currentUser")));
		this.currentUser = this.currentUserSubject.asObservable();
	}

	public get currentUserValue(): IUser {
		return this.currentUserSubject.value;
	}

	validatePasswordToken(passwordToken: string) {
		return this.validatePasswordTokenHttpPost(passwordToken);
	}

	loginWithUsernameAndPassword(username: string, password: string) {
		this.dataService.ApplicationLoadingMessage("Logging in User", true);
		this.signalRCore.LogActivity("Logging in as " + username + "...", username);
		var dataString = username + "\n" + password;
		return this.httpPost(dataString, "username/password");
	}

	resetApplicationToDefaultSettings() {
		// remove user from local storage and set current user to null
		localStorage.removeItem("currentUser");
		Global.User.DebugMode = false;
		Global.User.Menu = null;
		Global.User.PermittedSites = null;
		Global.User.Privilege = null;
		Global.User.Role = null;
		Global.User.currentUser = null;
		Global.User.isAdmin = false;
		Global.User.isOrgAdmin = false;
		Global.User.isSiteAdmin = false;
		Global.User.isLoggedIn = false;
		Global.User.needToBuildMenu = true;
		Global.SignalR.ListOfTagNamePrefixes = null;
		Global.SignalR.ListOfAdditionalSignalRGroups = null;
		this.currentUserSubject.next(null);
		if (this.signalRCore) {
			this.signalRCore.stopHub();
		}

		this.dataService.setThemeBackToDefaultSettings();
	}

	logout() {
		Global.User.DebugMode && console.log(this.serviceName + "LOGGING OUT...");
		this.signalRCore.LogActivity("Logging out...");
		Global.User.isLoggedInAsDifferentUser = false;
		if (Global.User.currentUser != null) {
			var sqlStatement = "Security.User_Logout_ByUsername @Username='" + Global.User.currentUser.Username + "', @isMobile=" + (Global.isMobile ? "1" : "0");
			this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
				this.signalRCore.LogActivity(data);
				this.resetApplicationToDefaultSettings();
				this.router.navigate(["login"]);
			});
		} else {
			this.resetApplicationToDefaultSettings();
			this.router.navigate(["login"]);
		}
	}

	loginUserWithAccessToken = function (accessToken) {
		//See if there is a password change token attached to the url.

		Global.User.DebugMode && console.log(this.serviceName + "loginUserWithAccessToken: accessToken = " + accessToken);
		var dataString = this.utilityService.GetQuerystringParameterByName("pwt");
		if (dataString) {
			localStorage.removeItem("currentUser");
			this.currentUserSubject.next(null);
		} else {
			//No password change token - get the last ODataAccessToken from the data store
			dataString = accessToken;
			this.signalRCore.LogActivity("Logging in with access token...");
			return this.httpPost(dataString, "accessToken");
		}
	};

	httpPost = function (dataString: string, type: string) {
		let httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
				Authorization: "my-auth-token"
			})
		};

		Global.User.DebugMode && console.log(this.serviceName + "dataServerIsLocal = " + Global.Data.dataServerIsLocal);
		Global.User.DebugMode && console.log(this.serviceName + "this.apiUrl = " + this.apiUrl);
		var time0 = performance.now();

		// this.http.post(this.loginUrl, dataString, httpOptions).toPromise().then()
		let promise = new Promise((resolve, reject) => {
			// let apiURL = `${this.apiRoot}?term=${term}&media=music&limit=20`;
			this.http
				.post(this.dataService.loginUrl, dataString, httpOptions)
				.toPromise()
				.then(
					(accessTokenSentBackFromWebAPI) => {
						// Success
						var time = performance.now() - time0;
						console.log(this.serviceName + "time for webAPI to authenticate user with " + type + ": " + time + " milliseconds.");
						console.log(this.serviceName + "Logged in user access token sent back from webAPI: " + accessTokenSentBackFromWebAPI);
						if (accessTokenSentBackFromWebAPI) {
							Global.User.isLoggedIn = true;
							Global.User.DebugMode && console.log(this.serviceName + "current user is logged in...");
						}
						resolve(accessTokenSentBackFromWebAPI);
					},
					(msg) => {
						// Error
						reject(msg);
					}
				);
		});
		return promise;
	};

	validatePasswordTokenHttpPost = function (passwordChangeToken: string) {
		let httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
				Authorization: "my-auth-token"
			})
		};

		Global.User.DebugMode && console.log(this.serviceName + "dataServerIsLocal = " + Global.Data.dataServerIsLocal);
		Global.User.DebugMode && console.log(this.serviceName + "this.passwordTokenValidationUrl = " + this.passwordTokenValidationUrl);
		var time0 = performance.now();
		const http$ = this.http.post(this.passwordTokenValidationUrl, passwordChangeToken, httpOptions).pipe(
			tap((valid: any) => {
				var time = performance.now() - time0;
				console.log(this.serviceName + "time for webAPI to password token = " + time + " milliseconds.");
				console.log(this.serviceName + "Is password token valid? " + valid);
				return valid;
			}),
			catchError((err) => of([]))
		);
		return http$;
	};
}
