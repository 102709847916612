import { Routes } from '@angular/router';
import { GeolocationComponent } from '../native/geolocation/geolocation.component';
import { CameraComponent } from '../native/camera/camera.component';
import { VibrationComponent } from '../native/vibration/vibration.component';
import { BarcodeScannerComponent } from '../native/barcode-scanner/barcode-scanner.component';
import { DeviceMotionComponent } from '../native/device-motion/device-motion.component';
import { AuthGuard } from '../user/authentication/authorization.guard';


export const detailedRoutes: Routes = [
	{
		path: 'folder/Vibration',
		canActivate: [AuthGuard],
		component: VibrationComponent,
	},
	{
		path: 'folder/Geolocation',
		canActivate: [AuthGuard],
		component: GeolocationComponent,
	},
	{
		path: 'folder/Camera',
		canActivate: [AuthGuard],
		component: CameraComponent,
	},
	{
		path: 'folder/BarcodeScanner',
		canActivate: [AuthGuard],
		component: BarcodeScannerComponent,
	},
	{
		path: 'folder/DeviceMotion',
		canActivate: [AuthGuard],
		component: DeviceMotionComponent,
	},

	{
		path: 'folder/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('../folder/folder.module').then((m) => m.FolderPageModule),
	},
	{
		path: 'authentication',
		loadChildren: () =>
			import('../user/authentication/authentication.module').then(
				(m) => m.AuthenticationPageModule
			),
	},
	{
		path: 'login',
		loadChildren: () =>
			import('../user/login/login.module').then((m) => m.LoginPageModule),
	},
	{
		path: 'forgot-password/:pwt',
		loadChildren: () =>
			import('../user/forgot-password/forgot-password.module').then(
				(m) => m.ForgotPasswordPageModule
			),
	},
	{
		path: 'layout',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('../_layout/layout.module').then((m) => m.LayoutPageModule),
	},

	{
		path: 'logout',
		loadChildren: () =>
			import('../user/logout/logout.module').then(
				(m) => m.LogoutPageModule
			),
	},
	{
		path: 'home',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('../home/home.module').then((m) => m.HomePageModule),
	},
	{
		path: 'registration',
		loadChildren: () =>
			import('../user/registration/registration.module').then(
				(m) => m.RegistrationPageModule
			),
	},
	{
		path: '',
		redirectTo: 'home',
		pathMatch: 'full',
	},
];
