import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'
@Pipe({
  name: 'sanitizedUrl'
})
export class SanitizedUrlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(val: string): any {
	return this.sanitized.bypassSecurityTrustResourceUrl(val);
  }
}