import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import _ from "lodash";

import { map } from "jquery";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";

import { ToastrService } from "ngx-toastr";
import { Global } from "../../_constants/global.variables";
import { UtilityService } from "../../services/utility.service";
import { DataService } from "../../services/data.service";
import { FileUploadService } from "../../services/file-upload.service";
import { IGlobal } from "../../_models/global.model";

@Component({
	selector: "lib-release-notes",
	templateUrl: "./release-notes.component.html",
	styleUrls: ["./release-notes.component.scss"],
})
export class ReleaseNotesComponent implements OnInit {
	public distinctVersions: any;
	public releaseNotes: any;
	public iconmenu: string =
		"doc,docx,xls,xlsm,xlsx,msg,pdf,ppt,pptx,js,css,mp4,mp3,wav,zip,txt,json,config,cs,aspx,html,tif,tiff,vsd,chm,asp,sql,hl7,xml,linq,rdl,wma,msi,exe,reg,csv,webm";

	private videoKeyURL: string = Global.Video.serverArchiveUrl;
	private accessToken: string = Global.User.currentUser.ODataAccessToken;
	public source: string;
	public global: IGlobal = Global;

	constructor(
		private fileUploadService: FileUploadService,
		private sanitizer: DomSanitizer,
		private utilityService: UtilityService,
		private dataService: DataService,
		private toastr: ToastrService,
		public dialogRef: MatDialogRef<ReleaseNotesComponent>
	) {}

	ngOnInit() {
		this.utilityService.updateCurrentMenuItem("Release Notes");

		this.dataService
			.SQLActionAsPromise(
				"API.RDN_GetApplicationVersionReleaseNotes @EnvironmentId=" +
					Global.Application.Environment.Id +
					", @IsMobile=" +
					(Global.isMobile ? "1" : "0")
			)
			.then((dataObject: any) => {
				console.log("Release Notes = %O", dataObject); //-- this is where you build a dialog box to show the release notes, if they exist for this version. --Kirk T. Sherer, August 4, 2021.
				this.releaseNotes = dataObject;
				if (!Global.User.currentUser.IsSystemAdministrator) {
					this.releaseNotes = dataObject
						.where((data: any) => {
							return data.Internal == 0 || data.Internal == false;
						})
						.toArray();
				}
				this.source = this.releaseNotes.first((data: any) => {
					return data;
				}).Source;
				console.log("release-notes: source = " + this.source);

				var notes = this.releaseNotes
					.select((version: any) => {
						return {
							id: version.ApplicationVersionId,
							name: version.ApplicationVersion,
							description: version.ApplicationVersionDescription,
							environment:
								version.ApplicationVersionLatestEnvironment,
							goLiveDate: version.ApplicationVersionGoLiveDate,
							releaseNoteFeatures: this.releaseNotes
								.where((notes: any) => {
									return (
										notes.ApplicationVersionId ==
											version.ApplicationVersionId &&
										notes.ApplicationVersionReleaseNoteType ==
											"Feature"
									);
								})
								.select((note: any) => {
									return {
										id: note.Id,
										name: note.Name.replace(":", ""),
										description: note.Description,
										internal: note.Internal,
										pictureImageKey: note.PictureImageKey,
										releaseNoteType:
											note.ApplicationVersionReleaseNoteType,
										uploadedPicture:
											note.PictureImageKey != null
												? this.buildPictureUrl(note)
												: null,
									};
								})
								.toArray(),

							releaseNoteBugs: this.releaseNotes
								.where((notes: any) => {
									return (
										notes.ApplicationVersionId ==
											version.ApplicationVersionId &&
										notes.ApplicationVersionReleaseNoteType ==
											"Bug"
									);
								})
								.select((note: any) => {
									return {
										id: note.Id,
										name: note.Name.replace(":", ""),
										description: note.Description,
										internal: note.Internal,
										pictureImageKey: note.PictureImageKey,
										releaseNoteType:
											note.ApplicationVersionReleaseNoteType,
										uploadedPicture:
											note.PictureImageKey != null
												? this.buildPictureUrl(note)
												: null,
									};
								})
								.toArray(),
							releaseNoteNotes: this.releaseNotes
								.where((notes: any) => {
									return (
										notes.ApplicationVersionId ==
											version.ApplicationVersionId &&
										_.isNil(
											notes.ApplicationVersionReleaseNoteType
										)
									);
								})
								.select((note: any) => {
									return {
										id: note.Id,
										name: note.Name.replace(":", ""),
										description: note.Description,
										internal: note.Internal,
										pictureImageKey: note.PictureImageKey,
										releaseNoteType:
											note.ApplicationVersionReleaseNoteType,
										uploadedPicture:
											note.PictureImageKey != null
												? this.buildPictureUrl(note)
												: null,
									};
								})
								.toArray(),
						};
					})
					.toArray();

				//-- since the Linq-to-Typescript's 'distinct' function doesn't actually allow you to get a distinct list based on a value, I had to build this
				//-- next forEach loop so I can get the distinct list of versions after building the objects the way I need to display them in the view. --Kirk T. Sherer, October 29, 2021.

				var distinctVersions: Array<any> = [];
				var currentVersion: string = "";
				notes.forEach((note: any) => {
					if (currentVersion != note.name) {
						currentVersion = note.name;
						distinctVersions.push(note);
					}
				});

				this.distinctVersions = distinctVersions;
				Global.User.DebugMode &&
					console.log(
						"release-notes: this.distinctVersions = %O",
						this.distinctVersions
					);

				// const dialogRef = this.dialog.open(ReleaseNotesComponent, {
				// 	width: '60%',
				// 	height: '80%', //,
				// 	data: releaseNotes,
				// });
				// dialogRef.afterClosed().subscribe((result) => {
				// 	Global.User.DebugMode &&
				// 		console.log('The dialog was closed');
				// });
			});
	}

	closeDialog(): void {
		// this.dialogRef.close();
	}

	closeAndStopSeeingReleaseNotesAtLogin(
		dontShowAtLoginPermanently?: number
	): void {
		// StopSeeingReleaseNotes
		var sql =
			"API.RDN_ApplicationReleaseNotes_StopSeeingAtLogin @UserId=" +
			Global.User.currentUser.Id;
		if (dontShowAtLoginPermanently) {
			sql += ", @StopSeeingReleaseNotes=" + dontShowAtLoginPermanently;
		}
		this.dataService.SQLActionAsPromise(sql).then((dataObject: any) => {
			if (dataObject) {
				let message = "Release Notes will no longer be displayed";
				if (!dontShowAtLoginPermanently) {
					message += " at login";
				}
				this.utilityService.showToastMessageShared({
					type: "info",
					message: message,
					title: "Release Notes",
				});
			}
			// this.dialogRef.close();
		});
	}

	public viewPicture(file: any) {
		Global.User.DebugMode &&
			console.log("trying to view the file... file = %O", file);
		var url = file.viewUrl;

		var intHSize = window.screen.availWidth * 0.7;
		var intVSize = window.screen.availHeight * 0.7;
		var intTop = (window.screen.availHeight - intVSize) / 2;
		var intLeft = (window.screen.availWidth - intHSize) / 2;
		var sngWindow_Size_Percentage = 0.85;

		var strFeatures = "";
		intHSize = window.screen.availWidth * sngWindow_Size_Percentage * 0.6;
		intVSize = window.screen.availHeight * sngWindow_Size_Percentage;
		intTop = (window.screen.availHeight - intVSize) / 2;
		intLeft = (window.screen.availWidth - intHSize) / 2;
		strFeatures =
			"top=" +
			intTop +
			",left=" +
			intLeft +
			",height=" +
			intVSize +
			",width=" +
			intHSize +
			", scrollbars=yes, resizable=yes, location=no";

		var SRWindow = window.open(url, "SRWindow", strFeatures);
	}

	private buildPictureUrl(note: any): any {
		if (note.PictureImageKey != "" && note.PictureImageKey != undefined) {
			Global.User.DebugMode &&
				console.log("note.PictureImageKey = " + note.PictureImageKey);
			var file: any = {};
			file.imageKey = note.PictureImageKey;
			file.isVideoServerImage =
				note.IsVideoServerImage == null
					? false
					: note.IsVideoServerImage == "False"
					? false
					: true;
			file.videoServerFilename = note.VideoServerFilename
				? note.VideoServerFilename
				: file.isVideoServerImage
				? note.DefaultVideoFileName
				: null;
			file.thumbnailImage = note.ThumbnailBase64
				? this.sanitizer.bypassSecurityTrustResourceUrl(
						"data:image/png;base64," + note.ThumbnailBase64
				  )
				: null;
			file.name = note.Filename;
			file = this.setFileImage(file);
			Global.User.DebugMode &&
				console.log("release-notes: file = %O", file);
			return file;
		} else {
			return null;
		}
	}

	private setFileImage(file: any) {
		Global.User.DebugMode && console.log("setFileImage: file = %O", file);
		var includeThumbnail = false;
		var filename = file.name;

		if (filename == "Pasted Image") {
			includeThumbnail = true;
		} else if (filename.indexOf(".") == -1) {
			//-- user didn't name the file with a file extension.  Add the appropriate file extension so we can get the correct thumbnail picture, if applicable. --Kirk T. Sherer, February 5, 2021.
			if (file.IsVideoServerImage) {
				filename = file.videoServerFilename;
			} else {
				filename = filename + ".png";
			}
		}

		var fileExtension = filename.split(".").pop().toLowerCase();
		// Global.User.DebugMode && console.log("fileExtension = " + fileExtension);
		if (
			fileExtension == "jpg" ||
			fileExtension == "png" ||
			fileExtension == "jpeg" ||
			fileExtension == "gif" ||
			fileExtension == "bmp"
		) {
			includeThumbnail = true;
		}
		// Global.User.DebugMode && console.log("this.iconmenu = " + this.iconmenu);
		// Global.User.DebugMode && console.log("this.iconmenu.indexOf(fileExtension.toLowerCase()) = " + this.iconmenu.indexOf(fileExtension.toLowerCase()));

		//Assign an icon for the file
		if (this.iconmenu.indexOf(fileExtension.toLowerCase()) > -1) {
			//This is an icon file display. Assign the right one.
			file.iconFile =
				Global.imagesUrl +
				"assets/img/FileUpload/icon-" +
				fileExtension +
				".png";
		} else {
			file.iconFile = null;
		}

		// Global.User.DebugMode && console.log("file.iconFile = " + file.iconFile);

		if (file.imageKey != null) {
			if (file.isVideoServerImage) {
				var url =
					this.videoKeyURL +
					file.videoServerFilename +
					"?accessToken=" +
					encodeURIComponent(this.accessToken);
				file.thumbnailUrl = null;
				file.viewUrl = url;
			} else {
				file.thumbnailUrl =
					this.dataService.imageKeyURL + file.imageKey;
				file.viewUrl = file.thumbnailUrl;
			}
		}

		// Global.User.DebugMode && console.log("file.thumbnailUrl = " + file.thumbnailUrl);

		return file;
	}
}
