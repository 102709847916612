import { Injectable } from "@angular/core";
import * as moment from "moment";

import { ToastrService } from 'ngx-toastr';
import { AlertController, ToastController } from '@ionic/angular';
import { BehaviorSubject } from "rxjs";

import swal from "sweetalert2";
import { Global } from '../_constants/global.variables';
import { numberFormat } from "highcharts";

export interface applicationSharedNotification {
	type: string;
	title?: string;
	message: string;
	override?: any;
}
@Injectable({
	providedIn: 'root',
})
export class UtilityService {
	public timeZoneOffsetHoursFromUTC = new Date().getTimezoneOffset() / 60;
	public homeRoute$: BehaviorSubject<string> = new BehaviorSubject<string>(
		'layout/dashboard-demo'
	);
	public titleMenuChange$: BehaviorSubject<string> =
		new BehaviorSubject<string>(Global.CurrentMenuItem);

	constructor(
		private toastr: ToastrService,
		private mobileToastr: ToastController,
		private mobileAlertify: AlertController
	) {}

	updateCurrentMenuItem(title: string) {
		Global.User.DebugMode &&
			console.log('updateCurrentMenuItem invoked. title = ' + title);
		Global.CurrentMenuItem = title;
		this.titleMenuChange$.next(title);
	}

	GetQuerystringParameterByName(name: string) {
		name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
		const regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
			results = regex.exec(location.search);
		return results === null
			? ''
			: decodeURIComponent(results[1].replace(/\+/g, ' '));
	}

	ReplaceItemInArrayByIdOnlyIfPresent(arr: any, item: any) {
		let replaced = false;
		for (let i = 0; i < arr.length; i++) {
			if (arr[i].Id == item.Id) {
				arr[i] = item;
				replaced = true;
				break;
			}
		}
	}

	updateStringToSQLSyntax(originalString: string) {
		var newString = '';
		for (let counter = 0; counter < originalString.length; counter++) {
			var char = originalString[counter];

			switch (char) {
				case '\n':
					if (originalString[counter + 1] === '\n') {
						newString += '</p>\n<p>';
						counter++;
					} else newString += '<br />';
					break;

				case ' ':
					if (
						originalString[counter - 1] != ' ' &&
						originalString[counter - 1] != '\t'
					) {
						newString += ' ';
					}
					break;

				case "'":
					newString += "''";
					break;

				case '\t':
					if (originalString[counter - 1] != '\t') {
						newString += ' ';
					}
					break;

				case '&':
					newString += '&amp;';
					break;

				case '"':
					newString += '&quot;';
					break;

				case '>':
					newString += '&gt;';
					break;

				case '<':
					newString += '&lt;';
					break;

				default:
					newString += char;
			}
		}
		return newString;
	}

	ToFixed(number: number, fractionSize: number) {
		if (+number == 0) {
			return number;
		}
		var fixed = +(
			Math.round(+(number.toString() + 'e' + fractionSize)).toString() +
			'e' +
			-fractionSize
		);
		let fixedNumberAsString = fixed.toString();
		// console.log(fixedNumberAsString);
		if (fixedNumberAsString.indexOf('.') < 0) {
			fixedNumberAsString += '.0';
		}
		return fixedNumberAsString;
	}

	SecondsToString(seconds: any) {
		if (!seconds) {
			return '';
		}
		if (seconds == 'NULL') {
			return '';
		}
		if (seconds == 'null') {
			return '';
		}
		if (+seconds != seconds) {
			return '';
		}

		var secondsAsNumber = +seconds;
		let numdays = Math.floor(secondsAsNumber / 86400);
		var numDaysAsString = numdays.toString();
		numdays = parseInt(numDaysAsString);
		var numhours = Math.floor((secondsAsNumber % 86400) / 3600);
		var numminutes = Math.floor(((secondsAsNumber % 86400) % 3600) / 60);
		let numseconds = ((secondsAsNumber % 86400) % 3600) % 60;
		var numSecondsAsString = numseconds.toString();
		numseconds = parseInt(numSecondsAsString);

		// return numdays.toString();
		return (
			(numdays > 0 ? numdays + (numdays == 1 ? ' day ' : ' days ') : '') +
			(numhours > 9 ? numhours : '0' + numhours) +
			':' +
			(numminutes > 9 ? numminutes : '0' + numminutes) +
			':' +
			(numseconds > 9 ? numseconds : '0' + numseconds)
		);
	}

	translateMomentDateToJavascriptDate(momentDate: any) {
		var dateValue = momentDate._d;

		// var returnDate = new Date(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate(), dateValue.getHours(), dateValue.getMinutes(), dateValue.getSeconds(), dateValue.getMilliseconds());
		return dateValue;
	}

	FormatNumberWithCommas(x: any) {
		var parts = x.toString().split('.');
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		return parts.join('.');
	}

	ConvertDateTimeToUTCDateTimeAndFormatForSQLParameter(inputDate: any) {
		var newDate = moment(inputDate).add(this.timeZoneOffsetHoursFromUTC, 'hours').format("MM/DD/YYYY HH:mm:ss");
		return newDate;
	}

	GetUTCQueryDate(inputDate: any) {
		// var newDate = moment(inputDate).add(this.timeZoneOffsetHoursFromUTC, 'hours');
		// return translateMomentDateToJavascriptDate(newDate);
		var newDate = moment(inputDate);
		return this.translateMomentDateToJavascriptDate(newDate);
	}

	GetUTCQueryDateMinutesAgo(inputDate: any) {
		// var newDate = moment(inputDate).add(this.timeZoneOffsetHoursFromUTC, 'hours');
		// return translateMomentDateToJavascriptDate(newDate);
		var newDate = moment(inputDate);
		return this.translateMomentDateToJavascriptDate(newDate);
	}

	GetODataQueryDateFromUTCDate(inputDate: any) {
		var newDate = moment(inputDate).subtract(
			this.timeZoneOffsetHoursFromUTC,
			'hours'
		);
		return this.translateMomentDateToJavascriptDate(newDate);
	}

	GetODataQueryDateFromInputDate(inputDate: any) {
		var newDate = moment(inputDate);
		return this.translateMomentDateToJavascriptDate(newDate);
	}

	DateTimeInMilliseconds(dt: any) {
		// returns date/time in milliseconds
		var inputDate = new Date(dt);
		return inputDate.getTime();
	}

	JavascriptDateTimeInMilliseconds(dateValue: any) {
		var jsDate = new Date(dateValue);
		var utcDate = new Date(
			jsDate.getUTCFullYear(),
			jsDate.getUTCMonth(),
			jsDate.getUTCDate(),
			jsDate.getUTCHours(),
			jsDate.getUTCMinutes(),
			jsDate.getUTCSeconds(),
			jsDate.getUTCMilliseconds()
		);
		return utcDate.getTime(); //-- returns date/time in milliseconds.
	}

	GetNonUTCQueryDate(inputDate: any) {
		var newDate = moment(inputDate);
		return this.translateMomentDateToJavascriptDate(newDate);
	}

	GetQueryDateForNow() {
		var newDate = moment().add(this.timeZoneOffsetHoursFromUTC, 'hours');
		return this.translateMomentDateToJavascriptDate(newDate);
	}

	GetQueryDateSecondsAgo(secondsToSubtract: number) {
		var newDate = moment()
			.add(this.timeZoneOffsetHoursFromUTC, 'hours')
			.subtract(secondsToSubtract, 'seconds');
		return this.translateMomentDateToJavascriptDate(newDate);
	}

	GetQueryDateMinutesAgo(minutesToSubtract: number) {
		var newDate = moment()
			.add(this.timeZoneOffsetHoursFromUTC, 'hours')
			.subtract(minutesToSubtract, 'minutes');
		return this.translateMomentDateToJavascriptDate(newDate);
	}

	GetQueryDateHoursAgo(hoursToSubtract: number) {
		var newDate = moment()
			.add(this.timeZoneOffsetHoursFromUTC, 'hours')
			.subtract(hoursToSubtract, 'hours');
		return this.translateMomentDateToJavascriptDate(newDate);
	}

	GetQueryDateDaysAgo(daysToSubtract: number) {
		var newDate = moment()
			.add(this.timeZoneOffsetHoursFromUTC, 'hours')
			.subtract(daysToSubtract, 'days');
		return this.translateMomentDateToJavascriptDate(newDate);
	}

	GetOdataQueryDateForMidnightLocalTime() {
		var today = new Date();
		var myToday = new Date(
			today.getFullYear(),
			today.getMonth(),
			today.getDate(),
			0,
			0,
			0
		);
		var localUTCMidnight = moment(myToday).add(
			this.timeZoneOffsetHoursFromUTC * 2,
			'hours'
		);
		console.log(
			'LocalUTCMidnight = ' +
				this.translateMomentDateToJavascriptDate(localUTCMidnight)
		);
		return this.translateMomentDateToJavascriptDate(localUTCMidnight);
	}

	GetGateNameSortValue(gateName: any) {
		if (gateName) {
			if (typeof gateName.substring == 'function') {
				// if (!isFinite(gateName.substring(0, 1)) && isFinite(gateName.substring(1, 50))) {
				//    console.log("gateName = " + gateName + ", !isFinite(gateName.substring(0, 1)) = " + !isFinite(gateName.substring(0, 1)) + ", isFinite(gateName.substring(1, 50)) = " + isFinite(gateName.substring(1, 50)) + ", gateName.substring(0,1) = " + gateName.substring(0, 1) + ", gateName.substring(1, 50) = " + gateName.substring(1, 50));
				// }
				// if (!isFinite(gateName.substring(0, 1)) && !isFinite(gateName.substring(2, 3))) {
				//    console.log("gateName = " + gateName + ", !isFinite(gateName.substring(0, 1)) = " + !isFinite(gateName.substring(0, 1)) + ", !isFinite(gateName.substring(2, 3)) = " + !isFinite(gateName.substring(2, 3)) + ", gateName.substring(0,1) = " + gateName.substring(0, 1) + ", gateName.substring(2, 3) = " + gateName.substring(2, 3));
				// }
				// if (!isFinite(gateName.substring(0, 1)) && !isFinite(gateName.substring(3, 4))) {
				//    console.log("gateName = " + gateName + ", !isFinite(gateName.substring(0, 1)) = " + !isFinite(gateName.substring(0, 1)) + ", !isFinite(gateName.substring(3, 4)) = " + !isFinite(gateName.substring(3, 4)) + ", gateName.substring(0,1) = " + gateName.substring(0, 1) + ", gateName.substring(3, 4) = " + gateName.substring(3, 4));
				// }
				return !isFinite(gateName.substring(0, 1)) &&
					isFinite(gateName.substring(1, 50))
					? gateName.substring(0, 1) +
							gateName.substring(1, 50).padStart(4, '0')
					: !isFinite(gateName.substring(0, 1)) &&
					  !isFinite(gateName.substring(2, 3)) &&
					  isFinite(gateName.substring(3, 50))
					? gateName.substring(0, 1) +
					  gateName.substring(1, 2).padStart(4, '0') +
					  gateName.substring(2, 3) +
					  gateName.substring(3, 50).padStart(4, '0') // --IAH
					: !isFinite(gateName.substring(0, 1)) &&
					  !isFinite(gateName.substring(3, 4)) &&
					  isFinite(gateName.substring(4, 50))
					? gateName.substring(0, 1) +
					  gateName.substring(1, 3).padStart(4, '0') +
					  gateName.substring(3, 4) +
					  gateName.substring(4, 50).padStart(4, '0') // --JFK
					: isFinite(gateName.substring(0, 2)) &&
					  !isFinite(gateName.substring(2, 3)) &&
					  isFinite(gateName.substring(3, 50))
					? gateName.substring(0, 2).padStart(4, '0') +
					  gateName.substring(2, 3) +
					  gateName.substring(4, 50).padStart(4, '0') // --LAX
					: gateName.padStart(4, '0');
			} else {
				if (!isNaN(gateName) == true) {
					return +gateName;
				} else {
					return '';
				}
			}
		} else {
			return '';
		}
	}

	GetOdataUpdateableCurrentDateTime() {
		var utc = new Date().toISOString();
		console.log('utc = %O', utc);
		return utc;
	}

	GetOdataUpdateableCurrentDateTimeInUTCMilliseconds() {
		var utc = new Date().toISOString();
		var currentDateTimeInMilliseconds = this.DateTimeInMilliseconds(utc);
		return currentDateTimeInMilliseconds;
	}

	FormatDateToISOString(datestring: string) {
		var utc = new Date(datestring).toISOString();
		console.log('utc = %O', utc);
		return utc;
	}

	Camelize(str: string) {
		if (str) {
			return str.replace(
				/(?:^\w|[A-Z]|\b\w|\s+)/g,
				function (match, index) {
					if (+match === 0) {
						return '';
					} // or if (/\s+/.test(match)) for white spaces
					return index == 0
						? match.toLowerCase()
						: match.toUpperCase();
				}
			);
		}
	}

	ToPascalCase(str: string) {
		return str.replace(
			/\w\S*/g,
			(m) => m.charAt(0).toUpperCase() + m.substr(1).toLowerCase()
		);
	}

	GetJsonFromSignalR(signalRObject: any): any {
		var returnObject = {};
		var selectObject = {};
		// Accumulate the properties of the output object
		// Remove the leading Conn=xxx,
		var signalRString = signalRObject; 
		if (signalRObject.substring(0,4) == "Conn=") {
			signalRString = signalRObject.substring(
				signalRObject.indexOf(',') + 1
			);
		}

		var splitSignalRString: any = signalRString.split('~');
		//	Global.User.DebugMode && console.log("splitSignalRString = %O", splitSignalRString);

		// for (var property in splitSignalRString) {

		splitSignalRString.forEach((property: any) => {
			var splitField = property.split('!');
			//Global.User.DebugMode && console.log("GetJsonFromSignalR splitField = %O", splitField);
			//Global.User.DebugMode && console.log("GetJsonFromSignalR splitField[0] = " + splitField[0] + ", splitField[1] = " + splitField[1]);
			returnObject[splitField[0]] = isNaN(+splitField[1])
				? splitField[1]
				: +splitField[1];
		});

		//Global.User.DebugMode && console.log("GetJsonFromSignalR returnObject = %O", returnObject);

		return returnObject;
	}

	convertFromUtcToLocalToSite(ms, offsetTarget, returnAsMS?) {
		if (offsetTarget !== 0 && !offsetTarget) return null;

		let offsetTargetInMin = offsetTarget * 60;
		var date = new Date(ms); //    date.setHours(offset);
		var offset = new Date().getTimezoneOffset();

		// let isDST = this.is_DST_Used_In_This_TimeZone();
		let isDST = false;
		let offsetToApplyToThisTimezone: any;
		let calculatedOffsetSoFar = offsetTargetInMin + offset;
		if (isDST) {
			calculatedOffsetSoFar = calculatedOffsetSoFar + 60;
		}
		offsetToApplyToThisTimezone = calculatedOffsetSoFar / 60;
		let finalDateInMs = date.setHours(
			date.getHours() + offsetToApplyToThisTimezone
		);
		if (returnAsMS === true) {
			return finalDateInMs;
		} else {
			return new Date(finalDateInMs);
		}
	}

	convertMillisecondsToDateWithTimezoneOffset(milliseconds: number, timezoneOffset: number) : Date {
		if (!isNaN(milliseconds) && !isNaN(timezoneOffset)) {
			if (timezoneOffset !== 0 && !timezoneOffset) return null;

			let offsetTargetInMin = timezoneOffset * 60;
			var date = new Date(milliseconds); //    date.setHours(offset);
			var offset = new Date().getTimezoneOffset();
	
			let offsetToApplyToThisTimezone: any;
			let calculatedOffsetSoFar = offsetTargetInMin + offset;
			offsetToApplyToThisTimezone = calculatedOffsetSoFar / 60;
	
			let finalDateInMs = date.setHours(
				date.getHours() + offsetToApplyToThisTimezone
			);
			return new Date(finalDateInMs);
		}
		else {
			return null;
		}
		
	}

	convertDateToMillisecondsWithTimezoneOffset(requestedDate: Date, timezoneOffset: number) : number {
		if (timezoneOffset !== 0 && !timezoneOffset) return null;

		let offsetTargetInMin = timezoneOffset * 60;
		var date = new Date(requestedDate); //    date.setHours(offset);
		var offset = new Date().getTimezoneOffset();

		let offsetToApplyToThisTimezone: any;
		let calculatedOffsetSoFar = offsetTargetInMin + offset;
		offsetToApplyToThisTimezone = calculatedOffsetSoFar / 60;

		let finalDateInMs = date.setHours(
			date.getHours() + offsetToApplyToThisTimezone
		);
		return this.DateTimeInMilliseconds(new Date(finalDateInMs));
	}

	is_DST_Used_In_This_TimeZone() {
		let today = new Date(); //Create a date object that is now
		let thisYear = today.getFullYear(); //Get the year as a number
		let Jan_Date = new Date(thisYear, 0, 1); //Month is zero indexed - Jan is zero
		let jan_Timezone_OffSet = Jan_Date.getTimezoneOffset();
		let July_Date = new Date(thisYear, 6, 1);
		let july_Timezone_OffSet = July_Date.getTimezoneOffset();
		let offsetsNotEqual = july_Timezone_OffSet !== jan_Timezone_OffSet; //True if not equal

		return offsetsNotEqual; //If the offsets are not equal for summer and winter then the only possible reason is that DST is used for this time zone
	}

	GetUTCMSForNow() {
		return Date.now(); //-- go to this site to see why this changed: https://stackoverflow.com/questions/8047616/get-a-utc-timestamp
	}

	GetUTCDateFromLocalDate(localDate: any) {
		var timeZoneOffsetHoursFromUTC = new Date().getTimezoneOffset() / 60;
		if (localDate) {
			//console.log("================================================");
			//console.log("localDate = %O", localDate);

			var functionLocalDate = new Date(localDate);
			functionLocalDate.setHours(
				functionLocalDate.getHours() + timeZoneOffsetHoursFromUTC
			);
			//console.log("UTC Date Equivalent = %O", functionLocalDate);

			return functionLocalDate;
		}
	}

	GetLocalDateFromUTCDateString(utcDate: any) {
		var timeZoneOffsetHoursFromUTC = new Date().getTimezoneOffset() / 60;
		if (utcDate) {
			//console.log("================================================");
			//console.log("localDate = %O", localDate);
			var functionLocalDate = new Date({ ...utcDate });

			functionLocalDate.setHours(
				functionLocalDate.getHours() - timeZoneOffsetHoursFromUTC
			);
			//console.log("UTC Date Equivalent = %O", functionLocalDate);

			return functionLocalDate;
		}
	}

	GetLocalDateFromUTCDate(utcDate: any) {
		var timeZoneOffsetHoursFromUTC = new Date().getTimezoneOffset() / 60;
		if (utcDate && utcDate != '') {
			//console.log("================================================");
			//console.log("UTCDate = %O", utcDate);
			var newDate = moment(utcDate).subtract(
				timeZoneOffsetHoursFromUTC,
				'hours'
			);
			var localDate = this.translateMomentDateToJavascriptDate(newDate);
			//console.log("Local Date = %O", localDate);
			return localDate;
		}
		return null;
	}

	async waitUntilFinished(seconds: number) {
		setTimeout(() => {}, seconds);
		return true;
	}

	async showToastMessageShared(
		applicationSharedNotification: applicationSharedNotification
	) {
		console.log(
			'attempting to show toaster message: ' +
				applicationSharedNotification.message +
				'. Global.isMobile = ' +
				Global.isMobile
		);
		if (Global.isMobile) {
			console.log(
				'toastr message: ' + applicationSharedNotification.message
			);
			const toast = await this.mobileToastr.create({
				message: applicationSharedNotification.message,
				duration: 2000,
				position: 'bottom',
				cssClass: 'jbt-toaster-' + applicationSharedNotification.type,
			});
			toast.present();
		} else {
			if (applicationSharedNotification.type === 'info') {
				if (applicationSharedNotification.title !== undefined) {
					if (applicationSharedNotification.override !== undefined) {
						this.toastr.info(
							applicationSharedNotification.message,
							applicationSharedNotification.title,
							applicationSharedNotification.override
						);
					} else {
						this.toastr.info(
							applicationSharedNotification.message,
							applicationSharedNotification.title
						);
					}
				} else {
					this.toastr.info(applicationSharedNotification.message);
				}
			} else if (applicationSharedNotification.type === 'warning') {
				if (applicationSharedNotification.title !== undefined) {
					if (applicationSharedNotification.override !== undefined) {
						this.toastr.warning(
							applicationSharedNotification.message,
							applicationSharedNotification.title,
							applicationSharedNotification.override
						);
					} else {
						this.toastr.warning(
							applicationSharedNotification.message,
							applicationSharedNotification.title
						);
					}
				} else {
					this.toastr.warning(applicationSharedNotification.message);
				}
			} else if (applicationSharedNotification.type === 'success') {
				if (applicationSharedNotification.title !== undefined) {
					if (applicationSharedNotification.override !== undefined) {
						this.toastr.success(
							applicationSharedNotification.message,
							applicationSharedNotification.title,
							applicationSharedNotification.override
						);
					} else {
						this.toastr.success(
							applicationSharedNotification.message,
							applicationSharedNotification.title
						);
					}
				} else {
					this.toastr.success(applicationSharedNotification.message);
				}
			}
			else if (applicationSharedNotification.type === 'error') {
				if (applicationSharedNotification.title !== undefined) {
					if (applicationSharedNotification.override !== undefined) {
						this.toastr.error(
							applicationSharedNotification.message,
							applicationSharedNotification.title,
							applicationSharedNotification.override
						);
					} else {
						this.toastr.error(
							applicationSharedNotification.message,
							applicationSharedNotification.title
						);
					}
				} else {
					this.toastr.error(applicationSharedNotification.message);
				}
			}

			console.log(
				'toastr message: ' + applicationSharedNotification.message
			);
		}
	}

	async showAlert(htmlMessage: string, title?: string) {
		if (Global.isMobile) {
			window.event.stopPropagation();
			const alert = await this.mobileAlertify.create({
				message: htmlMessage,
				buttons: ['OK'],
			});
			alert.present();
		} else {
			swal.fire({
				position: 'center',
				title: title ? title : 'Alert',
				html: htmlMessage,
			});
		}
	}

	displayAlert(msg: string) {
		swal.fire({
			title: 'Password Change',
			position: 'center',
			html: 'Password Email sent to your email address.<br />This password change token can be used only once.',
		});
	}

	GetUserTimeZoneOffsetHours() {
		return this.timeZoneOffsetHoursFromUTC;
	}

	isArray(value: any) {
		return value &&
			typeof value === 'object' &&
			typeof value.length === 'number' &&
			typeof value.splice === 'function' &&
			!(value.propertyIsEnumerable('length'));
	};
}
