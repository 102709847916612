import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeolocationComponent } from './geolocation/geolocation.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { CameraComponent } from './camera/camera.component';
import { VibrationComponent } from './vibration/vibration.component';
import { BarcodeScannerComponent } from './barcode-scanner/barcode-scanner.component';
import { DeviceMotionComponent } from './device-motion/device-motion.component';

@NgModule({
	declarations: [
		GeolocationComponent,
		CameraComponent,
		VibrationComponent,
		BarcodeScannerComponent,
		DeviceMotionComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		IonicModule
	]
})
export class NativeModule { }
