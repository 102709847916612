import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
	name: 'decimalPlaces',
})
export class DecimalPlacesPipe implements PipeTransform {
	obsIdsToIgnoreTrimming: number[] = [54260, 54261];
	constructor(private decimalPipe: DecimalPipe) {}

	transform(
		val: any,
		decimalPlace: number = 1,
		JBTStandardObservationId?: number
	): unknown {
		const format = '1.0-' + decimalPlace;
		if (
			JBTStandardObservationId &&
			this.obsIdsToIgnoreTrimming.includes(JBTStandardObservationId)
		) {
			return val;
		} else if (
			val != null &&
			!isNaN(val) &&
			val.toString().indexOf('.') !== -1
		) {
			val = this.decimalPipe.transform(val, format);
			if (!isNaN(val)) {
				return +val;
			} else {
				return val;
			}
		}

		return val;
	}
}
