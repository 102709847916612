<div class="content" style="margin-top:5px; height:100%">
	<div class="card card-tasks drop-area" id="pasteTarget" (drop)="onDropEvent($event)"  dropZone (filesDropped)="onDropEvent($event)" (filesHovered)="dropzoneState($event)">
		<div *ngIf="global.isMobile && !readOnly">
			<ion-fab *ngIf="!isRecordingVideo && !takingVideo" vertical="bottom" horizontal="start" slot="fixed">
				<ion-fab-button (click)="takePicture()">
				  <ion-icon name="camera-outline"></ion-icon>
				</ion-fab-button>
			</ion-fab>
			<ion-fab vertical="bottom" [horizontal]="takingVideo && !isRecordingVideo ? 'start' : (isRecordingVideo ? 'center' : 'end')" slot="fixed">
				<ion-fab-button [color]="isRecordingVideo || takingVideo ? 'danger' : ''" (click)="isRecordingVideo ? stopVideoRecording() : (takingVideo ? recordVideo() : takeVideo())">
				<ion-icon [name]="isRecordingVideo ? 'stop-outline' : (takingVideo ? 'recording-outline': 'videocam-outline') "></ion-icon>
				</ion-fab-button>
			</ion-fab>
			<ion-fab *ngIf="takingVideo && !isRecordingVideo" vertical="bottom" horizontal="center" slot="fixed">
				<ion-fab-button (click)="changeCamera()">
					<ion-icon name="sync-circle-outline"></ion-icon>
				</ion-fab-button>
			</ion-fab>
			<ion-fab *ngIf="!isRecordingVideo && takingVideo" vertical="bottom" horizontal="end" slot="fixed">
				<ion-fab-button (click)="backToList()">
				  <ion-icon name="arrow-back-outline"></ion-icon>
				</ion-fab-button>
			</ion-fab>
		</div>
		<div *ngIf="!global.isMobile && !readOnly" class="card-header">
			<div class=" tools float-right">
				<div class=" dropdown" dropdown [ngClass]="{ 'open': showSettings, 'show': showSettings }">
				  <button
					class=" btn btn-link dropdown-toggle btn-icon dropdown-toggle"
					data-toggle="dropdown"
					dropdownToggle
					type="button"
					(click)="showSettingsMenu()"
					[ngClass]="{ 'chevron-up': showSettings }"
				  >
					<i class=" tim-icons icon-settings-gear-63"> </i>
				  </button>
				  <div *ngIf="showSettings" class=" dropdown-menu dropdown-menu-right show" >
					<a *ngIf="files.length > 0" class=" dropdown-item text-danger" (click)="removeAllFiles($event)">Remove All Files</a>
					<a class=" dropdown-item" (click)="closeWindow()"> Close </a>
				  </div>
				</div>
			</div>
		  <h3 class=" card-title" [ngClass]="{ 'drop-area-hover': dropzoneActive }" >{{listTitle}}</h3>
		  <h6 class="card-subtitle" [ngClass]="{ 'drop-area-hover': dropzoneActive }" (click)="onClick()">Drag and Drop files here.&nbsp;&nbsp;&nbsp;	Click here to open File Explorer.</h6>
		</div>
		<div *ngIf="files.length == 0 && !isRecordingVideo && !takingVideo" class="card-body; margin: 5px;" >
			No documentation has been added for this asset. Please click the Camera icon to add a new photo or file. Click the Video icon to take a video.
		</div>
		<div *ngIf="files.length > 0 && !isRecordingVideo && !takingVideo" class="card-body file-upload" nv-file-drop="" uploader="uploader" style="font-size: xx-small;" >
			<div>
				<table>
				  <thead class=" text-primary text-header">
					<tr>
					  <th class=" text-center">Type</th>
					  <th>Date</th>
					  <th>Name</th>
					  <th>Progress</th>
					  <th class=" text-right">Size</th>
					  <th *ngIf="!readOnly" class=" text-right" style="padding-right:10px">Actions</th>
					</tr>
				  </thead>
				  <tbody cdkDropList #new="cdkDropList" [cdkDropListData]="newFiles" >
					<input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" accept="image/*" style="display:none;" />
					<tr *ngFor="let file of files" (click)="viewFile(file)" >
						<td style="padding:5px !important" class=" text-center">
							<div class=" photo">
								<div *ngIf="file.iconFile">
									<img alt="photo" [src]="file.iconFile" title="{{ file.name }}" />
								</div>
								<div *ngIf="file.thumbnailImage">
									<img alt="photo" [src]="file.thumbnailImage" title="{{file.name}}" />
								</div>
								<div *ngIf="file.thumbnailUrl && !file.thumbnailImage">
									<img alt="photo" [src]="file.thumbnailUrl" title="{{file.name}}" />
								</div>
							</div>
						</td>
						<td style="padding:5px !important">{{file.data.lastModifiedDate | date: "MM/dd/yyyy"}}</td>
						<td *ngIf="!file.edit" style="padding:5px !important">{{file.data.name}}</td>
						<td *ngIf="file.edit" ><input type='text' [id]="file.data.name" [value]='file.data.name' title='Enter a name for this file.' (change)="updateFileName(file, $event)" /></td>
						<td style="padding:5px !important" class=" text-center">
							<div style="font-size:0.6rem" *ngIf="file.uploading">sent: <mat-progress-bar mode="determinate" color="primary" [value]="file.uploadProgress"></mat-progress-bar></div>
							<div style="font-size:0.6rem" *ngIf="file.uploading">received: <mat-progress-bar mode="determinate" color="warn" [value]="file.downloadProgress"></mat-progress-bar></div>
						</td>
					  <td style="padding:5px !important" class=" text-right">{{ file.data.size | fileSize }} </td>
					  <td *ngIf="!readOnly" style="padding:5px !important" class=" text-right" style="padding-right:10px">
						<button class="btn btn-danger btn-link btn-sm" tooltip="Delete this file" type="button" (click)="deleteFile(file, $event)">
							  <i class=" tim-icons icon-simple-remove"></i>
						</button>
						<button style="z-index: 1" class="btn btn-link btn-sm" tooltip="Edit this file" type="button" (click)="editFile(file, $event)">
							<i class="tim-icons icon-pencil"></i>
						</button>
					  </td>
					</tr>
				  </tbody>
				</table>
			  </div>
		</div>
		<div *ngIf="isRecordingVideo || takingVideo">
			<!-- Display the video stream while capturing -->
			<video class="video" #video autoplay playsinline muted [hidden]="!isRecordingVideo"></video>
			<!-- Neccessary for the video player -->
			<div *ngIf="!isRecordingVideo" id="fullscreen" slot="fixed">
				Click the Record button below to start your video. You should be using the <strong>{{ useRearCamera ? "Rear" : "Front" }}</strong> camera.
			</div>
		</div>
	</div>
