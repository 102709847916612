import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReleaseNotesComponent } from './release-notes.component';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
  declarations: [
    ReleaseNotesComponent
  ],
  imports: [
    CommonModule,
    ButtonModule,
    MatMenuModule,
    MatTooltipModule,
    MatDialogModule,
    MatButtonModule
  ],
  exports: [ReleaseNotesComponent]
})
export class ReleaseNotesModule { }
