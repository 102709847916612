import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NativeModule } from '../app/native/native.module';

import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Vibration } from '@ionic-native/vibration/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';

import { File } from '@ionic-native/file/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { DeviceMotion } from '@ionic-native/device-motion/ngx';
import { ServicesModule } from 'projects/shared-lib/src/lib/services/services.module';
import { HttpClientModule } from '@angular/common/http';

import { DirectivesModule } from './_other-modules/directives.module';

import { Plugins } from '@capacitor/core';

const { SplashScreen, Camera, Filesystem, Storage, CameraDirection } = Plugins;

import { ReleaseNotesModule } from 'projects/shared-lib/src/lib/components/release-notes/release-notes.module';
import { PipeModule } from 'projects/shared-lib/src/lib/_helpers/pipes/pipe.module';
import { LoadingSpinnerModule } from 'projects/shared-lib/src/lib/components/loading-spinner/loading-spinner.module';
import { MatDialogRef } from '@angular/material/dialog';
import { SnackbarBroadcastMessageModule } from 'projects/shared-lib/src/lib/components/snackbar-broadcast-message/snackbar-broadcast-message.module';
import { DialogModule } from '@progress/kendo-angular-dialog';

// import { AssetSummaryViewModule } from 'projects/shared-lib/src/lib/components/asset-summary-view/asset-summary-view.module';
import {
	IStepOption,
	TourAnchorMatMenuDirective,
	TourMatMenuModule,
	TourService,
} from 'ngx-ui-tour-md-menu';
import { MaterialModule } from 'projects/desktop/src/app/_other-modules/material.module';

@NgModule({
	declarations: [AppComponent],
	entryComponents: [],
	imports: [
		IonicModule.forRoot(),
		DirectivesModule,
		BrowserModule,
		BrowserAnimationsModule,
		ServicesModule,
		ToastrModule.forRoot({
			timeOut: 5000,
			positionClass: 'toast-bottom-left',
			preventDuplicates: true,
		}),
		AppRoutingModule,
		NativeModule,
		HttpClientModule,
		PipeModule,
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
		ReleaseNotesModule,
		LoadingSpinnerModule,
		SnackbarBroadcastMessageModule,
		DialogModule,
		MaterialModule,
		TourMatMenuModule.forRoot(),
		// AssetSummaryViewModule,
	],
	providers: [
		NativeModule,
		StatusBar,
		Vibration,
		Geolocation,
		File,
		BarcodeScanner,
		DeviceMotion,

		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{
			provide: MatDialogRef,
			useValue: {},
		},
		TourService,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}

// -- ServiceWorkerModule.register("ngsw-worker.js", { enabled: environment.production }) will only have the service worker module enabled for production.  I set the enabled: true above so that
// -- all versions (Test, Beta, and Prod) would be enabled for Service Worker updates. --Kirk T. Sherer, January 27, 2021.
