import { Injectable } from "@angular/core";

@Injectable()
export class IncomingDataService {
	public incomingDataArray: Array<any>;
	public incomingSQLDataArray: Array<any>;

	constructor() {
		this.incomingDataArray = [];
		this.incomingSQLDataArray = [];
	}
}