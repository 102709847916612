import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DialogDirective } from "../_directives/dialog.directive";
import { SharkDirective } from '../_directives/shark.directive';

@NgModule({
  declarations: [DialogDirective, SharkDirective],
  imports: [
    CommonModule
  ],
  exports: [DialogDirective, SharkDirective]
})
export class DirectivesModule { }
