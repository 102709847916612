<div style="display: block; text-align: center;">
	<div style="font-size: 20px">{{data.Message}}</div>
</div>
<div style="display: block">

  <div style="font-size: 14px">{{data.Description}}</div>
  <div style="display: flex; align-items: center; justify-content: center;">
    <button mat-button (click)="snackBarRef.dismiss()">
        Close
      </button>
  </div>
</div>

