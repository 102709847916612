import { Injectable, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Global } from '../_constants/global.variables';

@Injectable({
	providedIn: 'root',
})
export class WeatherService implements OnInit {
	environment: any;

	ngOnInit() {}

	constructor(private http: HttpClient) {}

	getCurrentWeather(loc: string) {
		return this.http.get(
			`${Global.Weather.apiUrl}/weather?q=${loc}&appid=${Global.Weather.apiKey}`
		);
	}

	getCurrentWeatherLatLong(lat: number, long: number) {
		return this.http.get(
			`${Global.Weather.apiUrl}/weather?lat=${lat}&lon=${long}&appid=${Global.Weather.apiKey}`
		);
	}
	getForecast(loc: string) {
		return this.http.get(
			`${Global.Weather.apiUrl}/forecast?q=${loc}&appid=${Global.Weather.apiKey}`
		);
	}

	getForecastLatLong(lat: number, long: number) {
		return this.http.get(
			`${Global.Weather.apiUrl}/forecast/daily?lat=${lat}&lon=${long}&cnt=7&appid=${Global.Weather.apiKey}`
		);
	}

	getUv(lat: number, lon: number) {
		return this.http.get(
			`${Global.Weather.apiUrl}/uvi/forecast?lat=${lat}&lon=${lon}&cnt=7&appid=${Global.Weather.apiKey}`
		);
	}

	getCardinalDirectionFromDegrees(degrees: number) {
		var val = Math.floor(degrees / 22.5 + 0.5);
		var arr = [
			'N',
			'NNE',
			'NE',
			'ENE',
			'E',
			'ESE',
			'SE',
			'SSE',
			'S',
			'SSW',
			'SW',
			'WSW',
			'W',
			'WNW',
			'NW',
			'NNW',
		];
		return arr[val % 16];
	}
}
