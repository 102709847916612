import { QuestionBase } from './question-base';

export class TextDifferencesComparisonQuestion extends QuestionBase<string> {
	controlType = 'text-differences-comparison';
	type: string;

	constructor(options: {} = {}) {
		super(options);
		this.type = options['type'] || '';
		this.leftFile = options['leftFile'] || '';
		this.rightFile = options['rightFile'] || '';
		this.left = null;
		this.right = null;
	}
}	
