import { QuestionBase } from './question-base';

export class UibButtonMultiSelectQuestion extends QuestionBase<string> {
  controlType = 'uib-button-multi-select';
  type = 'any';

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
  }
}
