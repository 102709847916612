import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerComponent } from './loading-spinner.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@NgModule({
	declarations: [LoadingSpinnerComponent],
	imports: [CommonModule, IndicatorsModule],
	exports: [LoadingSpinnerComponent],
})
export class LoadingSpinnerModule {}
