import { Component, OnInit } from '@angular/core';
import { DeviceMotion, DeviceMotionAccelerationData } from '@ionic-native/device-motion/ngx';

@Component({
	selector: 'device-motion',
	templateUrl: './device-motion.component.html',
	styleUrls: ['./device-motion.component.scss'],
})
export class DeviceMotionComponent implements OnInit {

	data: any;
	subscription: any;

	constructor(private deviceMotion: DeviceMotion) { }

	ngOnInit() { }


	startTracking() {
		// Watch device acceleration
		this.subscription = this.deviceMotion.watchAcceleration().subscribe((acceleration: DeviceMotionAccelerationData) => {
			this.data = acceleration;
		});
	}

	stopTracking() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
