import { Injectable, OnInit } from "@angular/core";
import {
  Observable,
  throwError,
  defer,
  of,
  observable,
  BehaviorSubject
} from "rxjs";
import { tap, catchError, min, map } from "rxjs/operators";
import { StorageMap } from "@ngx-pwa/local-storage";

@Injectable()
export class IndexedDBService {
	public indexedDBPresent: boolean = false;
	public service: any;
	private dbName: string;
	private version: string;
	private schema: any;
	private _error: any;
	public _db: any;

	data$: Observable<string | undefined>;

	constructor(private storageMap: StorageMap) {
		this.service = this;
		this._error = {
			setErrorHandlers: function (request: any, errorHandler: any) {
				if (typeof request !== 'undefined') {
					if ('onerror' in request) request.onError = errorHandler;
					if ('onblocked' in request)
						request.onblocked = errorHandler;
					if ('onabort' in request) request.onabort = errorHandler;
				}
			},
		};
	}
	getDBInstance(dbName: string, version: string, schema: any) {
		this.dbName = dbName;
		this.version = version;
		this.schema = schema;
		var service = this.service;
		var thisObject = this;
		var dbName = dbName;
		var version = version;
		var schema = schema;

		const indexedDB$ = new Observable((indexedDBsubscriber) => {
			var _db = {
				instance: null,
				open(databaseName: any, version: any, schema: any) {
					var deferred$: any = new Observable((subscriber) => {
						// console.log(
						//   "trying to open indexedDB... window.indexedDB = %O",
						//   window.indexedDB
						// );
						if (window.indexedDB) {
							var request = window.indexedDB.open(
								databaseName,
								version
							);
							//console.log("request = %O", request);

							service._error.setErrorHandlers(
								request,
								deferred$.pipe(catchError((err) => of([])))
							);
							request.onupgradeneeded = function (e: any) {
								//console.log("localDB Upgrading.....");
								var db = e.target.result; //Clear all data stores and recreate on id. //Collect the store names in a separate array because the forEach method will not work on them.
								//console.log("db = %O", db);
								var count = db.objectStoreNames.length;
								var names = [];
								for (var x = 0; x < count; x++) {
									names.push(db.objectStoreNames[x]);
								} //Delete all stores
								names.forEach(function (n: any) {
									//console.log("Deleting object store " + n);
									db.deleteObjectStore(n);
								});
								schema.forEach(function (ds: any) {
									// console.log("schema = %O", ds);
									// console.log("Creating objectStore " + ds.dataStoreName);
									var objectStore = db.createObjectStore(
										ds.dataStoreName,
										{
											keyPath: ds.keyName,
										}
									);
									if (ds.indices) {
										ds.indices.forEach(function (index) {
											objectStore.createIndex(
												index.name,
												index.fieldName,
												{
													unique: false,
												}
											);
										});
									}
								});
							};
							request.onsuccess = function (e: any) {
								// console.log(
								//   "this.request.onsuccess: e.target.result = %O",
								//   e.target.result
								// );
								service.indexedDBPresent = true;
								_db.instance = e.target.result;
								service._db = e.target.result;
								service._error.setErrorHandlers(
									_db.instance,
									deferred$.pipe(catchError((err) => of([])))
								);

								subscriber.next(e.target.result);
								subscriber.complete();
							};
						} else {
							subscriber.next(null);
							subscriber.complete();
						}
					});

					return deferred$;
				},
				requireOpenDB(objectStoreName) {
					if (service._db.instance === null) {
						const error$ = throwError(
							'You cannot use an object store when the database has not been opened. Store Name = ' +
								objectStoreName
						);
						console.log(
							'You cannot use an object store when the database has not been opened. Store Name = ' +
								objectStoreName
						);
						return error$.pipe(catchError((err) => of(`${err}`)));
					}
				},

				getObjectStore(objectStoreName, mode) {
					var store$: any = new Observable((subscriber) => {
						if (!this.instance) {
							// console.log("indexedDBPresent = " + this.instance);
							subscriber.next(null);
							subscriber.complete();
						}
						mode = mode || 'readonly';
						var txn = this.instance.transaction(
							objectStoreName,
							mode
						);
						var store = txn.objectStore(objectStoreName);
						subscriber.next(store);
						subscriber.complete();
					});

					return store$;
				},

				requireObjectStoreName(objectStoreName) {
					if (
						typeof objectStoreName === 'undefined' ||
						!objectStoreName ||
						objectStoreName.length === 0
					) {
						const error$ = throwError(
							'An objectStoreName is required.'
						);
						// console.log("An objectStoreName is required.");
						return error$.pipe(catchError((err) => of(`${err}`)));
					}
				},

				getCount(objectStoreName) {
					var count$: any = new Observable((subscriber) => {
						if (!this.instance) {
							// console.log("indexedDBPresent = " + this.instance);
							subscriber.next(null);
						}
						this.requireObjectStoreName(objectStoreName);
						this.requireOpenDB(objectStoreName);
						var store = service.getObjectStore(objectStoreName);
						var request = store.count();
						var count;

						request.onsuccess = function (e) {
							count = e.target.result;
							console.log('e.target.result = ' + count);
							subscriber.next(count);
							subscriber.complete();
						};
					});

					return count$;
				},

				getAll(objectStoreName) {
					var getAll$: any = new Observable((subscriber) => {
						if (!this.instance) {
							// console.log("indexedDBPresent = " + this.instance);
							subscriber.next(null);
						}
						this.requireObjectStoreName(objectStoreName);
						this.requireOpenDB(objectStoreName);
						this.getObjectStore(objectStoreName).subscribe(
							(data) => {
								var store = data;
								// console.log("getAll Function: store = %O", store);
								if (store.getAll) {
									var request = store.getAll();
									var allData;
									request.onsuccess = function (e) {
										allData = e.target.result;
										// console.log("e.target.result = " + allData);
										subscriber.next(allData);
										subscriber.complete();
									};
								} else {
									// console.log("getAll Evaluated to null.");
									subscriber.next(null);
									subscriber.complete();
								}
							},
							(err) =>
								console.error(
									`Error with getAll function: ${err}`
								)
						);
					});

					return getAll$;
				},

				upsert(objectStoreName: string, dataObject: any) {
					var upsert$: any = new Observable((subscriber) => {
						//console.log("Upsert data = %O", data);
						if (!this.instance) {
							// console.log("upsert: indexedDBPresent = " + this.instance);
							subscriber.next(null);
							subscriber.complete();
						}
						// console.log("upsert: requireObjectStoreName invoking...");
						this.requireObjectStoreName(objectStoreName);
						// console.log("upsert: requireOpenDB invoking...");
						this.requireOpenDB(objectStoreName); //Copy will remove all function definition properties on the data.
						//console.log("upsert: Object.assign executing...");
						var data = Object.assign({}, data); //-- equivalent of angular.copy in AngularJS...
						// console.log(
						//   "upsert: this.getObjectStore(" +
						//     objectStoreName +
						//     ", 'readwrite') executing..."
						// );
						this.getObjectStore(
							objectStoreName,
							'readwrite'
						).subscribe(
							(s) => {
								var store = s;
								//console.log("upsert: store = %O", store);
								var request = store.put(dataObject);
								//console.log("upsert: request = %O", request);
								request.onsuccess = function (e) {
									subscriber.next(dataObject);
									subscriber.complete();
								};
							},
							(err) =>
								console.error(
									`Error with upsert function: ${err}`
								)
						);
					});

					return upsert$;
				},

				delete(objectStoreName, key) {
					var delete$: any = new Observable((subscriber) => {
						if (!this.instance) {
							//console.log("indexedDBPresent = " + this.instance);
							subscriber.next(null);
							subscriber.complete();
						}
						this.requireObjectStoreName(objectStoreName);
						this.requireOpenDB(objectStoreName);
						this.getObjectStore(
							objectStoreName,
							'readwrite'
						).subscribe(
							(data) => {
								var store = data;
								var request = store.delete(key);
								subscriber.complete();
							},
							(err) =>
								console.error(
									`Error with delete function: ${err}`
								)
						);
					});

					return delete$;
				},

				getById(objectStoreName, key) {
					var getById$: any = new Observable((subscriber) => {
						if (!this.instance) {
							//console.log("indexedDBPresent = " + this.instance);
							subscriber.next(null);
							subscriber.complete();
						}

						this.requireObjectStoreName(objectStoreName);
						this.requireOpenDB(objectStoreName);
						this.getObjectStore(objectStoreName).subscribe(
							(data) => {
								//console.log("getById: getObjectStore = %O", data);
								var store = data;
								var request = store.get(key);

								request.onsuccess = function (e) {
									var result = e.target.result;
									subscriber.next(result);
									subscriber.complete();
								};
							},
							(err) =>
								console.error(
									`Error with getById function: ${err}`
								)
						);
					});

					return getById$;
				},

				getByIndex(objectStoreName, indexName, key) {
					var getById$: any = new Observable((subscriber) => {
						if (!this.instance) {
							//console.log("indexedDBPresent = " + this.instance);
							subscriber.next(null);
							subscriber.complete();
						}
						this.requireObjectStoreName(objectStoreName);
						this.requireOpenDB(objectStoreName);
						var store = service
							.getObjectStore(objectStoreName)
							.subscribe(
								(data) => {
									var store = data;
									var index = store.index(indexName);
									var range = IDBKeyRange.only(key);
									var outputData = [];
									index.openCursor(range).onsuccess =
										function (e) {
											var cursor = e.target.result;
											if (cursor) {
												outputData.push(cursor.value);
												cursor.continue();
											} else {
												subscriber.next(outputData);
												subscriber.complete();
											}
										};
								},
								(err) =>
									console.error(
										`Error with getByIndex function: ${err}`
									)
							);
					});
					return getById$;
				},

				clear(objectStoreName) {
					var clear$: any = new Observable((subscriber) => {
						if (!this.instance) {
							//console.log("indexedDBPresent = " + this.instance);
							subscriber.next(null);
							subscriber.complete();
						}
						this.requireObjectStoreName(objectStoreName);
						this.requireOpenDB(objectStoreName);
						this.getObjectStore(
							objectStoreName,
							'readWrite'
						).subscribe(
							(data) => {
								var store = data;
								var request = store.clear();
								request.onsuccess = function (e) {
									subscriber.next(null);
									subscriber.complete();
								};
							},
							(err) =>
								console.error(
									`Error with clear function: ${err}`
								)
						);
					});

					return clear$;
				},
			}; //console.log("Opening Database");

			_db.open(dbName, version, schema).subscribe(
				(data) => {
					//console.log("Database resolved");
					indexedDBsubscriber.next(_db);
					indexedDBsubscriber.complete();
				},
				(err) => {
					console.error('Failed opening indexedDB...%O', err);
				},
				() => {
					//console.log("IndexedDB should be opened. _db = %O", _db);
				}
			);

			//console.log("_db = %O", _db);
		});

		return indexedDB$;
	}
}
