import { Injectable, NgZone } from "@angular/core";
import { IUser } from "../_models/user.model";

import { Observable, Subject } from "rxjs";
import { Router } from "@angular/router";
import { AuthenticationService } from "./authentication.service";
import { UtilityService } from "./utility.service";
import { CacheUtilityService } from "./cache-utility.service";
import { DataService } from "./data.service";
import * as moment from "moment";
import Swal from "sweetalert2";
import { MatDialog } from "@angular/material/dialog";

import { SignalRCoreService } from "./signalr-core.service";
import { Global } from "../_constants/global.variables";
import { ReleaseNotesComponent } from "../components/release-notes/release-notes.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SnackbarBroadcastMessageComponent } from "../components/snackbar-broadcast-message/snackbar-broadcast-message.component";
import { IGlobal } from "../_models/global.model";
import { IAsset } from "../_models/asset.model";

@Injectable({
	providedIn: "root"
})
export class SecurityService {
	public currentUser: IUser;
	public currentUserIsLoggedIn$: Subject<boolean> = new Subject();
	public redirectUrl: string;
	public serviceName: string = "security-service: ";
	public lastVisitedRouteData: any;

	systemMessage: { severity: string; message: string; active: boolean };

	constructor(private signalRCore: SignalRCoreService, private authenticationService: AuthenticationService, private router: Router, private dataService: DataService, private cacheUtilityService: CacheUtilityService, private zone: NgZone, private utilityService: UtilityService, public dialog: MatDialog, private snackBar: MatSnackBar) {
		this.currentUser = Global.User.currentUser;
	}

	getCurrentUser() {
		this.currentUser = JSON.parse(localStorage.getItem("currentUser")) as IUser;
		Global.User.currentUser = this.currentUser;

		return this.currentUser;
	}

	currentUserIsLoggedIn() {
		console.log(this.serviceName + "Global.User = %O", Global.User);
		console.log(this.serviceName + "Global.User.isLoggedIn = " + Global.User.isLoggedIn);

		if (Global && !Global.User.isLoggedIn && !Global.User.isBeingAuthenticated) {
			this.authenticateUser();
		} else {
			this.updateIsLoggedInUserStatus(true);
		}
	}

	updateIsLoggedInUserStatus(isLoggedIn: boolean) {
		console.log(this.serviceName + "updateIsLoggedInUserStatus: curentUserIsLoggedIn: " + isLoggedIn);
		Global.User.isLoggedIn = isLoggedIn;
		this.currentUserIsLoggedIn$.next(Global.User.isLoggedIn);
		this.signalRCore.LogActivity("Current user is logged in.");
	}

	get isAdmin(): boolean {
		if (!Global.User.currentUser) {
			this.currentUser = JSON.parse(localStorage.getItem("currentUser")) as IUser;
			Global.User.currentUser = this.currentUser;
		} else {
			this.currentUser = Global.User.currentUser;
		}

		return Global.User.isAdmin;
	}

	authenticateUser() {
		console.log(this.serviceName + "Global.Theme = " + Global.Theme);
		console.log(this.serviceName + "Global = %O", Global);
		this.dataService.ApplicationLoadingMessage("Logging in User", true);
		if (!Global.User.isBeingAuthenticated) {
			this.signalRCore.LogActivity("Global.User.isBeingAuthenticated is null. User is now being authenticated...");

			Global.User.isBeingAuthenticated = true;
			console.log(this.serviceName + "authenticating user...Global.User.isBeingAuthenticated = " + Global.User.isBeingAuthenticated);
			const currentUser = this.getCurrentUser();

			console.log(this.serviceName + "currentUser = %O", currentUser);

			if (currentUser && currentUser.ODataAccessToken) {
				console.log(this.serviceName + "Logging in with access token '" + currentUser.ODataAccessToken + "'...");
				//-- assuming the person is logged in so the login screen won't appear. --Kirk T. Sherer, March 1, 2021.
				this.signalRCore.LogActivity("Logging in with access token...");

				this.dataService.ApplicationLoadingMessage("Logged In", true);
				this.processLoggedInUser(null, currentUser.Username); //-- processing the logged-in user if the access token is valid.
				//-----------------------------------------------------------------------------------------------------
				// NOTE:  	We're no longer going to the webAPI to authenticate the user's access token.  We're just
				//			going to send the access token into the processLoggedInUser function and if the User
				//			record comes back with no valid Username defined, then we kick the user out to the login
				//			screen.  --Kirk T. Sherer, March 1, 2021.
				//-----------------------------------------------------------------------------------------------------
			} else {
				console.log(this.serviceName + "Logging in with username / password since localStorage currentUser doesn't exist...");
				console.log(this.serviceName + "Navigating to /login...");
				this.signalRCore.LogActivity("Logging in with username / password since localStorage currentUser doesn't exist...");

				if (Global.isMobile) {
					this.router.navigate(["login"]);
				} else {
					this.router.navigate(["user/login"]);
				}
			}
		} else {
			console.log(this.serviceName + "User is currently being authenticated...");
			this.signalRCore.LogActivity("User is being authenticated...");
		}
	}

	logInAsDifferentUser(accessToken: string) {
		this.signalRCore.LogActivity("User is logging in as a different user.");
		Global.User.isBeingAuthenticated = true;
		this.currentUser = null; //-- have to be sure this is cleared out to log in as a different user. --Kirk T. Sherer, September 7, 2021.
		this.authenticationService.loginUserWithAccessToken(accessToken).then(
			(accessToken: any) => {
				if (!accessToken) {
					// -- login was invalid, so go to login with username/password.
					console.log(this.serviceName + "Logging in with username / password since logging in with ODataAccessToken was invalid...");
					console.log(this.serviceName + "Navigating to /login...");
					this.signalRCore.LogActivity("Logging in with username / password since logging in with ODataAccessToken was invalid...");

					if (Global.isMobile) {
						this.router.navigate(["login"]);
					} else {
						this.router.navigate(["user/login"]);
					}
				} else {
					//-- user was successfully logged in as another user.  Send out the update to the 'isLoggedIn' status.
					console.log(this.serviceName + "Logging in with access token '" + accessToken + "'...");
					this.signalRCore.LogActivity("Logging in with other user's access token...");
					this.processLoggedInUser(accessToken);
				}
			},
			(err: Error) => {
				console.error(`user was not authorized. Error: ${err}`);
				console.log(this.serviceName + "Navigating to /login...");
				this.signalRCore.LogActivity("User was not authorized to log in as another user. Navigating to /login...");
				if (Global.isMobile) {
					this.router.navigate(["login"]);
				} else {
					this.router.navigate(["user/login"]);
				}
			},
			() => {
				console.log(this.serviceName + "All finished with user authentication.");
				this.signalRCore.LogActivity("All finished with user authentication.");
				Global.User.isBeingAuthenticated = false;
			}
		);
	}
	processLoggedInUser(accessToken: string, username?: string) {
		this.signalRCore.startHub();
		this.dataService.ApplicationLoadingMessage("Preparing to connect to database", true);
		this.signalRCore.LogActivity("Preparing to connect to database.");
		this.signalRCore.LogActivity("Using " + (Global.isMobile ? "mobile" : "desktop") + " application.");
		console.log("Using " + (Global.isMobile ? "mobile" : "desktop") + " application.");

		//Connecting to User and Site Data
		var time0 = performance.now();
		var authorizedSitesAndPrivileges: any = [];
		var mainMenuPrivileges: any = [];
		var widgetsPerSite: any = [];
		var authorizedSitesAndPrivilegesObject = {};

		var widgetsPerSiteObject = {};
		var listOfUserRolesAndPrivileges = {};
		var listOfUserRoles = {};
		var listOfUserPrivileges = {};

		// --login was valid, so navigate to the main layout.
		console.log(this.serviceName + "processLoggedInUser invoked...");
		var accessTokenWithParameterName = accessToken != null ? "@accessToken='" + accessToken + "'" : "";
		var usernameWithParameterName = username != null ? "@Username='" + username + "'" : "";

		//-- build out JSON object for SQLMultiAction first... --Kirk T. Sherer, February 10, 2021.
		var requestJSON: any = [
			{
				label: "PersonAndSecurityRecord",
				sqlStatement: "Security.User_Login_PersonAndSecurityRecord " + accessTokenWithParameterName + usernameWithParameterName + ", @EnvironmentId=" + Global.Application.Environment.Id + ", @isMobile=" + (Global.isMobile ? "1" : "0")
			},
			{
				label: "ListOfUserRolesAndPrivileges",
				sqlStatement: "Security.User_Login_DistinctListOfUserRolesAndPrivileges"
			},
			{
				label: "AuthorizedSitesAndPrivileges",
				sqlStatement: username != null ?
					"Security.User_Login_AuthorizedSitesRolesAndPrivilegesForUser_ByUsername @Username='" + username + "'" : "Security.User_Login_AuthorizedSitesRolesAndPrivilegesForUser @accessToken='" + accessToken + "'"
			},
			{
				label: "MainMenuPrivileges",
				sqlStatement: username != null ?
					"Security.User_Login_MainMenuPrivilegesForUser_ByUsername @Username='" + username + "'" : "Security.User_Login_MainMenuPrivilegesForUser @accessToken='" + accessToken + "'"
			},
			{
				label: "UserFavorites",
				sqlStatement: username != null ?
					"Security.User_Login_UserFavorites_ByUsername @Username='" + username + "'" : "Security.User_Login_UserFavorites @accessToken='" + accessToken + "'"
			},
			{
				label: "WidgetsPerSite",
				sqlStatement: username != null ?
					"Security.User_Login_WidgetsPerSite_ByUsername @Username='" + username + "'" : "Security.User_Login_WidgetsPerSite @accessToken='" + accessToken + "'"
			},
			{
				label: "Dashboards",
				sqlStatement: "Security.User_Login_DashboardsForUser " + accessTokenWithParameterName + usernameWithParameterName + ", @isMobile=" + Global.isMobile
			},
			{
				label: "DashboardTimeScopes",
				sqlStatement: "API.GetDashboardTimeScopes"
			},
			{
				label: "TacticalDashboardUpdateIntervals",
				sqlStatement: "API.GetTacticalDashboardUpateIntervals"
			},
			{
				label: "GeofencingZoneTypes",
				sqlStatement: "API.GeofencingGetAllZoneTypes"
			},
			{
				label: "CacheUtilityData",
				sqlStatement: username != null ?
					"API.GetUserCachedTableData_Without_Major_Collections_ByUsername @Username='" + username + "'" : "API.GetUserCachedTableData_Without_Major_Collections @accessToken = '" + accessToken + "'"
			},
			{
				label: "JBTStandardObservationsLessThan16000",
				sqlStatement: "API.GetUserCachedTableData_JBTStandardObservations_IDs_Less_Than_16000"
			},
			{
				label: "JBTStandardObservationsBetween16000And55000",
				sqlStatement: "API.GetUserCachedTableData_JBTStandardObservations_IDs_Between_16000_And_55000"
			},
			{
				label: "JBTStandardObservationsGreaterThan55000",
				sqlStatement: "API.GetUserCachedTableData_JBTStandardObservations_IDs_Greater_Than_55000"
			},
			{
				label: "Assets",
				sqlStatement: username != null ?
					"API.GetUserCachedTableData_Assets_ByUsername @Username='" + username + "'" : "API.GetUserCachedTableData_Assets @accessToken = '" + accessToken + "'"
			},
			{
				label: "AssetGraphics",
				sqlStatement: username != null ?
					"API.GetUserCachedTableData_AssetGraphics_ByUsername @Username='" + username + "'" : "API.GetUserCachedTableData_AssetGraphics @accessToken = '" + accessToken + "'"
			},
			{
				label: "SystemsAndSystemGraphics",
				sqlStatement: username != null ?
					"API.GetUserCachedTableData_Systems_and_SystemGraphics_ByUsername @Username='" + username + "'" : "API.GetUserCachedTableData_Systems_and_SystemGraphics @accessToken = '" + accessToken + "'"
			},
			{
				label: "GenericTacticalDashboardWidgets",
				sqlStatement: "API.GetListOfTacticalDashboardRepeatWidgetType"
			},

			{
				label: "UpdateLastLoginDate",
				sqlStatement: username != null ?
					"Security.User_UpdateLastLoginDate_ByUsername @Username='" + username + "'" : "Security.User_UpdateLastLoginDate @accessToken = '" + accessToken + "', @isMobile=" + Global.isMobile //-- log the user's login date/time in SQL Server so we can track usage of the user accounts.  This was in the PersonAndSecurityRecord stored procedure earlier. --Kirk T. Sherer, July 27, 2023.
			}
		];
		console.log("requestJSON = %O", requestJSON);

		try {
			this.dataService.ApplicationLoadingMessage("Connecting to Database", true);

			this.dataService.SQLMultiAction(requestJSON, accessToken).then(
				(data: any) => {
					//logged in successfully
					this.dataService.ApplicationLoadingMessage("", false);

					this.signalRCore.LogActivity("Execution of Multi Action successful.");
					console.log(this.serviceName + "SQLMultiAction for requestJSON = %O", data);
					var collections: any = data;
					var user: any = {};
					user.Menu = {
						Dashboards: [],
						Privileges: []
					};
					user.Security = {
						Aggregate: {
							Collections: {
								AssetIds: [],
								SiteIds: [],
								WidgetTypeIds: []
							}
						},
						AllowedDashboardTypeIds: [],
						AllowedDashboardTypes: [
							{ Id: 1, Name: "Standard" },
							{ Id: 2, Name: "Tactical" }
						]
					};
					var userTimer = performance.now();
					collections.forEach((collection: any) => {
						switch (collection.label) {
							case "PersonAndSecurityRecord":
								if (!collection.data) {
									this.signalRCore.LogActivity("Invalid User. Going back to login screen.");

									//--this insures that if we don't receive anything for this person, we're headed back to the login screen. Thanks for the recommendation, Dylan.
									this.authenticationService.resetApplicationToDefaultSettings();
									if (Global.isMobile) {
										this.router.navigate(["login"]);
									} else {
										this.router.navigate(["user/login"]);
									}
								} else {
									var personRecord = collection.data.first(); //-- if we're here, we might have an inactive user since the token was valid.
									console.log(this.serviceName + "personRecord = %O", personRecord);

									if (personRecord == "E") {
										//-- we have an error in the person record instead of a data collection.  Reload the application since it didn't finish bringing up the site.
										this.signalRCore.LogActivity("Error in user validation.  Reloading site....");
										window.location.reload();
									}

									this.signalRCore.LogActivity("Valid user. Continuing user setup...");

									for (var field in personRecord) {
										if (field != "OrganizationId" && field != "OrganizationName" && field != "EnvironmentVersion") {
											switch (field) {
												case "IsSystemAdministrator":
													user.IsSystemAdministrator = personRecord[field] == null ? false : personRecord[field] == 1;
													break;
												case "IsOrganizationAdministrator":
													user.IsOrganizationAdministrator = personRecord[field] == null ? false : personRecord[field] == 1;
													break;
												case "Rockstar":
													user.Rockstar = personRecord[field] == null ? false : personRecord[field] == 1;
													break;
												case "UseProductionDataOnTest":
													user.UseProductionDataOnTest = personRecord[field] == null ? false : personRecord[field] == 1;
													break;
												case "Active":
													user.Active = personRecord[field] == null ? false : personRecord[field] == 1;
													break;
												case "ReturnToLastVisitedRoute":
													user.ReturnToLastVisitedRoute = personRecord[field] == null ? false : personRecord[field] == 1;
													break;
												case "DarkTheme": 
													user.DarkTheme = personRecord[field] == null ? false : personRecord[field] == 1;
													break;
												default:
													user[field] = personRecord[field];
													break;
											}
										}
									}
									console.log(this.serviceName + "personRecord.DebugMode = " + personRecord.DebugMode);
									Global.User.DebugMode = true;
									user.Organization = {
										Id: personRecord["OrganizationId"],
										Name: personRecord["OrganizationName"],
										IsAdmin: user.IsOrganizationAdministrator,
										UsesAirportSites: personRecord["OrganizationUsesAirportSites"] == 1
									};
									user.OrganizationUsesAirportSites = personRecord["OrganizationUsesAirportSites"] == 1;
									Global.Application.Environment.Version = personRecord["EnvironmentVersion"];
								}
								break;
							case "ListOfUserRolesAndPrivileges":
								listOfUserRolesAndPrivileges = collection.data.first();
								break;
							case "AuthorizedSitesAndPrivileges":
								authorizedSitesAndPrivileges = collection.data;
								if (!authorizedSitesAndPrivileges) {
									this.authenticationService.resetApplicationToDefaultSettings();
									if (Global.isMobile) {
										this.router.navigate(["login"]);
									} else {
										this.router.navigate(["user/login"]);
									}
								}

								authorizedSitesAndPrivileges.forEach((row: any) => {
									if (row) {
										authorizedSitesAndPrivilegesObject[row.SiteId] = row;
									}
								});
								break;
							case "MainMenuPrivileges":
								mainMenuPrivileges = collection.data.first().UserPrivileges;
								break;
							case "UserFavorites":
								user.UserFavorites = collection.data;
								break;
							case "WidgetsPerSite":
								//console.log("collection.data = %O", collection.data);
								widgetsPerSite = collection.data;
								widgetsPerSite.forEach((row: any) => {
									if (row) {
										widgetsPerSiteObject[row.Id] = row;
									}
								});

								break;
							case "Dashboards":
								user.Dashboards = collection.data;
								user.DashboardsObject = {};
								collection.data.forEach((dashboard: any) => {
									user.DashboardsObject[dashboard.Id] = dashboard;
								});
								break;
							case "DashboardTimeScopes":
								user.DashboardTimeScopes = collection.data;
								break;
							case "TacticalDashboardUpdateIntervals":
								user.TacticalDashboardUpdateIntervals = collection.data;
								break;
							case "GeofencingZoneTypes":
								user.GeofencingZoneTypes = collection.data;
								break;
							case "CacheUtilityData":
								this.cacheUtilityService.returnedData = collection.data;
								break;
							case "JBTStandardObservationsLessThan16000":
								this.cacheUtilityService.jbtStandardObservationsLessThan16000 = collection.data;
								break;
							case "JBTStandardObservationsBetween16000And55000":
								this.cacheUtilityService.jbtStandardObservationsBetween16000And55000 = collection.data;
								break;
							case "JBTStandardObservationsGreaterThan55000":
								this.cacheUtilityService.jbtStandardObservationsGreaterThan55000 = collection.data;
								break;
							case "Assets":
								this.cacheUtilityService.assets = collection.data;
								break;
							case "AssetGraphics":
								this.cacheUtilityService.assetGraphics = collection.data;
								break;
							case "SystemsAndSystemGraphics":
								this.cacheUtilityService.systemsAndSystemGraphics = collection.data;
								break;
							case "GenericTacticalDashboardWidgets":
								user.GenericTacticalDashboardWidgets = collection.data;
								break;

							case "UpdateLastLoginDate":
								this.lastVisitedRouteData = collection.data;
								break;
						}
					});

					user.DashboardTimeZones = [
						{ Id: 1, Description: "User Time" },
						{ Id: 2, Description: "Site Time" },
						{ Id: 3, Description: "UTC Time" }
					]; //-- don't need to pull this from SQL Server when it's just two items that won't change. --Kirk T. Sherer, March 3, 2022.

					var timeToCreateUserObject = performance.now() - userTimer;
					console.log(this.serviceName + "time for user object to be set up = " + timeToCreateUserObject + " milliseconds. user object = %O", user);
					console.log(this.serviceName + "this.cacheUtilityService.returnedData = %O", this.cacheUtilityService.returnedData);
					this.signalRCore.LogActivity("User object set up in " + timeToCreateUserObject + " milliseconds.");

					if (!user.Username || !user.Active) {
						//-- invalid or inactive token.  Just redirect to login screen. --Kirk T. Sherer, February 26, 2021.
						this.signalRCore.LogActivity("User is not active. Redirecting to login screen.");

						this.authenticationService.resetApplicationToDefaultSettings();
						if (Global.isMobile) {
							this.router.navigate(["login"]);
						} else {
							this.router.navigate(["user/login"]);
						}
					} else {
						//-- build out user security object -- Have to do this to add in the user privileges and roles they are assigned per site. --Kirk T. Sherer, October 21, 2021.
						user.Security.AllowedDashboardTypeIds = widgetsPerSite
							.where((r: any) => {
								return +r.dtId != -1;
							})
							.select((rec: any) => {
								return +rec.dtId;
							})
							.distinct()
							.toArray();
						user.Security.Aggregate.Collections.WidgetTypeIds = widgetsPerSite
							.select((widgetTypes: any) => {
								return +widgetTypes.wtId;
							})
							.distinct()
							.toArray();
						user.Security.Aggregate.Collections.WidgetTypeNames = widgetsPerSite
							.select((widgetTypeNames: any) => {
								return widgetTypeNames.wtN;
							})
							.distinct()
							.toArray();
						user.Security.Aggregate.Collections.SiteIds = authorizedSitesAndPrivileges
							.select((row: any) => {
								return row.SiteId;
							})
							.distinct()
							.toArray();
						user.Security.SiteIdsGroupedByWidgetTypeId = widgetsPerSite
							.groupBy((widgets: any) => {
								return +widgets.wtId;
							})
							.toArray()
							.select((groupedByWidgets: any) => {
								var firstRecord = groupedByWidgets.first();
								var newRecord: any = {
									WidgetTypeId: +firstRecord.wtId,
									WidgetTypeName: firstRecord.wtN,
									DashboardTypeId: +firstRecord.dtId,
									SiteIdCollection: groupedByWidgets
										.select((rec: any) => {
											return +rec.sId;
										})
										.toArray()
								};
								return newRecord;
							})
							.toArray();
						user.Security.WidgetTypeIdsGroupedBySiteId = widgetsPerSite
							.groupBy((widgets: any) => {
								return +widgets.sId;
							})
							.toArray()
							.select((formattedWidgets: any) => {
								var firstRecord = formattedWidgets.first();
								var newRecord: any = {
									SiteId: +firstRecord.sId,
									SiteName: firstRecord.sN,
									Role: authorizedSitesAndPrivilegesObject[+firstRecord.sId] != null ? authorizedSitesAndPrivilegesObject[+firstRecord.sId].UserRoles : "Standard User", //-- if we don't have the authorizedSitesAndPrivileges object for this site, just give them standard user access. --Kirk T. Sherer, November 4, 2021.
									Privilege: authorizedSitesAndPrivilegesObject[+firstRecord.sId] != null ? authorizedSitesAndPrivilegesObject[+firstRecord.sId].UserPrivileges : "Read",
									WidgetTypeIdCollection: formattedWidgets
										.select((rec: any) => {
											return +rec.wtId;
										})
										.toArray()
								};
								return newRecord;
							})
							.toArray();

						var list: any = listOfUserRolesAndPrivileges;
						var userRolesArray = list.UserRoles.split(",");
						var userPrivsArray = list.UserPrivileges.split(",");
						var mainMenuPrivsArray = list.UserPrivileges.split(",");

						userRolesArray.forEach((role: string) => {
							listOfUserRoles[role.replace(/\s/g, "")] = false;
						});

						userPrivsArray.forEach((privilege: string) => {
							listOfUserPrivileges[privilege.replace(/\s/g, "")] = false;
						});

						mainMenuPrivsArray.forEach((privilege: string) => {
							listOfUserPrivileges[privilege.replace(/\s/g, "")] = false;
						});

						user.Privilege = {};
						user.Role = {};
						var isSiteAdministrator = false;
						authorizedSitesAndPrivileges.forEach((row: any) => {
							var newObject = {};
							var roles = {};
							var privs = {};
							var assignedUserRolesArray = row.UserRoles.split(",");
							var assignedUserPrivsArray = row.UserPrivileges.split(",");

							userRolesArray.forEach((role: string) => {
								roles[role.replace(/\s/g, "")] = false;
							});

							userRolesArray.forEach((role: string) => {
								assignedUserRolesArray.forEach((assigned: string) => {
									if (assigned == role) {
										roles[role.replace(/\s/g, "")] = true;
										if (role == "Administrator" && isSiteAdministrator == false) {
											isSiteAdministrator = true;
										}
									}
								});
							});

							userPrivsArray.forEach((priv: string) => {
								privs[priv.replace(/\s/g, "")] = false;
							});
							userPrivsArray.forEach((priv: string) => {
								assignedUserPrivsArray.forEach((assigned: string) => {
									if (assigned == priv) {
										privs[priv.replace(/\s/g, "")] = true;
									}
								});
							});

							user.Privilege[row.SiteName] = privs;
							user.Role[row.SiteName] = roles;
						});

						var mainMenuPrivilegesArray = mainMenuPrivileges.split(",");
						var privs = {};
						mainMenuPrivsArray.forEach((priv: string) => {
							mainMenuPrivilegesArray.forEach((assigned: string) => {
								if (assigned == priv) {
									privs[priv.replace(/\s/g, "")] = true;
								}
							});
						});

						user.Menu.Privileges = privs;

						localStorage.setItem("currentUser", JSON.stringify(user));
						console.log(this.serviceName + "this.currentUser = %O", this.currentUser);
						this.signalRCore.LogActivity("User roles and privileges defined.");

						var currentUser = localStorage.getItem("currentUser");
						Global.User.currentUser = <IUser>JSON.parse(currentUser);
						Global.User.currentUser.DateLoggedIn = user.DateLoggedInMS;
						Global.User.currentUser.DateLoggedInFormatted = moment(user.DateLoggedInMS).format("YYYY-MM-DD HH:mm:ss");
						Global.User.isAdmin = Global.User.currentUser.IsSystemAdministrator;
						Global.User.isOrgAdmin = Global.User.currentUser.IsOrganizationAdministrator && !Global.User.currentUser.IsSystemAdministrator;
						Global.User.isSiteAdmin = isSiteAdministrator && !Global.User.currentUser.IsSystemAdministrator && !Global.User.currentUser.IsOrganizationAdministrator;
						Global.User.Privilege = user.Privilege;
						Global.User.Role = user.Role;
						Global.User.Menu = user.Menu;

						console.log(this.serviceName + "Global.User.currentUser = %O", Global.User.currentUser);
						console.log(this.serviceName + "Global = %O", Global);

						Global.User.SidebarMini = user.SidebarMini;
						this.dataService.updateDebugMode(user.DebugMode);
						var localStorageUseProductionDataOnTest: string = localStorage.getItem("useProductionDataOnTest");
						var dbUseProductionDataOnTest: string = user.UseProductionDataOnTest == true ? "1" : "0";
						if (localStorageUseProductionDataOnTest != dbUseProductionDataOnTest) {
							//-- synching up the local storage value for UseProductionDataOnTest with the Database value for this user.
							//-- we're using the local storage version as the data source since it's what the user had set on their
							//-- browser / application. --Kirk T. Sherer, May 17, 2023.
							this.dataService.updateUseProductionDataOnTest(localStorageUseProductionDataOnTest == "1" ? true : false, true);
						}
						Global.User.currentUser.GeofencingZoneTypes = user.GeofencingZoneTypes;
						Global.User.currentUser.UserFavorites = user.UserFavorites;
						this.updateIsLoggedInUserStatus(true); //- need to set this here, after the user has been validated, before we allow SignalR to try to connect. --Kirk T. Sherer, November 29, 2023.
						this.dataService.currentUserChanged$.next(Global.User.currentUser);
						this.dataService.needToBuildMenu$.next(true);

						// Force Site Time when logged in as another user
						if (Global.User.isLoggedInAsDifferentUser == true) {
							Global.User.currentUser.Dashboards.forEach((dashboard) => {
								dashboard.TimeZoneId = 2;
							});
						}

						//-- Theme settings, and isMobile is needed to determine if the user has set their overall mobile device setting to 'dark mode' in the 'prefersDarkTheme' setting.
						if (!Global.isMobile) {
							//-- DESKTOP ONLY --
							if (!user.MenuColor && !user.DarkTheme) {
								Global.User.MenuColor = user.OrganizationUsesAirportSites ? Global.DefaultColorForDesktopVersion_AirportSiteUsers : Global.DefaultColorForDesktopVersion; //--default to the iOPS default color since the user hasn't set this yet. --Kirk T. Sherer, May 5, 2020.
								Global.Theme = user.OrganizationUsesAirportSites ? Global.DefaultThemeForApplication_AirportSiteUsers : Global.DefaultThemeForApplication;
							}
							else {
								Global.User.MenuColor = user.MenuColor;
							} 
							if (user.MenuColor?.indexOf("dark") > -1 ) {
								Global.Theme = "dark";
							}
							else 
							{
								Global.Theme = "light";
							}

						} else {
							//-- MOBILE ONLY -- NOTE: Mobile doesn't have individual colors.  It's either dark theme or light theme, and if the user's mobile device has a default light or dark theme, then that's what mobile will be using. --Kirk T. Sherer, July 5, 2024. 
							if (!user.MobileOnlyDarkTheme) {
								Global.Theme = "light";
							} else {
								Global.Theme = "dark";
							}
							if (Global.User.prefersDarkTheme && Global.Theme == "light") {
								Global.Theme = "dark";
								this.dataService.setTheme(Global.Theme, true); //-- user has a mobile device that is set to Dark Mode on the device itself, so set the mobile theme to dark and update the setting in User Settings. --Kirk T. Sherer, November 25, 2020.
							} else {
								this.dataService.setTheme(Global.Theme);
							}
						}

						this.dataService.initializeSiteWithUserPreferences();
						
						this.zone.runOutsideAngular(() => {
							Global.User.DebugMode && console.log(this.serviceName + "initializing cache utility service...");
							this.cacheUtilityService.initializeCacheUtilityService();

							this.joinSignalRGroups().subscribe(
								(data) => {
									Global.User.DebugMode && console.log(this.serviceName + "Global.SignalR.joinedSignalRGroups: " + data);
									this.signalRCore.LogActivity("Joined SignalR Groups.");
								},
								(err) => console.log(`${err}`)
							);
						});

						// --next, navigate to first view, in this case 'dashboard'.
						console.log(this.serviceName + "Global.User.currentUser.Dashboards = %O", Global.User.currentUser.Dashboards);
						console.log(this.serviceName + "Global.User.currentUser.DashboardsObject = %O", Global.User.currentUser.DashboardsObject);
						this.dataService.currentUserInitialLoadIsFinished$.next(true);
						var totalTime = performance.now() - time0;
						console.log(this.serviceName + "total time for user to be set up = " + totalTime + " milliseconds.");
						console.log(this.serviceName + "Global.User = %O", Global.User);
						this.signalRCore.LogActivity("Total time for user to be set up = " + totalTime + " milliseconds.");
						this.routeUserToStart(); //-- we didn't specify a dashboardId in the route, so just start at the user's first dashboard in their list. --Kirk T. Sherer, November 3, 2020.
					}
				},
				(error: any) => {
					if (error.status == 403 || error.status == 500) {
						console.log(this.serviceName + "access token " + accessToken + " is invalid.  Redirecting to the login page.");
						this.signalRCore.LogActivity("Error with user account. Redirecting to login page.");

						this.authenticationService.resetApplicationToDefaultSettings();
						if (Global.isMobile) {
							this.router.navigate(["login"]);
						} else {
							this.router.navigate(["user/login"]);
						}
					} else {
						this.signalRCore.LogActivity("Unknown error in Multi Data Condensed. Reloading the application.");

						if (error.statusText == "Unknown Error") {
							//-- Just log the error to the console since I can't repeat this on my machine.  Once I can see the error, hopefully I can fix it. --Kirk T. Sherer, September 12, 2022.
							console.log(this.serviceName + "Unknown Error in MultiDataCondensed = %O", error);
						}

						Swal.fire({
							title: "Error connecting to Database",
							text: "Your connection was unsucessful connecting to the database for iOPS.  Please reload the application to re-connect to the database. If your problem persists, please contact your iOPS Administrator.",
							showCancelButton: false,
							confirmButtonText: "Reload",
							reverseButtons: false
						}).then(() => {
							window.location.reload();
							// this.authenticationService.logout();
							// this.router.navigate(['user/login']);
						});
					}
				}
			);
		} catch (error) {
			console.error(this.serviceName + "Error with SQLMultiAction: %O", error);
			this.signalRCore.LogActivity("Unknown error in Multi Action. Redirecting to login page.");
			this.authenticationService.resetApplicationToDefaultSettings();
			if (Global.isMobile) {
				this.router.navigate(["login"]);
			} else {
				this.router.navigate(["user/login"]);
			}
		}
	}

	joinSignalRGroups(reconnect?: boolean) {
		var joinSignalRGroups$ = new Observable((subscriber) => {
			let currentUser = Global.User.currentUser;
			//console.log("currentUser = %O", currentUser);
			if (!currentUser) {
				currentUser = JSON.parse(localStorage.getItem("currentUser")) as IUser;
				Global.User.currentUser = currentUser;
			}

			this.signalRCore.LogActivity("Joining SignalR Groups...");
			console.log("<--- Joining SignalR Groups --->");
			
			this.signalRCore.joinGroups();
			subscriber.next(Global.SignalR.joinedSignalRGroups);
			subscriber.complete();
		});

		return joinSignalRGroups$;
	}

	routeUserToStart() {
		var dashboardsData = Global.User.currentUser.Dashboards;
		this.signalRCore.LogActivity("Routing user to start...");

		//-- before routing to the first dashboard, check to see if this user should see the release notes.  If the user has set
		//-- the ViewReleaseNotesAtLogin to 'false' in their user record, then skip this step.  Otherwise, display the release notes
		//-- in a dialog window. --Kirk T. Sherer, November 3, 2021.
		console.log("Global.User.currentUser.ViewReleaseNotesAtLogin = " + Global.User.currentUser.ViewReleaseNotesAtLogin);

		var lastVisitedRoute: string = this.lastVisitedRouteData?.first().LastVisitedRoute;
		if (lastVisitedRoute == "/user/logout" || lastVisitedRoute == "/logout" || lastVisitedRoute == "/layout/user-settings") {
			lastVisitedRoute = null;
		}

		var global: IGlobal = Global;
		var currentRoute: string = global.Initial.route;

		if (currentRoute == "/user/logout" || currentRoute == "/logout" || currentRoute == "/layout/user-settings") {
			currentRoute = null;
		}

		if (currentRoute && currentRoute != lastVisitedRoute && currentRoute != "/" && currentRoute != "/user/authentication" && currentRoute != "/authentication" && !Global.isMobile) {
			//-- if the user bookmarked or typed in a route and it's not equal to the last visited route, then we're going there. --Kirk T. Sherer, November 17, 2023.
			Global.User.isBeingAuthenticated = false;
			this.router
				.navigate([currentRoute])
				.then((data: boolean) => {
					if (!data) {
						//-- User can't route to the typed-in or bookmarked route (i.e. promise returned 'false' condition), so route the user to the last visited route.
						this.normalRoutingForUserWithoutBookmarkedRoute(lastVisitedRoute, dashboardsData);
					} else {
						//-- typed-in or bookmarked route was successful.
						console.log(this.serviceName + currentRoute + " was successful. Routing is finished.");
					}
				})
				.catch((e) => {
					//-- Error in routing to the current typed-in or bookmarked route, so route the user to the last visited route.
					this.normalRoutingForUserWithoutBookmarkedRoute(lastVisitedRoute, dashboardsData);
				});
		} else if (Global.User.currentUser.StopSeeingReleaseNotes && Global.User.currentUser.ViewReleaseNotesAtLogin) {
			console.log(this.serviceName + "routing user to release notes.");
			Global.User.isBeingAuthenticated = false;
			this.router
				.navigate(["layout/releaseNotes"])
				.then((data: boolean) => {
					if (!data) {
						//-- weren't able to route to the release notes, so go to the last visited route.
						this.normalRoutingForUserWithoutBookmarkedRoute(lastVisitedRoute, dashboardsData);
					} else {
						//-- routing to the release notes was successful.
						console.log(this.serviceName + "layout/releaseNotes route was successful. Routing is finished.");
					}
				})
				.catch((e) => {
					//-- Error in routing to the release notes, so route the user to the last visited route.
					this.normalRoutingForUserWithoutBookmarkedRoute(lastVisitedRoute, dashboardsData);
				});
		} else {
			//--User doesn't care to see the release notes, so route them to their last visited route.
			this.normalRoutingForUserWithoutBookmarkedRoute(lastVisitedRoute, dashboardsData);
		}
	}

	normalRoutingForUserWithoutBookmarkedRoute(lastVisitedRoute: string, data: any) {
		this.signalRCore.LogActivity("Normal routing for user without bookmarked route...");

		const firstDashboard: any = data.find((d: any) => {
			return d.IsMobile == Global.isMobile;
		});

		if (!Global.User.currentUser.ReturnToLastVisitedRoute || !lastVisitedRoute) {
			if (data.length > 0) {
				this.routeUserToFirstDashboard(firstDashboard);
			} else {
				var homeRoute = "layout/dashboard-demo";
				this.utilityService.updateCurrentMenuItem("Demo Dashboard");
				this.utilityService.homeRoute$.next(homeRoute); //--homeRoute$ is used by the tabs on the bottom of the mobile application. --Kirk T. Sherer, March 27, 2024.
				Global.User.isBeingAuthenticated = false;
				console.log(this.serviceName + "routing user to demo dashboard.");
				this.router.navigate(["layout/dashboard-demo"]); // -- go to demo dashboard since user doesn't have any dashboards yet. --Kirk T. Sherer, February 4, 2020.
			}
		} else {
			var lastVisitedRouteTitle: string = this.lastVisitedRouteData?.first().LastVisitedRouteTitle;
			if (!lastVisitedRouteTitle && lastVisitedRoute.indexOf("/") > -1) {
				lastVisitedRouteTitle = lastVisitedRoute.split("/")?.last(); //-- use the last portion of the route for the title since we don't currently have the last visited route's title. --Kirk T. Sherer, November 27, 2023.
			}
			this.utilityService.updateCurrentMenuItem(lastVisitedRouteTitle);
			var mobileVersionLastVisitedRoute = lastVisitedRoute.substring(0, 1) == "/" ? lastVisitedRoute.substring(1, lastVisitedRoute.length) : lastVisitedRoute; //-- removes the leading slash from the saved route.

			this.utilityService.homeRoute$.next(mobileVersionLastVisitedRoute); //--homeRoute$ is used by the tabs on the bottom of the mobile application. --Kirk T. Sherer, March 27, 2024.
			Global.User.isBeingAuthenticated = false;
			console.log(this.serviceName + "routing user to last visited route: " + lastVisitedRoute);
			this.router
				.navigate([lastVisitedRoute])
				.then((data: boolean) => {
					if (!data) {
						//-- weren't able to route to the user's last visited route, so go to the first dashboard.
						this.routeUserToFirstDashboard(firstDashboard);
					} else {
						//-- routing to the user's last visited route was successful.
						console.log(this.serviceName + lastVisitedRoute + " route was successful. Routing is finished.");
					}
				})
				.catch((e) => {
					//-- Error in routing to the user's last visited route, so go to the first dashboard.
					this.routeUserToFirstDashboard(firstDashboard);
				});
		}
	}

	routeUserToFirstDashboard(firstDashboard: any) {
		this.signalRCore.LogActivity("Routing user to first dashboard...");
		if (firstDashboard !== undefined) {
			const firstDashboardId: number = firstDashboard.Id;
			var homeRoute = "layout/dashboard/" + firstDashboardId;
			this.utilityService.updateCurrentMenuItem(firstDashboard.Name);
			this.utilityService.homeRoute$.next(homeRoute); //--homeRoute$ is used by the tabs on the bottom of the mobile application. --Kirk T. Sherer, March 27, 2024.
			Global.User.isBeingAuthenticated = false;
			console.log(this.serviceName + "routing user to first dashboard.");
			this.router
				.navigate(["layout/dashboard/" + firstDashboardId])
				.then((data: boolean) => {
					if (!data) {
						//-- weren't able to route to the user's first dashboard, so go to the demo dashboard route.
						this.router.navigate(["layout/dashboard-demo"]);
					} else {
						//-- routing to the user's first dashboard was successful.
						console.log(this.serviceName + "Routing to the user's first dashboard was successful. Routing is finished.");
					}
				})
				.catch((e) => {
					//-- Error in routing to the user's first dashboard, so go to the demo dashboard route.
					this.router.navigate(["layout/dashboard-demo"]);
				});
		} else {
			var homeRoute = "layout/dashboard-demo";
			this.utilityService.updateCurrentMenuItem("Demo Dashboard");
			this.utilityService.homeRoute$.next(homeRoute); //--homeRoute$ is used by the tabs on the bottom of the mobile application. --Kirk T. Sherer, March 27, 2024.
			Global.User.isBeingAuthenticated = false;
			console.log(this.serviceName + "routing user to demo dashboard.");
			this.router.navigate(["layout/dashboard-demo"]); // -- go to demo dashboard since user doesn't have any dashboards yet. --Kirk T. Sherer, February 4, 2020.
		}
	}

	async buildDashboardsAndDashboardObjectForLoggedInUser(dashboardId?: string) {
		this.signalRCore.LogActivity("build dashboards and dashboard object for user...");

		var dashboards = Global.User.currentUser.Dashboards;
		console.log(this.serviceName + "dashboards for user = %O", dashboards);
		if (dashboards) {
			Global.User.currentUser.DashboardsObject = {};
			dashboards.forEach((dashboard: any) => {
				Global.User.currentUser.DashboardsObject[dashboard.Id] = dashboard;
			});
		}
		console.log(this.serviceName + "Routing to dashboard since logging in with ODataAccessToken was valid...");
		console.log(this.serviceName + "Global.User.currentUser = %O", Global.User.currentUser);
		return dashboardId;
	}

	openSnackBarMessage(messageObject: any) {
		this.signalRCore.LogActivity("User opened snack bar message...");

		this.snackBar.openFromComponent(
			SnackbarBroadcastMessageComponent,

			{
				data: messageObject,
				verticalPosition: "top",
				horizontalPosition: "center"
			}
		);
	}
}
