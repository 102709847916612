import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { IUser } from "../_models/user.model";
import { Router } from "@angular/router";
import { Global } from "../_constants/global.variables";
import { DataService } from "./data.service";
import { lastValueFrom } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class FileUploadService {
	private componentName: string = "file-upload.service";

	currentUserJSONObject: any;
	private httpHeaders: HttpHeaders;

	constructor(
		private http: HttpClient,
		private router: Router,
		private dataService: DataService
	) {
		var currentUser = localStorage.getItem("currentUser");

		if (!currentUser || !Global.User.isLoggedIn) {
			Global.User.DebugMode &&
				console.log(
					"rerouting to authenticate user since this current user doesn't exist..."
				);
			this.router.navigate(["authentication"]);
		} else {
			this.currentUserJSONObject = <IUser>(
				JSON.parse(localStorage.getItem("currentUser"))
			);

			this.httpHeaders = new HttpHeaders({
				Authorization: this.currentUserJSONObject.ODataAccessToken,
			});
		}
	}

	public upload(formData: FormData) {

		Global.User.DebugMode &&
			console.log("this.dataService.fileUploadUrl: " + this.dataService.fileUploadUrl);
		Global.User.DebugMode &&
			console.log("FileUploadService.upload: formData = %O", formData);
		return this.http.post<any>(this.dataService.fileUploadUrl, formData, {
			headers: this.httpHeaders,
			observe: "events",
			reportProgress: true,
		});
	}

	public list(list: string) {
		Global.User.DebugMode &&
			console.log("FileUploadService.list: list = " + list);
		return this.http.post<any>(this.dataService.fileUploadListUrl, list, {
			headers: this.httpHeaders,
			observe: "events",
			reportProgress: true,
		});
	}

	public async listAsPromise(list: string) {
		const httpList = this.http.post<any>(
			this.dataService.fileUploadListUrl,
			list,
			{
				headers: this.httpHeaders,
				observe: "events",
				reportProgress: true,
			}
		);
		var listResponse = await lastValueFrom(httpList);
		if (listResponse != null) {
			return listResponse;
		} else {
			return null;
		}
	}

	public buildFileUploadObject(assetId: number, widgetObject: any): any {
		var service: any = this;
		var entityId = assetId;
		var entityType: string = "Asset";
		var siteName = widgetObject.siteName ? widgetObject.siteName : "";
		var entityName = widgetObject.gateSystemName
			? " Gate " + widgetObject.gateSystemName
			: widgetObject.gseName;
		var assetType = widgetObject.gateSystemName
			? " " + widgetObject.widgetNameDisplay.split(" ")[0]
			: "";

		var title =
			siteName +
			(entityName != null ? entityName : "") +
			assetType +
			" Documentation";

		var sqlStatement =
			"API.FileImageManagement @Type='List', @EntityType='" + entityType + "', @EntityId=" + entityId + ", @UserId=" + Global.User.currentUser.Id;
		return this.dataService
			.SQLActionAsPromise(sqlStatement)
			.then((data: any) => {
				Global.User.DebugMode &&
					console.log(
						this.componentName + ": data returned = %O",
						data
					);
				var fileImageData = data;
				var fileImageLibraryControlObject = {
					imageKeys: fileImageData.filter((i: any) => {
						return i.ImageKey;
					}),
					//---B
					removeUploadFunction(deletedImageKey: string) {
						service.dataService.SQLActionAsPromise("API.FileImageManagement @Type='Delete', @EntityType='" + entityType + "', @EntityId=" + entityId + ", @ImageKey='" + deletedImageKey + "', @UserId=" + Global.User.currentUser.Id).then((deletedEntity: any) => {
							service.fileImageData.splice(deletedEntity);
						});
					},
					//---B
					addUploadFunction(newImageKey: string) {
						service.dataService.SQLActionAsPromise("API.FileImageManagement @Type='Add', @EntityType='" + entityType + "', @EntityId=" + entityId + ", @ImageKey='" + newImageKey + "', @UserId=" + Global.User.currentUser.Id).then((newEntity: any) => {
							fileImageData.push(newEntity);
						});
					},
					mode: null,
					listTitle: title,
					entityId: entityId,
					entityType: entityType,
					countOfFilesUploaded: fileImageData.length,
					closeUploadWindowFunction() {
						service.FileImageLibraryControlObject = null;
					}
				};

				return fileImageLibraryControlObject;
			});
	}
}
