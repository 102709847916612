import { Injectable, NgZone } from "@angular/core";
import { IndexedDBService } from "./indexedDB.service";
import { UtilityService } from "./utility.service";
import { Observer, Observable, BehaviorSubject, Subject, of } from "rxjs";
import { catchError, filter, map } from "rxjs/operators";
import { DataService } from "./data.service";
import { SignalRCoreService } from "./signalr-core.service";

import { HttpHeaders, HttpClient } from "@angular/common/http";
import { IUser } from "../_models/user.model";
import { Global } from "../_constants/global.variables";
import { ISite } from "../_models/site.model";
import { IAsset } from "../_models/asset.model";
import { ISystem } from "../_models/system.model";
import { IStandardObservationSeverityLevel } from "../_models/standardObservationSeverityLevel.model";
import { IStandardObservation } from "../_models/standardObservation.model";
import { ICamera } from "../_models/camera.model";
import { DomSanitizer } from "@angular/platform-browser";
import { IUserIssue } from "../_models/user-issue.model";
import { IGlobal } from "../_models/global.model";
import { IAssetTypeGroup } from "../_models/asset-type-group.model";
import { IAssetType } from "../_models/asset-type.model";
import { ISQLServerSubject } from "../_models/sql-server-subject.model";
import { IncomingDataService } from "./incoming-data.service";
import { ISabelData } from "../_models/sabel-data.model";

@Injectable()
export class CacheUtilityService {
	public cache: any;
	private indexedDBCollections: any;
	private localDB: any;
	private indexedDB: any;
	private service: any;
	private observer: Observer<any>;
	private iopsSystemTables: any;
	private httpHeaders: HttpHeaders;
	public currentUserJSONObject: IUser;
	public returnedData: any;
	public jbtStandardObservationData: any;
	public assetGraphics: any;
	public assets: any;
	public systemsAndSystemGraphics: any;
	public jbtStandardObservationsLessThan16000: any;
	public jbtStandardObservationsBetween16000And55000: any;
	public jbtStandardObservationsGreaterThan55000: any;
	public global: IGlobal = Global;
	public serviceName: string = "cache-utility: ";
	public sqlEntitySubjects: Array<ISQLServerSubject> = [];

	constructor(private dataService: DataService, 
				private indexedDBService: IndexedDBService,  
				private utilityService: UtilityService, 
				private signalRCore: SignalRCoreService, 
				private sanitizer: DomSanitizer, 
				private http: HttpClient,
				private incomingDataService: IncomingDataService,
				private zone: NgZone
			) {
		this.service = this;
		this.localDB = this.localDB;
		var currentUser = localStorage.getItem("currentUser");
		this.currentUserJSONObject = <IUser>JSON.parse(localStorage.getItem("currentUser"));
		setInterval(() => {
			this.harvestIncomingSQLData();
		},100);
	}

	public harvestIncomingSQLData() {
		var service = this;
		service.zone.runOutsideAngular(() => {
			while (service.incomingDataService.incomingSQLDataArray.length > 0) {
				var signalRMessage = service.incomingDataService.incomingSQLDataArray.pop();
				//console.log(service.serviceName + "SQL Server signalRMessage = %O", signalRMessage);
				service.ProcessIncomingSQLSignalRMessages(signalRMessage);
			}
		});
	}

	initializeCacheUtilityService() {
		//Global.User.Global.User.DebugMode && console.log && console.log(this.serviceName + "Initializing CacheUtilityService...");
		this.service = this;
		this.cache = {
			calculationOperatorTypes: [],
			calculationOperatorTypesObject: {},
			calculationRuleOperations: [],
			calculationRuleOperationsObject: {},
			calculationRules: [],
			calculationRulesObject: {},
			calculationTypes: [],
			calculationTypesObject: {},
			genericData: [],
			genericDataObject: {},
			jbtStandardObservations: [],
			jbtStandardObservationsObject: {},
			jbtStandardObservationValueTranslations: [],
			jbtStandardObservationValueTranslationsObject: {},
			jbtStandardObservationSeverityLevels: [],
			jbtStandardObservationSeverityLevelsObject: {},
			organizations: [],
			organizationsObject: {},
			organizationSites: [],
			organizationSitesObject: {},
			organizationSiteSuites: [],
			organizationSiteSuitesObject: {},
			sites: [],
			sitesObject: {},
			systems: [],
			systemsObject: {},
			systemTypes: [],
			systemTypesObject: {},
			recipes: [],
			recipesObject: {},
			recipeFrequencyTypes: [],
			recipeFrequencyTypesObject: {},
			recipeConditionals: [],
			recipeConditionalsObject: {},
			recipeConditionalEvaluationTypes: [],
			recipeConditionalEvaluationTypesObject: {},
			recipeConditionalWatchTypes: [],
			recipeConditionalWatchTypesObject: {},
			recipeConstants: [],
			recipeConstantsObject: {},
			assets: [],
			assetsObject: {},
			assetTypes: [],
			assetTypesObject: {},
			assetTypeGroups: [],
			assetTypeGroupsObject: {},
			tags: [],
			tagsObject: {},
			assetGraphics: [],
			assetGraphicsObject: {},
			assetGraphicVisibleValues: [],
			assetGraphicVisibleValuesObject: {},
			assetMaintenanceModeReasons: [],
			assetMaintenanceModeReasonsObject: {},
			assetOutOfServiceReasons: [],
			assetOutOfServiceReasonsObject: {},
			bhsJamAlarms: [],
			bhsJamAlarmsObject: {},
			cameras: [],
			camerasObject: {},
			dashboards: [],
			dashboardsObject: {},
			suites: [],
			suitesObject: {},
			suiteModules: [],
			suiteModulesObject: {},
			modules: [],
			modulesObject: {},
			widgetTypes: [],
			widgetTypesObject: {},
			widgetTypeTabGroups: [],
			widgetTypeTabGroupsObject: {},
			canvasWidgetTypes: [],
			canvasWidgetTypesObject: {},
			moduleWidgetTypes: [],
			moduleWidgetTypesObject: {},
			canvasWidgetTagRules: [],
			canvasWidgetTagRulesObject: {},
			plcPrograms: [],
			plcProgramsObject: {},
			plcProgramRevisions: [],
			plcProgramRevisionsObject: {},
			iopsSystemTables: [],
			iopsSystemTablesObject: {},
			users: [],
			usersObject: {},
			userIssues: [],
			userIssuesObject: {},
			sabelData: [],
			sabelDataObject: {},
			systemGraphics: [],
			systemGraphicsObject: {},
			systemGraphicVisibleValues: [],
			systemGraphicVisibleValuesObject: {},
			trainingCourses: [],
			trainingCoursesObject: {},
			trainingCourseLessons: [],
			trainingCourseLessonsObject: {},
			fleets: [],
			fleetsObject: {},
			userNotes: [{ Id: 1, Title: "No Note", Description: "Test", CreatedBy: "System", CreationDate: new Date(), LastModifiedBy: "System", LastModifiedDate: new Date(), EntityId: 963933, EntityLogTypeId: 1  }, { Id: 1, Title: "No Note", Description: "Test", CreatedBy: "System", CreationDate: new Date(), LastModifiedBy: "System", LastModifiedDate: new Date(), EntityId: 3150796, EntityLogTypeId: 1  }],
			ready: false,
			updated: false
		};

		this.indexedDBCollections = [];

		this.Init();
	}

	GetCachedIOPSCollectionObservable(options) {
		var service = this.service;

		var getCachedIOPSCollectionObservable$: any = new Observable((subscriber) => {
			//options.debug = true;
			if (options.debug) {
				//debugger;
				Global.User.DebugMode && console.log(this.serviceName + "Cache for '" + options.cachedName + "' called");
			}

			service.cache[options.cachedName] = [];
			service.cache[options.cachedName + "Object"] = {};

			var maxDate;
			var maxDateUTCMilliseconds;

			var t0 = performance.now();

			//Global.User.DebugMode && console.log(this.serviceName + "GetCachedIOPSCollectionPromise - options = %O", options);
			var indexedDBData: any = this.cache[options.cachedName] || [];

			if (options.debug) {
				Global.User.DebugMode && console.log(this.serviceName + "indexedDBData returned from indexedDB for " + options.cachedName + " = %O", indexedDBData);
			}

			//debugger;

			//---G
			//+Get All of the entities that have changed since the localDB was collected - or load all if the parameter is specified.
			//---G
			//+by LastModifiedDateUTCMilliseconds
			if (options.useLastModifiedDateUTCMilliseconds) {
				//options.debug = true;

				if (indexedDBData.length > 0) {
					maxDateUTCMilliseconds = indexedDBData.max(function (e) {
						return +(e.LastModifiedDateUTCMilliseconds || 0);
					});
				} else {
					maxDateUTCMilliseconds = 0;
				}
				if (options.debug) {
					Global.User.DebugMode && console.log(this.serviceName + options.cachedName + " maxDateUTCMilliseconds = %O", maxDateUTCMilliseconds);
				}

				service.dataService.GetResource(options.odataCollectionName, maxDateUTCMilliseconds).subscribe(
					(data) => {
						if (options.debug) {
							Global.User.DebugMode && console.log(this.serviceName + "getCachedIOPSCollectionObservable$: " + options.cachedName + " = %O", data);
						}
						this.updateDataCacheForSpecificCollection(options, data);
						subscriber.next(data);
					},
					(err) => Global.User.DebugMode && console.log(this.serviceName + `${err}`),
					() => {
						subscriber.complete();
					}
				);
			} else {
				//+by LastModifiedDate otherwise
				if (indexedDBData.length > 0) {
					maxDate = Date.parse(
						indexedDBData.max(function (e) {
							return e.LastModifiedDate;
						})
					);
				} else {
					maxDate = new Date("1/1/2017");
				}

				if (options.debug) {
					Global.User.DebugMode && console.log(this.serviceName + options.cachedName + " maxDate = %O", maxDate);
				}

				var maxDateInMilliseconds: number = Date.parse(maxDate);

				if (options.debug) {
					Global.User.DebugMode && console.log(this.serviceName + "OData Query Date for " + options.cachedName + " maxdate = %O", maxDate);
				}

				if (options.cachedName == "Sites") {
					maxDateInMilliseconds = 0;
				}

				//Get only changed entities from the odata source

				service.dataService.GetResource(options.odataCollectionName, maxDateInMilliseconds).subscribe(
					(data) => {
						if (options.debug) {
							Global.User.DebugMode && console.log(this.serviceName + "getCachedIOPSCollectionObservable$: " + options.cachedName + " = %O", data);
						}
						this.updateDataCacheForSpecificCollection(options, data);
						subscriber.next(data);
					},
					(err) => Global.User.DebugMode && console.log(this.serviceName + `${err}`),
					() => {
						subscriber.complete();
					}
				);
			}
		});

		return getCachedIOPSCollectionObservable$;
	}

	updateDataCacheForSpecificCollection(options: any, data: any) {
		var service = this.service;
		var indexedDBData: any = service.indexedDBCollections[options.cachedName] || [];

		if (options.cachedName == "organizations") {
			console.log(this.serviceName + "Organizations being updated...");
		}
		service.cache[options.cachedName] = [];
		service.cache[options.cachedName + "Object"] = {};

		if (options.debug) {
			Global.User.DebugMode && console.log(this.serviceName + "updateDataCacheForSpecificCollection: options.cachedName = " + options.cachedName + ", data = %O", data);
		}

		if (options.debug) {
			Global.User.DebugMode && console.log(this.serviceName + "updateDataCacheForSpecificCollection: " + options.cachedName + " = " + data.count());
		}

		var combinedData: any = data
			.concat(indexedDBData)
			.toArray()
			.filter((thing: any, i: any, arr: any) => arr.findIndex((t) => t.Id === thing.Id) === i);

		if (options.debug) {
			Global.User.DebugMode && console.log(this.serviceName + "updateDataCacheForSpecificCollection: combined " + options.cachedName + " = " + combinedData.count());
		}

		service.indexedDBCollections[options.cachedName] = combinedData.toArray();

		if (options.debug) {
			Global.User.DebugMode && console.log(this.serviceName + "updateDataCacheForSpecificCollection: service.indexedDBCollections[" + options.cachedName + "] = %O", service.indexedDBCollections[options.cachedName]);
		}

		if (combinedData) {
			if (options.debug) {
				Global.User.DebugMode && console.log(this.serviceName + "updateDataCacheForSpecificCollection: combined " + options.cachedName + " data = %O", combinedData);
			}

			service.cache[options.cachedName] = combinedData.toArray();
			if (options.debug) {
				Global.User.DebugMode && console.log(this.serviceName + "updateDataCacheForSpecificCollection: combined " + options.cachedName + " data = %O", combinedData.toArray());
				Global.User.DebugMode && console.log(this.serviceName + "service.cache[" + options.cachedName + "] = %O", service.cache[options.cachedName]);
			}

			service.cache[options.cachedName].forEach((d) => {
				if (d.Id) {
					service.cache[options.cachedName + "Object"][d.Id.toString()] = d;
				} else {
					Global.User.DebugMode && console.log(this.serviceName + options.cachedName + " does not have an Id field...");
				}
			});
			if (options.debug) {
				Global.User.DebugMode && console.log(this.serviceName + "updateDataCacheForSpecificCollection: Cache for " + options.cachedName + " Populated");
				Global.User.DebugMode && console.log(this.serviceName + "updateDataCacheForSpecificCollection: service.cache[" + options.cachedName + "Object] = %O", service.cache[options.cachedName + "Object"]);
			}
		}
	}

	ExpandCachedTableData(cols: string, data: any, name: string) {
		//console.log(this.serviceName + "ExpandCachedTableData: name = " + name + ", cols = " + cols + ", data = %O", data);
		var collectionName = name;
		var colNumber = 0;
		if (name == "Assets") {
			console.log(this.serviceName + "name = 'Assets'");
		}
		try {
			var colData = cols.split(",").map((colSet: any) => {
				var colArray = colSet.split("_");
				return {
					type: colArray[0],
					name: colArray[1],
					position: colNumber++
				};
			});

			//console.log(this.serviceName + "data.split('`').where((item:any) => item != '' ).toArray() = %O", data.split("`").where((item:any) => item != "" ).toArray());

			var expandedData = [];
			if (data) {
				//console.log("<--- " + collectionName + " --->")
				var dataWithoutCarriageReturnLineFeeds = data.split("\r\n").join("");

				expandedData = dataWithoutCarriageReturnLineFeeds
					.split("`")
					.where((item: any) => item != "")
					.toArray()
					.select((row: any) => {
						var rowDataArray = row.split("~");
						var rowObject = {};
						try {
							colData.forEach((col) => {
								switch (col.type) {
									case "S":
										rowObject[col.name] = rowDataArray[col.position] == "" ? null : rowDataArray[col.position];
										break;
									case "N":
										rowObject[col.name] = +rowDataArray[col.position];
										break;
									case "D":
										rowObject[col.name] = +rowDataArray[col.position] < 0 ? null : new Date(+rowDataArray[col.position]);
										rowObject[col.name + "MS"] = +rowDataArray[col.position] < 0 ? null : +rowDataArray[col.position];
										break;
									case "B":
										rowObject[col.name] = rowDataArray[col.position] == "True" || rowDataArray[col.position] == "true" || +rowDataArray[col.position] == 1 ? true : false;
										break;
								}
							});
							return rowObject;
						} catch (e) {
							console.error("problem with parsing data from SQL Server...");
						}
					})
					.toArray();
			}

			return expandedData;
		} catch (error) {
			console.error(`Error in ExpandCachedTableData for ${name}: ${error.message}`);
		}
	}

	Init = function () {
		var service = this.service;
		var thisObject = this;
		service.time0 = performance.now();

		Global.User.DebugMode && console.log(this.serviceName + "cache-utility-service - service.Init called ");
		Global.User.DebugMode && console.log(this.serviceName + "CachedTableData requested " + (performance.now() - service.time0));
		if (!this.currentUserJSONObject) {
			this.currentUserJSONObject = <IUser>JSON.parse(localStorage.getItem("currentUser"));
		}
		var accessToken = this.currentUserJSONObject.ODataAccessToken && Global.User.currentUser?.ODataAccessToken;
		Global.User.DebugMode && console.log(this.serviceName + "cache-utility-service - accessToken = " + accessToken);

		//-- building out cached data from returnedData - all but JBT Standard Observations, which will be separate. --Kirk T. Sherer, August 4, 2021.

		//-- NOTE: this.returnedData is coming from the Security Service call when the user is logged in.  It is coming from the API.GetUserCachedTableData_Without_Major_Collections stored procedure for this user.
		var returnedData = this.returnedData;

		var expandedData = returnedData.map((collection: any) => {
			return {
				name: collection.Name,
				cachedName: collection.CachedName,
				data: this.ExpandCachedTableData(collection.Cols, collection.Data, collection.Name)
			};
		});
		console.log(this.serviceName + "CachedTableData Expansion Time = " + (performance.now() - service.time0));
		expandedData.forEach((item: any) => {
			switch (item.name) {
				case "AssetTypeGroups":
					var assetTypeGroupsArray: Array<IAssetTypeGroup> = [];
					item.data &&
						item.data.forEach((row: any) => {
							if (row) {
								let newAssetTypeGroupRow: IAssetTypeGroup = {
									Id: row.Id,
									Name: row.Name
								};
								assetTypeGroupsArray.push(newAssetTypeGroupRow);
								this.cache[item.cachedName + "Object"][row.Id] = newAssetTypeGroupRow;
							}
						});
					this.cache[item.cachedName] = assetTypeGroupsArray;
					break;
				case "AssetTypes":
					var assetTypesArray: Array<IAssetType> = [];
					item.data &&
						item.data.forEach((row: any) => {
							if (row) {
								let newAssetTypeRow: IAssetType = {
									Id: row.Id,
									Name: row.Name,
									Description: row.Description,
									IsValidForWidgets: row.IsValidForWidgets != null ? row.IsValidForWidgets : false,
									AssetTypeGroupId: row.AssetTypeGroupId,
									AssetTypeGroup: row.AssetTypeGroupId != null ? this.cache.assetTypeGroupsObject[row.AssetTypeGroupId] : null
								};
								assetTypesArray.push(newAssetTypeRow);
								this.cache[item.cachedName + "Object"][row.Id] = newAssetTypeRow;
							}
						});
					this.cache[item.cachedName] = assetTypesArray;
					break;
				case "Cameras":
					var camerasArray: Array<ICamera> = [];
					item.data &&
						item.data.forEach((row: any) => {
							if (row) {
								let newCameraRow: ICamera = {
									AssignedSystemId: row.AssignedSystemId ?? null,
									AssignedSiteId: row.AssignedSiteId,
									Description: row.Description ?? null,
									Id: row.Id,
									Name: row.Name,
									Ordinal: row.Ordinal ?? 0,
									SelectorButtonLabel: row.SelectorButtonLabel,
									Site: null,
									System: null,
									trustedSourceURL: null
								};
								camerasArray.push(newCameraRow);
								this.cache[item.cachedName + "Object"][row.Id] = newCameraRow;
							}
						});
					this.cache[item.cachedName] = camerasArray;
					break;
				case "Sites":
					var sitesArray: Array<ISite> = [];
					item.data &&
						item.data.forEach((row: any) => {
							if (row) {
								let newSiteRow: ISite = {
									Active: row.Active,
									Assets: [],
									Cameras: this.cache?.cameras?.where((camera: ICamera) => { return camera.AssignedSiteId == row.Id; }).toArray(),
									Description: row.Description,
									GoogleLatitude: row.GoogleLatitude,
									GoogleLongitude: row.GoogleLongitude,
									HasGates: row.HasGates == null ? false : row.HasGates,
									Id: row.Id,
									JBTDistributionSite: row.JBTDistributionSite,
									SabelSite: row.SabelSite,
									Metadata: null,
									Name: row.Name,
									Mnemonic: row.Mnemonic,
									Systems: [],
									TimeZone: row.TimeZone,
									UTCTimeOffset: row.UTCTimeOffset
								};
								sitesArray.push(newSiteRow);
								this.cache[item.cachedName + "Object"][row.Id] = newSiteRow;
							}
						});
					this.cache[item.cachedName] = sitesArray;
					break;
				case "JBTStandardObservationSeverityLevels":
					var severityLevelsArray: Array<IStandardObservationSeverityLevel> = [];
					item.data &&
						item.data.forEach((row: any) => {
							if (row) {
								if (Global.User.currentUser.OrganizationUsesAirportSites == false) {
									//-- the current non-airport organizations do not want to see the normal 'Alarm' and 'Critical' names for Severity Level 1 and 3. --Kirk T. Sherer, August 26, 2024. 
									var severityLevelName = "";
									switch(row.Id) {
										case 1:
											severityLevelName = "Amber";
											break;
										case 3: 
											severityLevelName = "Red";
											break;
										default:
											severityLevelName = row.Name;
											break;
									}
									let newSeverityLevelRow: IStandardObservationSeverityLevel = {
										Active: row.Active,
										Description: row.Description,
										Id: row.Id,
										JBTStandardObservations: [],
										Name: severityLevelName,
										Ordinal: row.Ordinal
									};
									severityLevelsArray.push(newSeverityLevelRow);
									this.cache[item.cachedName + "Object"][row.Id] = newSeverityLevelRow;
								}
								else {
									//-- leave the Severity Level names the same.  The user's organization doesn't need to have them renamed. --Kirk T. Sherer, August 26, 2024. 
									let newSeverityLevelRow: IStandardObservationSeverityLevel = {
										Active: row.Active,
										Description: row.Description,
										Id: row.Id,
										JBTStandardObservations: [],
										Name: row.Name,
										Ordinal: row.Ordinal
									};
									severityLevelsArray.push(newSeverityLevelRow);
									this.cache[item.cachedName + "Object"][row.Id] = newSeverityLevelRow;
								}
							}
						});
					this.cache[item.cachedName] = severityLevelsArray;
					break;
				case "UserIssues":
					var userIssuesArray: Array<IUserIssue> = [];
					item.data &&
						item.data.forEach((row: any) => {
							if (row) {
								if (row.Id == 132) {
									console.log("row = %O", row);
								}
								let newUserIssueRow: IUserIssue = {
									AssignedDate: row.AssignedDate,
									AssignedDateMS: row.AssignedDateMS < 0 ? null : row.AssignedDateMS,
									AssignedToUserId: row.AssignedToUserId,
									AssignedTo: row.AssignedTo,
									CountOfFilesUploaded: row.CountOfFilesUploaded,
									CreatedBy: row.CreatedBy,
									CreationDate: row.CreationDate,
									CreationDateMS: row.CreationDateMS < 0 ? null : row.CreationDateMS,
									CreatorUserId: row.CreatorUserId,
									Deleted: row.Deleted,
									Description: row.Description != null ? service.stringToHTML(row.Description) : null,
									DiscussionCount: row.DiscussionCount,
									Email: row.Email,
									Id: row.Id,
									LastModifiedDate: row.LastModifiedDate,
									LastModifiedDateMS: row.LastModifiedDateMS < 0 ? null : row.LastModifiedDateMS,
									LastModifiedBy: row.LastModifiedBy,
									LastModifiedUserId: row.LastModifiedUserId,
									NotifyCreatorUser: row.NotifyCreatorUser,
									NotifyAssignedUser: row.NotifyAssignedUser,
									Organization: row.Organization,
									Phone: row.Phone,
									Resolution: row.Resolution != null ? service.stringToHTML(row.Resolution) : null,
									Resolved: row.Resolved,
									Subject: row.Subject,
									UserIssueSubjectId: row.UserIssueSubjectId
								};
								userIssuesArray.push(newUserIssueRow);
								this.cache[item.cachedName + "Object"][row.Id] = newUserIssueRow;
							}
						});
					this.cache[item.cachedName] = userIssuesArray;
					break;
				default:
					this.cache[item.cachedName] = item.data;
					this.cache[item.cachedName + "Object"] = {};
					item.data &&
						item.data.forEach((row: any) => {
							if (row) {
								this.cache[item.cachedName + "Object"][row.Id] = row;
							}
						});
					break;
			}
		});
		console.log(this.serviceName + "cached data without major collections = %O", expandedData);

		//-- building out cached data from jbtStandardObservations. --Kirk T. Sherer, August 4, 2021.

		//-- NOTE:  the following Standard Observation collections are coming from the Security Service calls API.GetUserCachedTableData_JBTStandardObservations_IDs_Less_Than_16000,
		//--		API.GetUserCachedTableData_JBTStandardObservations_IDs_Between_16000_And_55000, and API.GetUserCachedTableData_JBTStandardObservations_IDs_Greater_Than_55000.  These
		//--		had to be broken up into separate stored procedures rather than a single one because it was taking too long to return from SQL Server. --Kirk T. Sherer, May 5, 2023.

		var jbtStandardObservationData =
			this.jbtStandardObservationsLessThan16000
				.map((collection: any) => {
					return collection.Data;
				})
				.toString() +
			this.jbtStandardObservationsBetween16000And55000
				.map((collection: any) => {
					return collection.Data;
				})
				.toString() +
			this.jbtStandardObservationsGreaterThan55000
				.map((collection: any) => {
					return collection.Data;
				})
				.toString();
		var expandedJBTStandardObservationData = this.jbtStandardObservationsLessThan16000.map((collection: any) => {
			return {
				name: collection.Name,
				cachedName: collection.CachedName,
				data: this.ExpandCachedTableData(collection.Cols, jbtStandardObservationData, collection.Name)
			};
		});
		expandedJBTStandardObservationData.forEach((item: any) => {
			var standardObservationsArray: Array<IStandardObservation> = [];
			item.data &&
				item.data.forEach((row: any) => {
					if (row) {
						let newStandardObservationRow: IStandardObservation = {
							Id: row.Id,
							JBTStandardObservationSeverityLevel: this.cache.jbtStandardObservationSeverityLevelsObject[row.JBTStandardObservationSeverityLevelId],
							JBTStandardObservationSeverityLevelId: row.JBTStandardObservationSeverityLevelId,
							Name: row.Name,
							ValueWhenActive: row.ValueWhenActive == null ? "1" : row.ValueWhenActive
						};
						standardObservationsArray.push(newStandardObservationRow);
						this.cache.jbtStandardObservationSeverityLevelsObject[row.JBTStandardObservationSeverityLevelId]?.JBTStandardObservations?.push(newStandardObservationRow);
						this.cache[item.cachedName + "Object"][row.Id] = newStandardObservationRow;
					}
				});
			this.cache[item.cachedName] = standardObservationsArray;
		});
		console.log(this.serviceName + "this.cache.jbtStandardObservations = %O", this.cache.jbtStandardObservations);

		//-- building out cached data from systemsAndSystemGraphics - only Systems and System Graphics in this section. --Kirk T. Sherer, August 4, 2021.
		var systemsAndSystemGraphics = this.systemsAndSystemGraphics;
		var expandedSystemsAndSystemGraphics = systemsAndSystemGraphics.map((collection: any) => {
			return {
				name: collection.Name,
				cachedName: collection.CachedName,
				data: this.ExpandCachedTableData(collection.Cols, collection.Data, collection.Name)
			};
		});
		expandedSystemsAndSystemGraphics.forEach((item: any) => {
			if (item.name == "Systems") {
				var systemsArray: Array<ISystem> = [];
				item.data &&
					item.data.forEach((row: any) => {
						if (row) {
							let newSystemRow: ISystem = {
								Assets: [],
								BaseUnitGraphicImageKey: row.BaseUnitGraphicImageKey,
								BaseUnitImageURL: row.BaseUnitImageURL,
								Cameras: this.cache?.cameras
									?.where((camera: ICamera) => {
										return camera.AssignedSystemId == row.Id;
									})
									.toArray(),
								GPUPresent: row.GPUPresent == null ? false : row.GPUPresent,
								Id: row.Id,
								Name: row.Name,
								NextArrival:
									row.NextArrival != null
										? {
												UTC: row.NextArrival != null ? this.utilityService.convertMillisecondsToDateWithTimezoneOffset(row.NextArrival, 0) : null,
												Local: row.NextArrival != null ? new Date(row.NextArrival) : null,
												Site: row.NextArrival != null ? this.utilityService.convertMillisecondsToDateWithTimezoneOffset(row.NextArrival, service.cache.sitesObject[row.SiteId].UTCTimeOffset) : null
										  }
										: null,
								OwnerOrganizationId: row.OwnerOrganizationId,
								PBBPresent: row.PBBPresent == null ? false : row.PBBPresent,
								PCAPresent: row.PCAPresent == null ? false : row.PCAPresent,
								ParentSystem: null,
								ParentSystemId: row.ParentSystemId,
								PerfectTurn: {
									PBB: [],
									PCA: [],
									GPU: []
								},
								Site: service.cache.sitesObject[row.SiteId],
								SiteId: row.SiteId,
								Systems: [],
								Type: row.Type,
								TypeId: row.TypeId
							};
							systemsArray.push(newSystemRow);
							this.cache[item.cachedName + "Object"][row.Id] = newSystemRow;
						}
					});
				this.cache[item.cachedName] = systemsArray;
			} else {
				this.cache[item.cachedName] = item.data;
				this.cache[item.cachedName + "Object"] = {};
				item.data &&
					item.data.forEach((row: any) => {
						if (row) {
							this.cache[item.cachedName + "Object"][row.Id] = row;
						}
					});
			}
		});
		//console.log(this.serviceName + "expandedSystemsAndSystemGraphics = %O", expandedSystemsAndSystemGraphics);

		//-- building out cached data from assets - only Assets in this section. --Kirk T. Sherer, August 4, 2021.

		//-- this.assets comes from the result set of running the API.GetUserCachedTableData_Assets for the logged-in user.
		var assets = this.assets;
		var expandedAssets = assets.map((collection: any) => {
			return {
				name: collection.Name,
				cachedName: collection.CachedName,
				data: this.ExpandCachedTableData(collection.Cols, collection.Data, collection.Name)
			};
		});
		expandedAssets.forEach((item: any) => {
			var assetsArray: Array<IAsset> = [];
			item.data &&
				item.data.forEach((row: any) => {
					if (row) {
						let newAssetRow: IAsset = {
							Active: row.Active,
							AdveezAsset: null,
							AdveezAssetId: row.AdveezAssetId,
							AssetModelId: row.AssetModelId,
							AssetTypeId: row.AssetTypeId,
							AssetTypeName: row.AssetTypeName,
							AssetType: row.AssetTypeId != null ? this.cache.assetTypesObject[row.AssetTypeId] : null,
							BaseUnitImageURL: row.BaseUnitImageURL,
							DeicerEvents: null,
							Description: row.Description,
							HasElevatorRotunda: row.HasElevatorRotunda == null ? false : row.HasElevatorRotunda,
							ElevatorRotundaTags: [],
							OptionTags: [],
							ActiveAlarmTags: [],
							MovePreventionTags: [],
							GSEPeakReportTags: [],
							Id: row.Id,
							MaintenanceMode: row.MaintenanceMode == null ? false : row.MaintenanceMode,
							MaintenanceModeReasonId: row.MaintenanceModeReasonId,
							MaintenanceOrganizationId: row.MaintenanceOrganizationId,
							MaintenanceOrganizationName: row.MaintenanceOrganizationName,
							ManufacturerOrganizationId: row.ManufacturerOrganizationId,
							ManufacturerOrganizationName: row.ManufacturerOrganizationName,
							ModemNumber: row.ModemNumber,
							Name: row.Name,
							OriginalName: row.OriginalName,
							PreferredName: row.PreferredName,
							OperatorOrganizationId: row.OperatorOrganizationId,
							OperatorOrganizationName: row.OperatorOrganizationName,
							OutOfService: row.OutOfService == null ? false : row.OutOfService,
							OutOfServiceReasonId: row.OutOfServiceReasonId,
							OwnerOrganizationId: row.OwnerOrganizationId,
							OwnerOrganizationName: row.OwnerOrganizationName,
							PLCProgramId: row.PLCProgramId,
							PLCProgramRevisionId: row.PLCProgramRevisionId,
							ParentSystem: service.cache.systemsObject[row.ParentSystemId],
							ParentSystemId: row.ParentSystemId,
							SignalRGroup: row.SignalRGroup,
							Site: service.cache.sitesObject[row.SiteId],
							SiteId: row.SiteId,
							TagNamePrefix: row.TagNamePrefix,
							Tags: [],
							ThingId: row.ThingId
						};
						if (row.SiteId == 81463 && row.ParentSystemId == null) {
							console.log("BNA newAssetRow = %O", newAssetRow);
						}

						assetsArray.push(newAssetRow);
						this.cache.systemsObject[row.ParentSystemId]?.Assets?.push(newAssetRow);
						this.cache[item.cachedName + "Object"][row.Id] = newAssetRow;
					}
				});
			this.cache[item.cachedName] = assetsArray;
		});

		//-- join all SignalR groups for the user's list of accessible assets (for all system admins this will be all assets.)
		// var listOfTagNamePrefixes = this.cache.assets.where((asset: IAsset) => { return asset.TagNamePrefix != null })
		// 											 .select((asset: IAsset) => { return asset.TagNamePrefix; }).toArray();

		// Global.SignalR.ListOfTagNamePrefixes = listOfTagNamePrefixes.length > 0 ? listOfTagNamePrefixes.join(",") : null;
		//Global.User.DebugMode && console.log(this.serviceName + "Global.SignalR.ListOfTagNamePrefixes: " + Global.SignalR.ListOfTagNamePrefixes);												

		var missingParentSystem = this.cache.assets
			.where((asset: any) => {
				return asset.ParentSystem == undefined;
			})
			.toArray();

		service.cache.systems.forEach((system: ISystem) => {
			system.ParentSystem = service.cache.systemsObject[system.ParentSystemId];
		});

		service.cache.systems.sort((g1, g2) => this.utilityService.GetGateNameSortValue(g1.Name).localeCompare(this.utilityService.GetGateNameSortValue(g2.Name), "en", { numeric: true }));

		service.cache.cameras.forEach((camera: ICamera) => {
			(camera.Site = service.cache.sitesObject[camera.AssignedSiteId]), (camera.System = camera.AssignedSystemId != null ? service.cache.systemsObject[camera.AssignedSystemId] : null);
		});

		console.log("assets with missing parent system reference: %O", missingParentSystem);
		console.log("cameras with parent references defined: %O", service.cache.cameras);

		//console.log(this.serviceName + "expandedAssets = %O", expandedAssets);
		//-- building out cached data from assetGraphics - only Asset Graphics in this section. --Kirk T. Sherer, August 4, 2021.
		var assetGraphics = this.assetGraphics;
		var expandedAssetGraphics = assetGraphics.map((collection: any) => {
			return {
				name: collection.Name,
				cachedName: collection.CachedName,
				data: this.ExpandCachedTableData(collection.Cols, collection.Data, collection.Name)
			};
		});
		expandedAssetGraphics.forEach((item: any) => {
			this.cache[item.cachedName] = item.data;
			this.cache[item.cachedName + "Object"] = {};
			item.data &&
				item.data.forEach((row: any) => {
					if (row) {
						this.cache[item.cachedName + "Object"][row.Id] = row;
					}
				});
		});
		//console.log(this.serviceName + "expandedAssetGraphics = %O", expandedAssetGraphics);

		Global.User.currentUser.Security.Aggregate.Collections.AssetIds = service.cache.assets.map((data: any) => {
			return +data.Id;
		});

		var activeSites = service.cache.sites.filter((site: any) => {
			return site.Active == true;
		});
		if (Global.User.isAdmin == false) {
			activeSites = service.cache.sites.filter((site) => {
				return Global.User.currentUser.Security.Aggregate.Collections.SiteIds.includes(site.Id);
			});
		}

		Global.User.PermittedSites = activeSites;
		console.log(this.serviceName + "Global.User.PermittedSites = %O", Global.User.PermittedSites);
		Global.User.currentUser.Security.Aggregate.Collections.SiteNames = activeSites
			.select((data: any) => {
				return data.Name;
			})
			.toArray();
		Global.User.currentUser.SiteList = activeSites
			.select((data: any) => {
				return data.Name;
			})
			.toArray()
			.join(",");
		console.log(this.serviceName + "Basic Collections Loaded - " + (performance.now() - service.time0));
		console.log(this.serviceName + "Stitch the Comprehensive Object Graph together. expandedData = %O", expandedData);

		service.additionalCacheDataSetup().subscribe(
			(data: any) => {
				Global.User.DebugMode && console.log(this.serviceName + "additionalCacheDataSetup$ subscription...");
			},
			(err: Error) => console.error(`Error with additionalCacheDataSetup: ${err.message}`),
			() => {
				service.signalRCore.joinSignalRGroupsByTagNamePrefix(); //-- can only join these after the list of tag name prefixes have been compiled after building the Asset list in the data cache.
				service.dataService.cacheStatus = "additionalCacheDataSetup$ finished.";
				service.dataService.fullCache$.next(service.dataService.cache);
			}
		);

		setTimeout((t) => {
			service.signalRCore.broadcast("dataService.ready");
			service.dataService.ListenForSignalRChangesAndSetUpIntervalFunctions();
		}, 500);
	};

	additionalCacheDataSetup() {
		var service = this.service;

		var additionalCacheDataSetup$ = new Observable((subscriber) => {
			//+Connect collections related to each company
			//***

			var time0 = performance.now();
			var iOPSData: any = {};

			iOPSData.SystemTypes = service.cache.systemTypes;

			var time2 = performance.now();

			iOPSData.AssetTypes = service.cache.assetTypes
				.where(function (a) {
					return a.IsValidForWidgets === true;
				})
				.toArray();
			Global.User.DebugMode && console.log(this.serviceName + "additionalCacheDataSetup: AssetTypes Graph completed. Time: " + (performance.now() - time2));
			Global.User.DebugMode && console.log(this.serviceName + "additionalCacheDataSetup: suiteModules...");

			//Attach the suiteModules for this suite to the suite itself through the intermediate collection.

			service.cache.suites.forEach(function (suite) {
				suite.SuiteModules = service.cache.suiteModules
					.where(function (sm) {
						return (sm.SuiteId = suite.Id);
					})
					.toArray();
			});

			//Attach the SuiteModules to the module itself
			service.cache.modules.forEach(function (module) {
				module.SuiteModules = service.cache.suiteModules
					.where(function (sm) {
						return (sm.ModuleId = module.Id);
					})
					.toArray();
			});

			//Attach the Suite to each record in the SuiteModules objects
			service.cache.suiteModules.forEach(function (sm) {
				sm.Suite = service.cache.suitesObject[sm.SuiteId];
				sm.Module = service.cache.modulesObject[sm.ModuleId];
			});

			iOPSData.Suites = service.cache.suites;
			iOPSData.SuiteModules = service.cache.suiteModules;
			iOPSData.Modules = service.cache.modules;

			iOPSData.WidgetTypes = service.cache.widgetTypes;

			time2 = performance.now();
			//Global.User.DebugMode && console.log(this.serviceName + "iOPSData service.cache.sites = %O", {...service.cache.sites});
			iOPSData.Sites = service.cache.sites
				.select((site: any) => {
					service.dataService.AttachBlankMetadataObject(site);

					site.Assets = service.cache.assets
						.where((asset: any) => asset.SiteId == site.Id)
						.select((asset: any) => {
							asset.Site = site;
							return asset;
						})
						.toArray();

					site.Systems = service.cache.systems
						.where((system: any) => system.SiteId == site.Id)
						.select((system: any) => {
							system.Site = site;
							return system;
						})
						.toArray();

					//Global.User.DebugMode && console.log(this.serviceName + "iOPSData site = %O", {...site});
					return site;
				})
				.toArray();

			Global.User.DebugMode && console.log(this.serviceName + "additionalCacheDataSetup: Sites Graph completed. Time: " + (performance.now() - time2));

			time2 = performance.now();
			var childSystemGroups = service.cache.systems
				.where((s) => {
					return s.ParentSystemId !== null;
				})
				.groupBy((s2) => s2.ParentSystemId)
				.toArray();
			var assetSystemGroups = service.cache.assets
				.where((a) => {
					return a.ParentSystemId !== null;
				})
				.groupBy((a) => a.ParentSystemId)
				.toArray();
			Global.User.DebugMode && console.log(this.serviceName + "additionalCacheDataSetup: Grouping Systems completed. Time: " + (performance.now() - time2));
			Global.User.DebugMode && console.log(this.serviceName + "additionalCacheDataSetup: childSystemGroups = %O", childSystemGroups);
			Global.User.DebugMode && console.log(this.serviceName + "additionalCacheDataSetup: assetSystemGroups = %O", assetSystemGroups);

			iOPSData.Systems = service.cache.systems
				.select((system) => {
					var subSystems: any = [];

					//-- we have to do the try/catch here so that
					//-- the subscription doesn't hit the error return on the subscriber's functions. We need it to return
					//-- the next function or the complete function and we can't have the error function run for this
					//-- or it stops the subscription.--Kirk T. Sherer, March 6, 2020.

					try {
						subSystems = childSystemGroups
							.first((g) => {
								return g.key == system.Id;
							})
							.toArray();
					} catch {
						subSystems = null;
					}

					// Global.User.DebugMode && console.log(this.serviceName + "additionalCacheDataSetup: subSystems = %O", subSystems);

					if (subSystems) {
						system.Systems = subSystems
							.select((s2) => {
								s2.ParentSystem = system;
								s2.Type = service.cache.systemTypesObject[s2.TypeId].Name;
								return s2;
							})
							.toArray();
					} else {
						system.Systems = [];
					}

					//-- we have to do the try/catch here so that
					//-- the subscription doesn't hit the error return on the subscriber's functions. We need it to return
					//-- the next function or the complete function and we can't have the error function run for this
					//-- or it stops the subscription.--Kirk T. Sherer, March 6, 2020.

					try {
						system.Assets = assetSystemGroups
							.first(function (asg) {
								return asg.key == system.Id;
							})
							.toArray();
					} catch {
						system.Assets = [];
					}

					//Global.User.DebugMode && console.log(this.serviceName + "additionalCacheDataSetup: system.Assets = %O", system.Assets);

					var systemType = service.cache.systemTypesObject[system.TypeId];
					if (systemType) {
						system.Type = service.cache.systemTypesObject[system.TypeId].Name;
					} else {
						Global.User.DebugMode && console.log(this.serviceName + "System did not have a TypeId. System = %O", system);
					}
					return system;
				})
				.toArray();

			Global.User.DebugMode && console.log(this.serviceName + "additionalCacheDataSetup: Systems Graph completed. Time: " + (performance.now() - time2));
			Global.User.DebugMode &&
				console.log(
					this.serviceName + "additionalCacheDataSetup: Systems = %O",
					iOPSData.Systems.where(function (s) {
						return s.TypeId == 2;
					}).toArray()
				);

			time2 = performance.now();
			Global.User.DebugMode && console.log(this.serviceName + "additionalCacheDataSetup: service.cache.tagsObject = %O", { ...service.cache.tagsObject });
			Global.User.DebugMode && console.log(this.serviceName + "additionalCacheDataSetup: service.cache.jbtStandardObservationsObject = %O", service.cache.jbtStandardObservationsObject);

			var assets = service.cache.assets
				.select((asset) => {
					asset.Tags = service.cache.tags.where((t) => t.AssetId == asset.Id).toArray();
				})
				.toArray();
			Global.User.DebugMode && console.log(this.serviceName + "additionalCacheDataSetup: assets = %O", assets);

			iOPSData.Assets = service.cache.assets
				.select((asset) => {
					asset.Tags =
						service.cache.tags &&
						service.cache.tags
							.where((tag) => tag.AssetId == asset.Id)
							.select((t2) => {
								t2.Asset = asset;
								return t2;
							})
							.toArray();

					//Global.User.DebugMode && console.log(this.serviceName + "asset.Tags = %O", asset.Tags);
					asset.DeicerEvents =
						service.cache.assetDeicerEvents &&
						service.cache.assetDeicerEvents
							.where((evt) => evt.AssetId == asset.Id)
							.select((t2) => {
								t2.Asset = asset;
								return t2;
							})
							.toArray();
					//Global.User.DebugMode && console.log(this.serviceName + "asset.DeicerEvents = %O", asset.DeicerEvents);

					asset.ParentSystem = service.cache.systemsObject[asset.ParentSystemId];
					//Global.User.DebugMode && console.log(this.serviceName + "asset.ParentSystem = %O", asset.ParentSystem);

					return asset;
				})
				.toArray();

			Global.User.DebugMode && console.log(this.serviceName + "additionalCacheDataSetup: Assets Graph completed. Time: " + (performance.now() - time2) + ", iOPSData.Assets = %O", iOPSData.Assets);

			time2 = performance.now();
			iOPSData.Tags = service.cache.tags
				.select((tag) => {
					tag.JBTStandardObservation = service.cache.jbtStandardObservationsObject[tag.JBTStandardObservationId];

					if (tag.LastObservationDate) {
						tag.LastObservationDate = service.utilityService.GetNonUTCQueryDate(tag.LastObservationDate);
						tag.LastModifiedDate = service.utilityService.GetNonUTCQueryDate(tag.LastModifiedDate);
					}
					Global.User.DebugMode && console.log(this.serviceName + "tag = %O", tag);
					return tag;
				})
				.toArray();

			Global.User.DebugMode && console.log(this.serviceName + "additionalCacheDataSetup: Tags Graph completed. Time: " + (performance.now() - time2) + ", iOPSData.Tags = %O", iOPSData.Tags);

			time2 = performance.now();
			Global.User.DebugMode &&
				console.log(this.serviceName + "service.cache.jbtStandardObservations = %O", {
					...service.cache.jbtStandardObservations
				});
			Global.User.DebugMode &&
				console.log(this.serviceName + "service.cache.unitsObject = %O", {
					...service.cache.unitsObject
				});

			iOPSData.JBTStandardObservations = service.cache.jbtStandardObservations
				.select((s) => {
					s.Tags = service.cache.tags?.where((t) => t.JBTStandardObservationId == s.Id).toArray();
					s.Unit = service.cache.unitsObject[s.UnitId];
					s.ValueTranslations = service.cache.jbtStandardObservationValueTranslations.where((vt) => vt.JBTStandardObservationId == s.Id).toArray();
					return s;
				})
				.toArray();

			Global.User.DebugMode && console.log(this.serviceName + "additionalCacheDataSetup: JBTStandardObservations Graph completed. Time: " + (performance.now() - time2) + " %O", iOPSData.JBTStandardObservations);

			service.cache.jbtStandardObservationValueTranslations.forEach((vt) => {
				vt.JBTStandardObservation = service.cache.jbtStandardObservationsObject[vt.JBTStandardObservationId];
			});

			iOPSData.JBTStandardObservationValueTranslations = service.cache.jbtStandardObservationValueTranslations;

			//Global.User.DebugMode && console.log(this.serviceName + "additionalCacheDataSetup: Complete Object Graph completed. Time: " + (performance.now() - time0), iOPSData);

			service.dataService.cache = service.cache;

			//Global.User.DebugMode && console.log(this.serviceName + "Cache Fully Loaded. Broadcasting service ready.");
			service.cache.ready = true;
			service.service.ready = true;

			//***G
			//+The following lines setup the proper listeners for SQL signalR updates by ececuting the function once for each cache collection.
			service.sqlEntitySubjects = [
				{
					Id: service.dataService.guid(),
					indexedDBCollectionName: "JBTStandardObservations",
					cachedCollectionName: "jbtStandardObservations",
					sqlUpdateName: "JBTStandardObservation",
					afterInsertFunction: function (sqlEntity) {
						sqlEntity.Unit = service.cache.unitsObject[sqlEntity.UnitId];
					}
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "jbtStandardObservationValueTypes",
					sqlUpdateName: "JBTStandardObservationValueType"
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "jbtStandardObservationSeverityLevels",
					sqlUpdateName: "JBTStandardObservationSeverityLevel",
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "systems",
					sqlUpdateName: "System",
					afterInsertFunction: function (sqlEntity) {
						sqlEntity.Type = service.cache.systemTypesObject[sqlEntity.SystemTypeId];
					}
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "jbtStandardObservationValueTranslations",
					sqlUpdateName: "JBTStandardObservationValueTranslations",
					afterInsertFunction: function (sqlEntity) {
						//Find the JBTStandardObservation and attach it.
						var jbtStandardObservationObject = service.cache.jbtStandardObservationsObject[sqlEntity.JBTStandardObservationId];
						sqlEntity.JBTStandardObservation = jbtStandardObservationObject;
	
						if (jbtStandardObservationObject) {
							if (jbtStandardObservationObject.ValueTranslations) {
								jbtStandardObservationObject.ValueTranslations.push(sqlEntity);
							} else {
								jbtStandardObservationObject.ValueTranslations = [sqlEntity];
							}
						}
					},
					afterUpdateFunction: function (sqlEntity) {
						//Find the JBTStandardObservation and modify it.
						var jbtStandardObservationObject = service.cache.jbtStandardObservationsObject[sqlEntity.JBTStandardObservationId];
						sqlEntity.JBTStandardObservation = jbtStandardObservationObject;
	
						if (jbtStandardObservationObject) {
							if (jbtStandardObservationObject.ValueTranslations) {
								jbtStandardObservationObject.ValueTranslations = [sqlEntity]
									.concat(jbtStandardObservationObject.ValueTranslations)
									.toArray()
									.filter((thing: any, i: any, arr: any) => arr.findIndex((t) => t.Id === thing.Id) === i);
							} else {
								jbtStandardObservationObject.ValueTranslations = [sqlEntity];
							}
						}
					}
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "sites",
					sqlUpdateName: "Site",
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "organizations",
					sqlUpdateName: "Organization",
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "widgetTypes",
					sqlUpdateName: "WidgetType",
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "canvasWidgetTypes",
					sqlUpdateName: "CanvasWidgetType",
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "organizationSites",
					sqlUpdateName: "OrganizationSite",
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "organizationSiteSuites",
					sqlUpdateName: "OrganizationSiteSuite",
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "assets",
					sqlUpdateName: "Asset",
					afterInsertFunction: function (row: any) {
						//-- only run this function if the logged-in user is an administrator, or the asset reference is owned, operated, manufactured, or maintained by the user's assigned organization.
						if (Global.User.isAdmin || Global.User.currentUser.Organization.Id == row.OwnerOrganizationId || Global.User.currentUser.Organization.Id == row.OperatorOrganizationId || Global.User.currentUser.Organization.Id == row.ManufacturerOrganizationId || Global.User.currentUser.Organization.Id == row.MaintenanceOrganizationId )
						{
							let newAssetRow: IAsset = {
								Active: row.Active,
								AdveezAsset: null,
								AdveezAssetId: row.AdveezAssetId,
								AssetModelId: row.AssetModelId,
								AssetTypeId: row.AssetTypeId,
								AssetTypeName: row.AssetTypeName,
								AssetType: row.AssetTypeId != null ? service.cache.assetTypesObject[row.AssetTypeId] : null,
								BaseUnitImageURL: row.BaseUnitImageURL,
								DeicerEvents: null,
								Description: row.Description,
								HasElevatorRotunda: row.HasElevatorRotunda == null ? false : row.HasElevatorRotunda,
								ElevatorRotundaTags: [],
								OptionTags: [],
								ActiveAlarmTags: [],
								MovePreventionTags: [],
								GSEPeakReportTags: [],
								Id: row.Id,
								MaintenanceMode: row.MaintenanceMode == null ? false : row.MaintenanceMode,
								MaintenanceModeReasonId: row.MaintenanceModeReasonId,
								MaintenanceOrganizationId: row.MaintenanceOrganizationId,
								MaintenanceOrganizationName: row.MaintenanceOrganizationName,
								ManufacturerOrganizationId: row.ManufacturerOrganizationId,
								ManufacturerOrganizationName: row.ManufacturerOrganizationName,
								ModemNumber: row.ModemNumber,
								Name: row.Name,
								OriginalName: row.OriginalName,
								PreferredName: row.PreferredName,
								OperatorOrganizationId: row.OperatorOrganizationId,
								OperatorOrganizationName: row.OperatorOrganizationName,
								OutOfService: row.OutOfService == null ? false : row.OutOfService,
								OutOfServiceReasonId: row.OutOfServiceReasonId,
								OwnerOrganizationId: row.OwnerOrganizationId,
								OwnerOrganizationName: row.OwnerOrganizationName,
								PLCProgramId: row.PLCProgramId,
								PLCProgramRevisionId: row.PLCProgramRevisionId,
								ParentSystem: service.cache.systemsObject[row.ParentSystemId],
								ParentSystemId: row.ParentSystemId,
								SignalRGroup: row.SignalRGroup,
								Site: service.cache.sitesObject[row.SiteId],
								SiteId: row.SiteId,
								TagNamePrefix: row.TagNamePrefix,
								Tags: [],
								ThingId: row.ThingId
							};
							service.cache.assets.push(newAssetRow);
							service.cache.assetsObject[row.Id] = newAssetRow;
							if (row.AssignedSystemId != null) {
								service.cache.systemsObject[row.AssignedSystemId].Assets.push(newAssetRow);
							}
							service.cache.sitesObject[row.AssignedSiteId].Assets.push(newAssetRow);
							Global.SignalR.ListOfTagNamePrefixes += "," + row.TagNamePrefix;
							service.signalRCore.joinGroup(row.TagNamePrefix);
	
							Global.User.DebugMode && console.log(service.serviceName + "assets afterInsertFunction() executed. service.cache.assets = %O", service.cache.assets);
						}
					},
					afterUpdateFunction: function (row: any) {
						//-- only run this function if the logged-in user is an administrator, or the asset reference is owned, operated, manufactured, or maintained by the user's assigned organization.
						if (Global.User.isAdmin || Global.User.currentUser.Organization.Id == row.OwnerOrganizationId || Global.User.currentUser.Organization.Id == row.OperatorOrganizationId || Global.User.currentUser.Organization.Id == row.ManufacturerOrganizationId || Global.User.currentUser.Organization.Id == row.MaintenanceOrganizationId )
						{
							var existingAsset = service.cache.assetsObject[row.Id];
	
							existingAsset.Active = row.Active,
							existingAsset.AdveezAsset = row.AdveezAssetId != null ? service.cache.adveezAssetsObject[row.AdveezAssetId] : null,
							existingAsset.AdveezAssetId = row.AdveezAssetId,
							existingAsset.AssetModelId = row.AssetModelId,
							existingAsset.AssetTypeId = row.AssetTypeId,
							existingAsset.AssetTypeName = row.AssetTypeId != null ? this.cache.assetTypesObject[row.AssetTypeId].Name : null,
							existingAsset.AssetType = row.AssetTypeId != null ? this.cache.assetTypesObject[row.AssetTypeId] : null,
							existingAsset.BaseUnitImageURL = row.BaseUnitImageURL,
							existingAsset.Description = row.Description,
							existingAsset.HasElevatorRotunda = row.HasElevatorRotunda == null ? false : row.HasElevatorRotunda,
							existingAsset.MaintenanceMode = row.MaintenanceMode == null ? false : row.MaintenanceMode,
							existingAsset.MaintenanceModeReasonId = row.MaintenanceModeReasonId,
							existingAsset.MaintenanceOrganizationId = row.MaintenanceOrganizationId,
							existingAsset.MaintenanceOrganizationName = row.MaintenanceOrganizationId != null ? this.cache.organizationsObject[row.MaintenanceOrganizationId].Name : null,
							existingAsset.ManufacturerOrganizationId = row.ManufacturerOrganizationId,
							existingAsset.ManufacturerOrganizationName = row.ManufacturerOrganizationId != null ? this.cache.organizationsObject[row.ManufacturerOrganizationId].Name : null,
							existingAsset.ModemNumber = row.ModemNumber,
							existingAsset.Name = row.Name,
							existingAsset.OriginalName = row.OriginalName,
							existingAsset.PreferredName = row.PreferredName,
							existingAsset.OperatorOrganizationId = row.OperatorOrganizationId,
							existingAsset.OperatorOrganizationName = row.OperatorOrganizationId != null ? this.cache.organizationsObject[row.OperatorOrganizationId].Name : null,
							existingAsset.OutOfService = row.OutOfService == null ? false : row.OutOfService,
							existingAsset.OutOfServiceReasonId = row.OutOfServiceReasonId,
							existingAsset.OwnerOrganizationId = row.OwnerOrganizationId,
							existingAsset.OwnerOrganizationName = row.OwnerOrganizationId != null ? this.cache.organizationsObject[row.OwnerOrganizationId].Name : null,
							existingAsset.PLCProgramId = row.PLCProgramId,
							existingAsset.PLCProgramRevisionId = row.PLCProgramRevisionId,
							existingAsset.ParentSystem = service.cache.systemsObject[row.ParentSystemId],
							existingAsset.ParentSystemId = row.ParentSystemId,
							existingAsset.SignalRGroup = row.SignalRGroup,
							existingAsset.Site = service.cache.sitesObject[row.SiteId],
							existingAsset.SiteId = row.SiteId,
							existingAsset.TagNamePrefix = row.TagNamePrefix,
							existingAsset.ThingId = row.ThingId
	
							var tagNamePrefixExists = Global.SignalR.ListOfTagNamePrefixes.split(",").firstOrDefault((tagNamePrefix:string) => { return tagNamePrefix == row.TagNamePrefix });
							if (tagNamePrefixExists == null) {
								//-- this asset didn't currently exist in the list of TagNamePrefixes, so just add it to the list and join the new SignalR group. 
								Global.SignalR.ListOfTagNamePrefixes += "," + row.TagNamePrefix;
								service.signalRCore.joinGroup(row.TagNamePrefix);
							}
	
							Global.User.DebugMode && console.log(service.serviceName + "assets afterUpdateFunction() executed. service.cache.assets = %O", service.cache.assets);
						}
					},
					afterDeleteFunction: function (row: any) {
						//-- only run this function if the logged-in user is an administrator, or the asset reference is owned, operated, manufactured, or maintained by the user's assigned organization.
						if (Global.User.isAdmin || Global.User.currentUser.Organization.Id == row.OwnerOrganizationId || Global.User.currentUser.Organization.Id == row.OperatorOrganizationId || Global.User.currentUser.Organization.Id == row.ManufacturerOrganizationId || Global.User.currentUser.Organization.Id == row.MaintenanceOrganizationId )
						{
							service.cache.assets = service.cache.assets
								.where((assets: IAsset) => {
									return assets.Id != row.Id;
								})
								.toArray();
							service.cache.assetsObject[+row.Id] = null;
							service.cache.sitesObject[row.SiteId].Assets = service.cache.sitesObject[row.SiteId].Assets.where((asset: IAsset) => {
								return asset.Id != row.Id;
							}).toArray();
							service.cache.systemsObject[row.ParentSystemId].Assets = service.cache.systemsObject[row.ParentSystemId].Assets.where((asset: IAsset) => {
								return asset.Id != row.Id;
							}).toArray();
	
							var remainingListOfTagNamePrefixes = Global.SignalR.ListOfTagNamePrefixes.split(",").where((tagNamePrefix:string) => { return tagNamePrefix != row.TagNamePrefix }).toArray().join(",");
							Global.SignalR.ListOfTagNamePrefixes = remainingListOfTagNamePrefixes;
							service.signalRCore.leaveGroup(row.TagNamePrefix);
	
							Global.User.DebugMode && console.log(service.serviceName + "assets afterUpdateFunction() executed. service.cache.assets = %O", service.cache.assets);
						}
					},
					debug: true
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "assetTypes",
					sqlUpdateName: "AssetTypes",
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "units",
					sqlUpdateName: "Unit",
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "assetGraphics",
					sqlUpdateName: "AssetGraphic",
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "assetGraphicVisibleValues",
					sqlUpdateName: "AssetGraphicVisibleValue",
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "systemTypes",
					sqlUpdateName: "SystemType",
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "suites",
					sqlUpdateName: "Suite",
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "suiteModules",
					sqlUpdateName: "SuiteModule",
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "modules",
					sqlUpdateName: "Module",
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "moduleWidgetTypes",
					sqlUpdateName: "ModuleWidgetType",
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "assetMaintenanceModeReasons",
					sqlUpdateName: "AssetMaintenanceModeReason",
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "assetOutOfServiceReasons",
					sqlUpdateName: "AssetOutOfServiceReason",
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "canvasWidgetTagRules",
					sqlUpdateName: "CanvasWidgetTagRule",
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "systemGraphics",
					sqlUpdateName: "SystemGraphic",
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "systemGraphicVisibleValues",
					sqlUpdateName: "SystemGraphicVisibleValue",
				},
				{
					Id: service.dataService.guid(),
					indexedDBCollectionName: "NinjaStatistics",
					cachedCollectionName: "ninjaStatistics",
					sqlUpdateName: "NinjaStatistic",
				},
				{
					Id: service.dataService.guid(),
					indexedDBCollectionName: "Cameras",
					cachedCollectionName: "cameras",
					sqlUpdateName: "Camera",
					afterInsertFunction: function (sqlEntity: any) {
						let newCameraRow: ICamera = {
							AssignedSystemId: sqlEntity.AssignedSystemId ?? null,
							AssignedSiteId: sqlEntity.AssignedSiteId,
							Description: sqlEntity.Description ?? null,
							Id: sqlEntity.Id,
							Name: sqlEntity.Name,
							Ordinal: sqlEntity.Ordinal ?? 0,
							SelectorButtonLabel: sqlEntity.SelectorButtonLabel,
							Site: service.cache.sitesObject[sqlEntity.AssignedSiteId],
							System: sqlEntity.AssignedSystemId != null ? service.cache.systemsObject[sqlEntity.AssignedSystemId] : null,
							trustedSourceURL: null
						};
						service.cache.cameras.push(newCameraRow);
						service.cache.camerasObject[sqlEntity.Id] = newCameraRow;
						if (sqlEntity.AssignedSystemId != null) {
							service.cache.systemsObject[sqlEntity.AssignedSystemId].Cameras.push(newCameraRow);
						}
						service.cache.sitesObject[sqlEntity.AssignedSiteId].Cameras.push(newCameraRow);
	
						Global.User.DebugMode && console.log(service.serviceName + "cameras afterInsertFunction() executed. service.cache.cameras = %O", service.cache.cameras);
					},
					afterUpdateFunction: function (sqlEntity: any) {
						var existingCamera = service.cache.camerasObject[sqlEntity.Id];
						existingCamera.AssignedSystemId = sqlEntity.AssignedSystemId ?? null;
						existingCamera.AssignedSiteId = sqlEntity.AssignedSiteId;
						existingCamera.Description = sqlEntity.Description ?? null;
						existingCamera.Name = sqlEntity.Name;
						existingCamera.Ordinal = sqlEntity.Ordinal ?? 0;
						existingCamera.SelectorButtonLabel = sqlEntity.SelectorButtonLabel;
	
						Global.User.DebugMode && console.log(service.serviceName + "cameras afterUpdateFunction() executed. service.cache.cameras = %O", service.cache.cameras);
					},
					afterDeleteFunction: function (sqlEntity: any) {
						service.cache.cameras = service.cache.cameras
							.where((camera: ICamera) => {
								return camera.Id != sqlEntity.Id;
							})
							.toArray();
						service.cache.camerasObject[+sqlEntity.Id] = null;
						service.cache.sitesObject[sqlEntity.AssignedSiteId].Cameras = service.cache.sitesObject[sqlEntity.AssignedSiteId].Cameras.where((camera: ICamera) => {
							return camera.Id != sqlEntity.Id;
						}).toArray();
						service.cache.systemsObject[sqlEntity.AssignedSystemId].Cameras = service.cache.systemsObject[sqlEntity.AssignedSystemId].Cameras.where((camera: ICamera) => {
							return camera.Id != sqlEntity.Id;
						}).toArray();
	
						Global.User.DebugMode && console.log(service.serviceName + "cameras afterUpdateFunction() executed. service.cache.cameras = %O", service.cache.cameras);
					},
					debug: true
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "canvasTemplates",
					sqlUpdateName: "CanvasTemplate",
					afterInsertFunction: function (sqlEntity) {
						console.log(service.serviceName + "CanvasTemplate afterInsertFunction() started. New CanvasTemplate = %O", sqlEntity);
						console.log(service.serviceName + "CanvasTemplate afterInsertFunction() finished. canvasTemplates = %O", service.cache.canvasTemplates);
					},
					afterUpdateFunction: function (sqlEntity) {
						var existingCanvasTemplate = service.cache.canvasTemplatesObject[sqlEntity.Id];
						console.log(service.serviceName + "CanvasTemplate afterUpdateFunction() started. Updating canvasTemplate = %O", existingCanvasTemplate);
						console.log(service.serviceName + "CanvasTemplate afterUpdateFunction() finished. canvasTemplates = %O", service.cache.canvasTemplates);
					},

				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "canvasWidgetTemplates",
					sqlUpdateName: "CanvasWidgetTemplate",
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "canvasWidgetTemplateJBTStandardObservations",
					sqlUpdateName: "CanvasWidgetTemplateJBTStandardObservation",
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "canvasWidgetTemplateJBTStandardObservationGaugeColorRanges",
					sqlUpdateName: "CanvasWidgetTemplateJBTStandardObservationGaugeColorRange",
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "canvasWidgetTemplateTagGridFilters",
					sqlUpdateName: "CanvasWidgetTemplateTagGridFilter",
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "tacticalDashboardWidgetConditionalShadings",
					sqlUpdateName: "TacticalDashboardWidgetConditionalShading",
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "BreadCrumbsEventsTemplates",
					sqlUpdateName: "BreadCrumbsEventsTemplate",
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "breadCrumbsEventsTemplateJBTStandardObservations",
					sqlUpdateName: "BreadCrumbsEventsTemplateJBTStandardObservation",
				},
				{
					Id: service.dataService.guid(),
					indexedDBCollectionName: "UserIssues",
					cachedCollectionName: "userIssues",
					sqlUpdateName: "UserIssue",
					afterInsertFunction: function (sqlEntity) {
						console.log(service.serviceName + "UserIssues afterInsertFunction() started. Creating User Issue = %O", sqlEntity);
						let newUserIssueRow: IUserIssue = {
							AssignedDate: sqlEntity.AssignedDate != 0 ? new Date(sqlEntity.AssignedDate) : null,
							AssignedDateMS: +sqlEntity.AssignedDateMS < 0 ? null : +sqlEntity.AssignedDateMS,
							AssignedToUserId: sqlEntity.AssignedToUserId == 0 ? null : +sqlEntity.AssignedToUserId,
							AssignedTo: sqlEntity.AssignedTo == 0 ? null : sqlEntity.AssignedTo,
							CountOfFilesUploaded: +sqlEntity.CountOfFilesUploaded,
							CreatedBy: sqlEntity.CreatedBy,
							CreationDate: sqlEntity.CreationDate != 0 ? new Date(sqlEntity.CreationDate) : null,
							CreationDateMS: +sqlEntity.CreationDateMS < 0 ? null : +sqlEntity.CreationDateMS,
							CreatorUserId: +sqlEntity.CreatorUserId,
							Deleted: sqlEntity.Deleted == "true" || sqlEntity.Deleted == "1" ? true : false,
							Description: sqlEntity.Description != 0 ? service.stringToHTML(sqlEntity.Description) : null,
							DiscussionCount: +sqlEntity.DiscussionCount,
							Email: sqlEntity.Email != 0 ? sqlEntity.Email : null,
							Id: +sqlEntity.Id,
							LastModifiedDate: sqlEntity.LastModifiedDate != 0 ? new Date(sqlEntity.LastModifiedDate) : null,
							LastModifiedDateMS: +sqlEntity.LastModifiedDateMS < 0 ? null : +sqlEntity.LastModifiedDateMS,
							LastModifiedBy: sqlEntity.LastModifiedBy != 0 ? sqlEntity.LastModifiedBy : null,
							LastModifiedUserId: sqlEntity.LastModifiedUserId != 0 ? +sqlEntity.LastModifiedUserId : null,
							NotifyCreatorUser: sqlEntity.NotifyCreatorUser == "true" || sqlEntity.NotifyCreatorUser == "1" ? true : false,
							NotifyAssignedUser: sqlEntity.NotifyAssignedUser == "true" || sqlEntity.NotifyAssignedUser == "1" ? true : false,
							Organization: sqlEntity.Organization,
							Phone: sqlEntity.Phone != 0 ? sqlEntity.Phone : null,
							Resolution: sqlEntity.Resolution != 0 ? service.stringToHTML(sqlEntity.Resolution) : null,
							Resolved: sqlEntity.Resolved == "true" || sqlEntity.Resolved == "1" ? true : false,
							Subject: sqlEntity.Subject != 0 ? sqlEntity.Subject : null,
							UserIssueSubjectId: sqlEntity.UserIssueSubjectId != 0 ? +sqlEntity.UserIssueSubjectId : null
						};
						service.cache.userIssues.push(newUserIssueRow);
						service.cache.userIssuesObject[sqlEntity.Id] = newUserIssueRow;
	
						service.signalRCore.broadcast("UserIssue Inserted");
	
						if (newUserIssueRow.CreatorUserId == service.global.User.currentUser.Id || newUserIssueRow.AssignedToUserId == service.global.User.currentUser.Id) {
							if (newUserIssueRow.NotifyCreatorUser || newUserIssueRow.NotifyAssignedUser) {
								var specificUserMessage = newUserIssueRow.CreatorUserId == service.global.User.currentUser.Id ? " by you." : " and assigned to you.";
								service.utilityService.showToastMessageShared({
									type: "info",
									message: "'User Issue #" + newUserIssueRow.Id + " has been created" + specificUserMessage + " Please see User Issues for details."
								});
							}
						} else {
							if (!service.global.User.isAdmin) {
								//-- current user isn't an administrator, so only give them their own list of issues. --Kirk T. Sherer, August 8, 2023.
								service.cache.userIssues = service.cache.userIssues
									.where((issue: any) => {
										return issue.CreatorUserId == service.global.User.currentUser.Id;
									})
									.toArray();
							}
						}
	
						Global.User.issueNotificationCount = service.cache.userIssues
							.where((i: any) => {
								if (Global.User.isAdmin) {
									return (i.CreatorUserId === Global.User.currentUser.Id && i.NotifyCreatorUser === true) || (i.AssignedToUserId === Global.User.currentUser.Id && i.NotifyAssignedUser === true);
								} else {
									return i.CreatorUserId === Global.User.currentUser.Id && i.NotifyCreatorUser === true;
								}
							})
							.toArray().length;
	
						console.log(service.serviceName + "UserIssues afterInsertFunction() finished. newUserIssueRow = %O", newUserIssueRow);
	
					},
					afterUpdateFunction: function (sqlEntity) {
						var existingUserIssue = service.cache.userIssuesObject[sqlEntity.Id];
						console.log(service.serviceName + "UserIssues afterUpdateFunction() started. Updating existingUserIssue = %O", existingUserIssue);
						existingUserIssue.AssignedDate = sqlEntity.AssignedDate != 0 ? new Date(sqlEntity.AssignedDate) : null;
						existingUserIssue.AssignedDateMS = +sqlEntity.AssignedDateMS < 0 ? null : +sqlEntity.AssignedDateMS;
						existingUserIssue.AssignedToUserId = sqlEntity.AssignedToUserId == 0 ? null : +sqlEntity.AssignedToUserId;
						existingUserIssue.AssignedTo = sqlEntity.AssignedTo == 0 ? null : sqlEntity.AssignedTo;
						existingUserIssue.CountOfFilesUploaded = +sqlEntity.CountOfFilesUploaded;
						existingUserIssue.CreatedBy = sqlEntity.CreatedBy;
						existingUserIssue.CreationDate = sqlEntity.CreationDate != 0 ? new Date(sqlEntity.CreationDate) : null;
						existingUserIssue.CreationDateMS = +sqlEntity.CreationDateMS < 0 ? null : +sqlEntity.CreationDateMS;
						existingUserIssue.CreatorUserId = +sqlEntity.CreatorUserId;
						existingUserIssue.Deleted = sqlEntity.Deleted == "true" || sqlEntity.Deleted == "1" ? true : false;
						existingUserIssue.Description = sqlEntity.Description != 0 ? service.stringToHTML(sqlEntity.Description) : null;
						existingUserIssue.DiscussionCount = +sqlEntity.DiscussionCount;
						existingUserIssue.Email = sqlEntity.Email != 0 ? sqlEntity.Email : null;
						existingUserIssue.LastModifiedDateMS = +sqlEntity.LastModifiedDateMS < 0 ? null : +sqlEntity.LastModifiedDateMS;
						existingUserIssue.LastModifiedDate = sqlEntity.LastModifiedDateMS < 0 ? null :  new Date(sqlEntity.LastModifiedDateMS);
						existingUserIssue.LastModifiedBy = sqlEntity.LastModifiedBy != 0 ? sqlEntity.LastModifiedBy : null;
						existingUserIssue.LastModifiedUserId = sqlEntity.LastModifiedUserId != 0 ? +sqlEntity.LastModifiedUserId : null;
						existingUserIssue.NotifyCreatorUser = sqlEntity.NotifyCreatorUser == "true" || sqlEntity.NotifyCreatorUser == "1" ? true : false;
						existingUserIssue.NotifyAssignedUser = sqlEntity.NotifyAssignedUser == "true" || sqlEntity.NotifyAssignedUser == "1" ? true : false;
						existingUserIssue.Organization = sqlEntity.Organization != 0 ? sqlEntity.Organization : null;
						existingUserIssue.Phone = sqlEntity.Phone != 0 ? sqlEntity.Phone : null;
						existingUserIssue.Resolution = sqlEntity.Resolution != 0 ? service.stringToHTML(sqlEntity.Resolution) : null;
						existingUserIssue.Resolved = sqlEntity.Resolved == "true" || sqlEntity.Resolved == "1" ? true : false;
						existingUserIssue.Subject = sqlEntity.Subject != 0 ? sqlEntity.Subject : null;
						existingUserIssue.UserIssueSubjectId = sqlEntity.UserIssueSubjectId != 0 ? +sqlEntity.UserIssueSubjectId : null;
	
						if ((existingUserIssue.CreatorUserId == service.global.User.currentUser.Id && existingUserIssue.NotifyCreatorUser) || (existingUserIssue.AssignedToUserId == service.global.User.currentUser.Id && existingUserIssue.NotifyAssignedUser)) {
							var specificUserMessage = existingUserIssue.CreatorUserId == service.global.User.currentUser.Id ? "." : " and assigned to you.";
							service.utilityService.showToastMessageShared({
								type: "info",
								message: "User Issue #" + existingUserIssue.Id + " has been updated" + specificUserMessage + " Please see User Issues for details."
							});
						} else {
							if (!service.global.User.isAdmin) {
								//-- current user isn't an administrator, so only give them their own list of issues. --Kirk T. Sherer, August 8, 2023.
								service.cache.userIssues = this.cache.userIssues
									.where((issue: any) => {
										return issue.CreatorUserId == service.global.User.currentUser.Id;
									})
									.toArray();
							}
						}
	
						Global.User.issueNotificationCount = service.cache.userIssues
							.where((i: any) => {
								if (Global.User.isAdmin) {
									return (i.CreatorUserId === Global.User.currentUser.Id && i.NotifyCreatorUser === true) || (i.AssignedToUserId === Global.User.currentUser.Id && i.NotifyAssignedUser === true);
								} else {
									return i.CreatorUserId === Global.User.currentUser.Id && i.NotifyCreatorUser === true;
								}
							})
							.toArray().length;
	
						console.log(service.serviceName + "UserIssues afterUpdateFunction() finished. existingUserIssue = %O", existingUserIssue);
					},
					afterDeleteFunction: function (sqlEntity: any) {
						service.cache.userIssues = service.cache.userIssues
							.where((userIssue: IUserIssue) => {
								return userIssue.Id != sqlEntity.Id;
							})
							.toArray();
						service.cache.userIssuesObject[+sqlEntity.Id] = null;
						if (!Global.User.isAdmin) {
							//-- current user isn't an administrator, so only give them their own list of issues. --Kirk T. Sherer, August 8, 2023.
							service.cache.userIssues = this.cache.userIssues
								.where((issue: any) => {
									return issue.CreatorUserId == Global.User.currentUser.Id;
								})
								.toArray();
						}
						console.log(service.serviceName + "User Issues afterDeleteFunction() executed. service.cache.userIssues = %O", service.cache.userIssues);
	
						Global.User.issueNotificationCount = service.cache.userIssues
							.where((i: any) => {
								if (Global.User.isAdmin) {
									return (i.CreatorUserId === Global.User.currentUser.Id && i.NotifyCreatorUser === true) || (i.AssignedToUserId === Global.User.currentUser.Id && i.NotifyAssignedUser === true);
								} else {
									return i.CreatorUserId === Global.User.currentUser.Id && i.NotifyCreatorUser === true;
								}
							})
							.toArray().length;
					},
					debug: true
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "emailTagUsers",
					sqlUpdateName: "EmailTagUsers",
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "entityLogs",
					sqlUpdateName: "EntityLog",
				},
				{
					Id: service.dataService.guid(),
					cachedCollectionName: "sabelData",
					sqlUpdateName: "SabelData",
					afterInsertFunction: function (sqlEntity) {
						//console.log(service.serviceName + "Sabel Data afterInsertFunction() started. Creating Sabel Data = %O", sqlEntity);
						if (sqlEntity.RedisKey.indexOf("-") > -1) {
							sqlEntity.RedisKey = sqlEntity.RedisKey?.split("-").firstOrDefault();
						}
			
						let newSabelDataRow: ISabelData = {
							SiteId: +sqlEntity.SiteId,
							Site: sqlEntity.Site,
							System: sqlEntity.System,
							Asset: sqlEntity.Asset,
							JBTStandardObservationId: +sqlEntity.JBTStandardObservationId,
							Name: sqlEntity.Name,
							ObservationDateTime: sqlEntity.ObservationDateTime,
							Value: sqlEntity.Value,
							TagId: +sqlEntity.TagId,
							RedisKey: sqlEntity.RedisKey,
							RecentlyUpdated: true
						};
						service.cache.sabelData.push(newSabelDataRow);
						service.cache.sabelDataObject[+sqlEntity.Id] = newSabelDataRow;

						service.cache.sabelData.splice(1000); //-- keep the list pared down to the last 1,000 records. 
	
						//console.log(service.serviceName + "Sabel Data Row Inserted");
					}
				}
			];

			Global.User.DebugMode &&
			console.log(
				this.serviceName +
					"<-- SQL Active Subjects -->"
			);
			console.log(this.serviceName + "list of SQL Entity Subjects: %O", service.sqlEntitySubjects);

			subscriber.next("Finished with additionalCacheDataSetup.");
			subscriber.complete();
		});

		return additionalCacheDataSetup$;
	}

	ProcessIncomingSQLSignalRMessages(signalRMessage: any) {
		var service = this.service;
		var typeOfOperation = signalRMessage.code.split(".").last();
		var sqlUpdateName = signalRMessage.code.split(".")[1];

		if (service.sqlEntitySubjects.length > 0) {
			var sqlEntitySubject = service.sqlEntitySubjects.firstOrDefault((sqlEntitySubject: ISQLServerSubject) => { return sqlEntitySubject.sqlUpdateName == sqlUpdateName});
			//-- if there are no active subjects, or none equal to the sqlUpdateName on the incoming SQL SignalR Message, then don't do anything. 
			if (sqlEntitySubject != null) {
				Global.SignalR.countOfObservations++; //-- technically this is a SignalR message, so we're counting it. 

				if (sqlEntitySubject.debug) {
					Global.User.DebugMode && console.log(this.serviceName + "============================================");
					Global.User.DebugMode && console.log(this.serviceName + "SQL." + sqlEntitySubject.sqlUpdateName + "." + typeOfOperation + " triggered. sqlEntity = %O", sqlEntitySubject);
				}
		
				var sqlEntity = signalRMessage.object == null ? signalRMessage.code : signalRMessage.object;
				if (typeof sqlEntity == "string") {
					sqlEntity = service.utilityService.GetJsonFromSignalR(sqlEntity);
				}
				if (sqlUpdateName == "SabelData") {
					sqlEntity.Id = service.dataService.guid(); //-- have to create an ID number value since the Sabel insert has not actually been inserted into SQL Server yet.
				}
		
				switch (typeOfOperation) {
					//+SQL Update or Insert
					case "Update":
					case "Insert":
						if (service.cache[sqlEntitySubject.cachedCollectionName] && service.cache[sqlEntitySubject.cachedCollectionName].count() > 0) {
							var cacheEntity: any;
							cacheEntity = service.cache[sqlEntitySubject.cachedCollectionName + "Object"][sqlEntity.Id];

							if (!cacheEntity) {
								service.insertSQLEntity(typeOfOperation, sqlUpdateName, sqlEntitySubject, sqlEntity, cacheEntity, service);
							}
							else 
							{
								service.updateSQLEntity(typeOfOperation, sqlUpdateName, sqlEntitySubject, sqlEntity, cacheEntity, service);
							}
						}
						break;
					//+SQL Delete
					case "Delete": 
						if (service.cache[sqlEntitySubject.cachedCollectionName] && service.cache[sqlEntitySubject.cachedCollectionName].count() > 0) {
							var cacheEntity: any;
							cacheEntity = service.cache[sqlEntitySubject.cachedCollectionName + "Object"][sqlEntity.Id];
							if (!cacheEntity) {
								//If the db entity did not exist, stop right here. No need to delete anything. 
								return;
							}
							else 
							{
								service.deleteSQLEntity(sqlEntitySubject, sqlEntity, cacheEntity, service);
							}
						}
						break;
				}
				sqlEntitySubject.Subject$ && sqlEntitySubject.Subject$.next(sqlEntity);
				if (service.dataService.sqlListenerSubjects.length > 0) {
					var relatedListenerSubject = service.dataService.sqlListenerSubjects.firstOrDefault((sqlListenerSubject: ISQLServerSubject) => { return sqlListenerSubject.sqlUpdateName == "SabelData" });
					if (relatedListenerSubject != null) {
						relatedListenerSubject.Subject$.next(sqlEntity); //-- send the updated sqlEntity record to the listening widgets with sql listener subscriptions.  If there are none, then this won't do anything.  --Kirk T. Sherer, October 14, 2024.
					}
				}
			}
		}
	

	}

	private updateSQLEntity(typeOfOperation: string, sqlUpdateName: string, activeSubject: any, sqlEntity: any, cacheEntity: any, service: any) {
		var t0 = performance.now();
		if (sqlUpdateName == "UserIssue") {
			console.log("User Issues being updated... %O", activeSubject);
			activeSubject.afterUpdateFunction && activeSubject.afterUpdateFunction(sqlEntity);
			return;
			//-- NOTE: User Issues will be updated in the afterInsert and afterUpdate functions. There is more structure than just the SignalR fields being returned. --Kirk T. Sherer, August 10, 2023.
		}

		if (sqlUpdateName == "Camera") {
			//-- must verify the user has access to the SiteId being sent from SQL Server to add to their list of cameras.  If not, then exit.
			var sqlEntitySiteId = +sqlEntity.AssignedSiteId;
			var siteExistsInPermittedListOfSites = Global.User.PermittedSites.firstOrDefault((site: ISite) => {
				return site.Id == sqlEntitySiteId;
			});
			if (!siteExistsInPermittedListOfSites) {
				//-- user doesn't have this site in their list of sites.  Exit this function immediately.
				return;
			}
			//-- NOTE: Cameras will be updated in the afterInsert and afterUpdate functions. There is more structure than just the SignalR fields being returned. --Kirk T. Sherer, July 26, 2023.
		} else {
			//Copy the properties correctly, except for the Id
			for (var property in sqlEntity) {
				if (sqlEntity.hasOwnProperty(property)) {
					if (sqlEntity[property] == "true") {
						sqlEntity[property] = true;
					}
					if (sqlEntity[property] == "false") {
						sqlEntity[property] = false;
					}
					if (property != "Id") {
						cacheEntity[property] = sqlEntity[property];
					}
				}
			}
		}

		if (activeSubject.debug) {
			Global.User.DebugMode && console.log(service.serviceName + "cache entity = %O", cacheEntity);
		}
		service.cache.updated = true;
		//Global.User.DebugMode && console.log(this.serviceName + "cache = %O", cache);
		service.signalRCore.broadcast("dataService.cache." + activeSubject.cachedCollectionName + " Modified", cacheEntity.Id);
		if (activeSubject.debug) {
			Global.User.DebugMode && console.log(service.serviceName + "Done! - " + (performance.now() - t0) + "ms");
			Global.User.DebugMode && console.log(service.serviceName + "============================================");
		}

		service.cache[activeSubject.cachedCollectionName + "Updated"] = true;
		activeSubject.afterUpdateFunction && activeSubject.afterUpdateFunction(sqlEntity);

	}

	private insertSQLEntity(typeOfOperation: string, sqlUpdateName: string, activeSubject: any, sqlEntity: any, cacheEntity: any, service: any) {
		var t0 = performance.now();
		if (activeSubject.debug) {
			Global.User.DebugMode && console.log(service.serviceName + "Cached Entity Insert for " + activeSubject.cachedCollectionName );
		}

		if (activeSubject.sqlUpdateName == "UserIssue") {
			console.log("User Issues being updated... %O", activeSubject);
			activeSubject.afterInsertFunction && activeSubject.afterInsertFunction(sqlEntity);
			return;
			//-- NOTE: User Issues will be updated in the afterInsert and afterUpdate functions. There is more structure than just the SignalR fields being returned. --Kirk T. Sherer, August 10, 2023.
		}

		if (activeSubject.sqlUpdateName == "SabelData") {
			if (sqlEntity.RedisKey.indexOf("-") > -1) {
				sqlEntity.RedisKey = sqlEntity.RedisKey?.split("-").firstOrDefault();
			}
			sqlEntity.UTCDateFull = service.utilityService.convertMillisecondsToDateWithTimezoneOffset(sqlEntity.ObservationDateTimeMS, 0);
			sqlEntity.RecentlyUpdated = true;
			sqlEntity.UserDateFull = new Date(sqlEntity.ObservationDateTimeMS);
			var siteUTCTimeOffset = service.cache.sites.firstOrDefault((site:ISite) => { return site.Name == sqlEntity.Site }).UTCTimeOffset;
			sqlEntity.SiteDateFull = service.utilityService.convertMillisecondsToDateWithTimezoneOffset(sqlEntity.ObservationDateTimeMS, siteUTCTimeOffset);
		}

		if (activeSubject.sqlUpdateName == "Cameras") {
			//-- must verify the user has access to the SiteId being sent from SQL Server to add to their list of cameras.  If not, then exit.
			var sqlEntitySiteId = +sqlEntity.AssignedSiteId;
			var siteExistsInPermittedListOfSites = Global.User.PermittedSites.firstOrDefault((site: ISite) => {
				return site.Id == sqlEntitySiteId;
			});
			if (!siteExistsInPermittedListOfSites) {
				//-- user doesn't have this site in their list of sites.  Exit this function immediately.
				return;
			}
			//-- NOTE: Cameras will be updated in the afterInsert and afterUpdate functions. There is more structure than just the SignalR fields being returned. --Kirk T. Sherer, July 26, 2023.
		} else {
			for (var property in sqlEntity) {
				if (sqlEntity.hasOwnProperty(property)) {
					if (sqlEntity[property] == "true") {
						sqlEntity[property] = true;
					}
					if (sqlEntity[property] == "false") {
						sqlEntity[property] = false;
					}
				}
			}

			service.cache[activeSubject.cachedCollectionName].push(sqlEntity);
			service.cache[activeSubject.cachedCollectionName + "Object"][+sqlEntity.Id] = sqlEntity;
		}

		service.cache.updated = true;

		service.signalRCore.broadcast("dataService.cache." + activeSubject.cachedCollectionName + " Added", +sqlEntity.Id);
		if (activeSubject.debug) {
			Global.User.DebugMode && console.log(service.serviceName + "cache." + activeSubject.cachedCollectionName + " = %O", service.cache[activeSubject.cachedCollectionName]);
			Global.User.DebugMode && console.log(service.serviceName + "Done! - " + (performance.now() - t0) + "ms");
		}
		activeSubject.afterInsertFunction && activeSubject.afterInsertFunction(sqlEntity);
	}

	private deleteSQLEntity(activeSubject: any, sqlEntity: any, cacheEntity: any, service: any) {
		var t0 = performance.now();
		if (service.cache[activeSubject.cachedCollectionName] && service.cache[activeSubject.cachedCollectionName].count() > 0) {
			var cacheEntity: any;

			cacheEntity = service.cache[activeSubject.cachedCollectionName + "Object"][sqlEntity.Id];

			if (!cacheEntity) {
				//If the db entity did not exist, stop right here.
				return;
			}

			if (activeSubject.debug) {
				Global.User.DebugMode && console.log(service.serviceName + "Cached Entity delete for " + activeSubject.cachedCollectionName + " was in cache already. Using cache copy");
			}

			service.cache[activeSubject.cachedCollectionName] = service.cache[activeSubject.cachedCollectionName].where((c: any) => c.Id != +sqlEntity.Id).toArray();
			service.cache[activeSubject.cachedCollectionName + "Object"][+sqlEntity.Id] = null;

			//Store the dbCollection back into local indexed DB
			service.cache.updated = true;
			service.signalRCore.broadcast("dataService.cache." + activeSubject.cachedCollectionName + " Deleted", +sqlEntity.Id);

			if (activeSubject.debug) {
				Global.User.DebugMode && console.log(this.serviceName + "cache." + activeSubject.cachedCollectionName + " = %O", service.cache[activeSubject.cachedCollectionName]);
				Global.User.DebugMode && console.log(this.serviceName + "Done! - " + (performance.now() - t0) + "ms");
			}
			activeSubject.afterDeleteFunction && activeSubject.afterDeleteFunction(sqlEntity);
		}
	}


	public stringToHTML(textString: string) {
		var parser = new DOMParser();
		var doc = parser.parseFromString(textString, "text/html");
		var text = doc.body.innerHTML;
		//console.log(this.componentName + "stringToHTML(" + textString + ") = " + text);
		var returnText = textString != undefined ? text.split("<br>").join("\n").split("<p>").join("").split("</p>").join("").split("&lt;").join("<").split("&gt;").join(">").split("<p>").join("").split("</p>").join("") : "";
		return returnText;
	}
}
