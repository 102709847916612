import { Injectable } from "@angular/core";
import _ from "lodash";
import { ColumnSettings } from "../_models/column-settings.interface";
import { DataService } from "./data.service";

@Injectable({
	providedIn: "root",
})
export class KendoSettingsService {

	public OverviewPropertiesToMerge = ['centered', 'trendable', 'ignoreDecimalDefault', 'columnObId']

	constructor(private dataService: DataService) { }

	//takes an array since we potentially have multiple kendo tables per widget, example being gse-summary, one on data and one on alarms tabs.
	parseReturnedSettingsForDates(jsonArray: any) {
		let jsonDataObjectParsed = JSON.parse(jsonArray);
		jsonDataObjectParsed.forEach((jsonObject, index) => {
			if (jsonObject.state.filter.filters.length > 0) {
				jsonObject.state.filter.filters.forEach((filter) => {
					filter.filters.forEach((filterFilter) => {
						let column = jsonDataObjectParsed[index].columnsConfig.find((column) => {
							return column.field === filterFilter.field;
						});
						if (column.filter === "date" && filterFilter.operator !== "isnull" && filterFilter.operator !== "isnotnull") {
							filterFilter.value = new Date(filterFilter.value);
						}
					});
				});
			}
		});
		return jsonDataObjectParsed;
	}
	saveGridSettings(gridsArray, widgetId) {
		let arrayToSave = [];
		gridsArray.forEach((grid) => {
			const columns = grid.gridObject.columns;
			const gridConfig = {
				name: grid.gridName,
				state: grid.gridState,
				columnsConfig: columns.toArray().map((item) => {
					return Object.keys(item)
						.filter((propName) => !propName.toLowerCase().includes("template"))
						.reduce((acc, curr) => ({ ...acc, ...{ [curr]: item[curr] } }), <ColumnSettings>{});
				}),
			};
			gridConfig.columnsConfig.forEach((column) => {
				delete column.__ngContext__;
			});
			arrayToSave.push(gridConfig);
		});

		let stringGridConfig = JSON.stringify(_.cloneDeep(arrayToSave));
		//console.log(stringGridConfig);

		let statement = "API.DashboardAddOrUpdateKendoUISettings " + "@WidgetId = " + widgetId + ", @KendoJsonString = '" + stringGridConfig + "'";
		console.log(statement);
		return this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			console.log(data);
			return data[0].KendoUISettingsJSON;
		});
	}

	mergeTemplateAndSavedColumnsToOneGrid(templateGridSettings, savedGridSettings) {
		//console.log(templateGridSettings);
		//console.log(savedGridSettings);


		if(savedGridSettings === undefined || savedGridSettings === null){
			let mergedColumnConfigs = [];

			templateGridSettings.columnsConfig.forEach((templateColumn) => {
				let matchedSavedColumn = savedGridSettings.columnsConfig.find((savedColumn) => {
					return savedColumn.field === templateColumn.field;
				});
				if (matchedSavedColumn !== undefined) {
					mergedColumnConfigs.push(matchedSavedColumn);
				} else {
					mergedColumnConfigs.push(templateColumn);
				}
			});
			let mergedObject = {
				state: savedGridSettings.state,
				columnsConfig: mergedColumnConfigs,
			};
			//console.log(mergedObject);
			return mergedObject;
		} else {
			let mergedColumnConfigs = [];

			templateGridSettings.columnsConfig.forEach((templateColumn) => {
				let matchedSavedColumn = savedGridSettings.columnsConfig.find((savedColumn) => {
					return savedColumn.field === templateColumn.field;
				});
				if (matchedSavedColumn !== undefined) {
					//merge properties
					this.OverviewPropertiesToMerge.forEach((property) => {
						if (templateColumn[property] !== undefined) {
							matchedSavedColumn[property] = templateColumn[property];
						}
					});
					mergedColumnConfigs.push(matchedSavedColumn);
				} else {
					mergedColumnConfigs.push(templateColumn);
				}
			});

			let mergedObject = {
				state: savedGridSettings.state,
				columnsConfig: mergedColumnConfigs,
			};
			//console.log(mergedObject);
			return mergedObject;


		}



	}
}
