<ion-card>
  <ion-card-header>
    <ion-card-title>Geolocation</ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <button full (click)="getGeolocation()">Get Geolocation</button>
    <div *ngIf="location">
      <h3>Latitude: {{location.lat}}</h3>
      <h3>Longitude: {{location.lng}}</h3>
    </div>
  </ion-card-content>
</ion-card>
